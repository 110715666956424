import { useStore } from "vuex";
import { computed, watch } from "vue";
import { key } from "@/store";
import { PREFER_MODULE, SET_PREFERENCE } from "@/store/constants";
import { Theme } from "@/store/modules/prefer";
import { setTheme } from "@/utils/theme";

interface UseThemeOptions {
    onChange?: (value: Theme) => void;
}

export const useTheme = (options: UseThemeOptions = {}) => {
    const store = useStore(key);

    const theme = computed(() => store.getters[`${PREFER_MODULE}/theme`]);

    const changeTheme = (value: Theme) => {
        store.commit(`${PREFER_MODULE}/${SET_PREFERENCE}`, {
            userTheme: value,
        });
        setTheme(value);
    };

    watch(theme, (val) => {
        options.onChange?.(val);
    });

    return {
        theme,
        changeTheme,
    };
};
