import { DatePickerMode } from "ant-design-vue/lib/date-picker/interface";

export function getDatePickerDefaultFormat(mode: DatePickerMode = "date") {
    switch (mode) {
        case "year":
            return "YYYY";
        // 十年的处理不是很友好，建议不要使用
        case "decade":
            return "YYYY ~ +9年";
        case "month":
            return "YYYY-MM";
        default:
            return "YYYY-MM-DD";
    }
}
