
/**
 * 对于上传组件来说，要满足的场景有：
 * 1. 自动上传
 * 2. 手动上传
 * 3. 支持预览
 * 4. 支持回显
 * 这里通过 autoUpload 来控制自动/手动上传，本质上控制的是 beforeUpload
 * 上传的支持度又分为单个上传，多个上传，这里通过 Upload 本身的 multiple 控制
 * 无论是自动上传还是手动上传，都要指定一个上传接口，以及参数名
 */
import { computed, defineComponent, nextTick, PropType, ref, watchEffect } from "vue";
import { UploadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { GeneralFunction, PlainObject } from "@/bean/base";
import { attachmentService } from "@/services/admin/attachment";
import { EnhancedFile, RawFile, RecordRowDTO } from "@/bean/dto";
import { isArray, isFile, isFunction, isPromise } from "@/utils/type";

interface CustomRequestOptions {
    data: PlainObject<string, string>;
    file: RawFile;
    filename: string;
    onError: GeneralFunction;
    onProgress: GeneralFunction;
    onSuccess: GeneralFunction;
}

interface UploadProps {
    name: string;
    customRequest: (option: CustomRequestOptions) => void;
    beforeUpload: (file: RawFile, fileList: RawFile[]) => boolean | Promise<unknown>;
    fileList: EnhancedFile[];
    remove: (file: EnhancedFile) => boolean | Promise<unknown>;
    data?: PlainObject<string, string>;
    multiple?: boolean;
}

interface SuccessUploadFinishResponse<T> {
    status: "success";
    data: T[];
}

interface FailUploadFinishResponse {
    status: "fail";
    err: Error;
}

export type UploadFinishResponse<T extends RecordRowDTO = RecordRowDTO> = SuccessUploadFinishResponse<T> | FailUploadFinishResponse;

// FileReader 转 Base64
// function getBase64(file: File) {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//     });
// }

// 获取 blob url
function getBlobURL(file: File | Blob | MediaSource) {
    return URL.createObjectURL(file);
}

const props = {
    uploadText: {
        type: String,
        default: "上传",
    },
    autoUpload: {
        type: Boolean,
        default: true,
    },
    uploadApi: {
        type: Function,
        default: attachmentService.uploadFiles.bind(attachmentService),
    },
    fileList: {
        type: Array as PropType<EnhancedFile[]>,
        default() {
            return [];
        },
    },
};

export default defineComponent({
    name: "ClUpload",
    inheritAttrs: false,
    components: {
        UploadOutlined,
    },
    props,
    emits: ["uploadFinish", "remove", "afterSelect"],
    setup(props, { attrs, emit }) {
        const fileList = ref<EnhancedFile[]>(props.fileList || []);
        watchEffect(() => {
            if (props.fileList.length > 0) {
                fileList.value = props.fileList;
            }
        });

        // 自定义上传，复用 token 校验流程
        // 默认每一个 file change 都会触发，所以在 multiple 情况下想在一个接口上传时，还需要用队列缓冲。
        // TODO: 队列缓冲，加一个 prop 控制；同时也支持外部自定义 customRequest 逻辑
        const customRequest: UploadProps["customRequest"] = async ({ data, file }) => {
            const formdata = new FormData();
            Object.keys(data).forEach((key) => {
                formdata.append(key, data[key]);
            });
            formdata.append(processedAttrs.value.name, file);
            try {
                const res = await props.uploadApi(formdata);
                emit("uploadFinish", {
                    status: "success",
                    data: res.result,
                });
                const index = fileList.value.findIndex((item) => item.uid === file.uid);
                if (index !== -1) {
                    if (isArray(res.result) && res.result.length > 0) {
                        const file = res.result[0];
                        fileList.value.splice(index, 1, {
                            uid: file.id,
                            url: file.fileUrl,
                            name: file.fileName,
                            // done 是已经上传的图片
                            status: "done",
                        });
                    }
                }
            } catch (err) {
                emit("uploadFinish", {
                    status: "fail",
                    err,
                });
            }
        };

        // 自动上传前的拦截，用来处理 fileList，同时决定是否自动上传
        // 同时支持外部传入同名 beforeUpload
        const beforeUpload: UploadProps["beforeUpload"] = (file) => {
            const url = getBlobURL(file);
            file.url = url;
            file.preview = url;
            if (processedAttrs.value.multiple) {
                // 多选
                fileList.value = [...fileList.value, file];
            } else {
                // 单选
                if (fileList.value.length > 0) {
                    // 同时发出 remove 消息，保证外部可以做业务删除逻辑
                    emit("remove", {
                        index: 0,
                        file: fileList.value[0],
                    });
                }
                fileList.value = [file];
            }
            return !!props.autoUpload;
        };

        // 删除一个文件
        const customReomove: UploadProps["remove"] = (file) => {
            const index = fileList.value.findIndex((item) => item.uid === file.uid);
            if (index !== -1) {
                fileList.value.splice(index, 1);
                emit("remove", {
                    index,
                    file,
                });
                return true;
            }
            return false;
        };

        const previewImgRef = ref();
        const previewURL = ref("");
        const onPreview = (file: EnhancedFile) => {
            previewURL.value = file.url;
            nextTick(() => {
                previewImgRef.value.$el.nextSibling.click();
            });
        };

        // 封装 attrs
        const processedAttrs = computed<UploadProps>(() => {
            return {
                // 支持外部自定义上传参数名
                name: "files",
                // 支持外部自定义自动上传行为
                customRequest,
                // 支持外部自定义预览
                onPreview,
                // 以上支持被覆盖
                ...attrs,
                // remove是属性不是事件回调，注意！
                remove: customReomove,
                fileList: fileList.value,
                beforeUpload: (_file: RawFile, _fileList: RawFile[]) => {
                    // 同时满足 Promise 和 boolean 的情况，就直接用 Promise 包装是最方便的
                    return new Promise((resolve, reject) => {
                        const beforeUploadHook = attrs["before-upload"];
                        if (isFunction(beforeUploadHook)) {
                            // 支持外部传入 beforeUpload 钩子
                            const hookResult = beforeUploadHook.call(null, _file, _fileList);
                            if (isPromise(hookResult)) {
                                // 如果传入的是 Promise，需要观察结果
                                hookResult.then(
                                    (res) => {
                                        // 如果 Promise 返回的是文件，支持以这个文件为上传对象
                                        // 接着还要进行内部的 beforeUpload 校验，主要是判断 autoUpload
                                        const beforeUploadResult = beforeUpload(isFile<RawFile>(res) ? res : _file, _fileList);
                                        beforeUploadResult === true ? resolve(true) : reject(new Error("canceled"));
                                    },
                                    () => {
                                        // 如果 Promse rejected，说明外部希望取消上传
                                        reject(new Error("canceled"));
                                    }
                                );
                            } else if (hookResult === false) {
                                // 如果外部 beforeUpload 返回 false, 说明也希望停止上传
                                reject(new Error("canceled"));
                            } else {
                                // 外部 beforeUpload 返回的既不是 Promise，也不是 false，说明是通过外部校验的，继续内部校验
                                const beforeUploadResult = beforeUpload(_file, _fileList);
                                beforeUploadResult === true ? resolve(true) : reject(new Error("canceled"));
                            }
                        } else {
                            // 外部没传合法的 beforeUpload，进行内部校验
                            const beforeUploadResult = beforeUpload(_file, _fileList);
                            beforeUploadResult === true ? resolve(true) : reject(new Error("canceled"));
                        }
                    }).finally(() => {
                        emit("afterSelect", _file, fileList.value);
                    });
                },
            };
        });

        // 手动触发上传
        const startUpload = async () => {
            // 检查 fileList
            const rawFiles = fileList.value.filter((item) => isFile<RawFile>(item));
            if (rawFiles.length === 0) {
                message.warning("文件列表为空！");
                return Promise.reject(new Error("empty"));
            }
            const { name, data } = processedAttrs.value;
            const formdata = new FormData();
            const processedData = data || {};
            Object.keys(processedData).forEach((key) => {
                formdata.append(key, processedData[key]);
            });
            rawFiles.forEach((file) => {
                formdata.append(name, file as RawFile);
            });
            try {
                const res = await props.uploadApi(formdata);
                return Promise.resolve(res);
            } catch (err) {
                return Promise.reject(err);
            }
        };

        return {
            processedAttrs,
            beforeUpload,
            startUpload,
            fileList,
            onPreview,
            previewImgRef,
            previewURL,
        };
    },
});
