/*
 * @Author: 蒋文斌
 * @Date: 2021-04-25 19:56:29
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-06-22 20:45:52
 * @Description: 全局按需引入 element-plus 组件
 */

import { App } from "vue";

import "element-plus/packages/theme-chalk/src/base.scss";

import {
    ElCheckbox,
    ElCheckboxGroup,
    ElImage,
    ElInfiniteScroll,
    ElInputNumber,
    ElRadio,
    ElTabPane,
    ElTabs,
    ElSelect,
    ElOption,
    ElScrollbar,
} from "element-plus";

const components = [ElImage, ElTabs, ElTabPane, ElRadio, ElInputNumber, ElCheckboxGroup, ElCheckbox, ElSelect, ElOption, ElScrollbar];

const plugins = [ElInfiniteScroll];

// 自动注册全局组件
export default {
    install(app: App): App {
        components.forEach((comp) => {
            app.component(comp.name, comp);
        });

        plugins.forEach((plugin) => {
            app.use(plugin);
        });
        return app;
    },
};
