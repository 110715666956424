/*
 * @Author: 蒋文斌
 * @Date: 2021-04-26 08:44:49
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-26 11:29:24
 * @Description: 异步hook
 */
import { Ref, ref } from "vue";
import { GeneralFunction } from "@/bean/base";

interface AsyncLoadingResponse {
    trigger: GeneralFunction;
    loading: Ref<boolean>;
    isError: Ref<boolean>;
    error: Ref<unknown>;
}

export const useAsyncLoading = (fn: GeneralFunction<Promise<unknown>>): AsyncLoadingResponse => {
    const loading = ref(false);
    const isError = ref(false);
    const error = ref();
    const trigger = async (...args: any[]) => {
        try {
            loading.value = true;
            await fn(...args);
        } catch (err) {
            isError.value = true;
            error.value = err;
        } finally {
            loading.value = false;
        }
    };
    return { trigger, loading, isError, error };
};
