/*
 * @Author: 蒋文斌
 * @Date: 2021-04-25 19:56:29
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-06-07 21:29:03
 * @Description: 全局按需引入 ant-design-vue 组件
 */

import { App } from "vue";
import {
    Form,
    Input,
    InputNumber,
    Select,
    Button,
    Checkbox,
    Radio,
    Tooltip,
    Layout,
    Menu,
    Breadcrumb,
    Tabs,
    Dropdown,
    Tag,
    Table,
    Space,
    Modal,
    ConfigProvider,
    TreeSelect,
    Row,
    Col,
    Upload,
    Cascader,
    Alert,
    Steps,
    Result,
    DatePicker,
    Tree,
    Image,
    Spin,
    Divider,
    Card,
    Skeleton,
    Collapse,
    Timeline,
    Rate,
    Progress,
    AutoComplete,
    Switch,
    Badge,
} from "ant-design-vue";

const components = [
    Alert,
    AutoComplete,
    Badge,
    Breadcrumb,
    Button,
    ConfigProvider,
    Collapse,
    Card,
    Checkbox,
    Cascader,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    Input,
    InputNumber,
    Image,
    Layout,
    Menu,
    Modal,
    Radio,
    Row,
    Result,
    Spin,
    Skeleton,
    Select,
    Steps,
    Space,
    Switch,
    Tooltip,
    Tabs,
    Tag,
    Table,
    Tree,
    TreeSelect,
    Upload,
    Timeline,
    Rate,
    Progress,
];

// 自动注册全局组件
export default {
    install(app: App): App {
        components.forEach((comp) => {
            app.use(comp);
        });
        return app;
    },
};
