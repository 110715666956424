/*
 * @Author: 蒋文斌
 * @Date: 2021-05-18 16:59:32
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-18 17:06:55
 * @Description: 全屏 hook
 */

import { onMounted, Ref, ref } from "vue";
import { isFullscreen, listenFullscreen } from "@/utils/fullscreen";

interface UseFullscreenResponse {
    isFullscreenFlag: Ref<boolean>;
}

export const useFullscreen = (): UseFullscreenResponse => {
    const isFullscreenFlag = ref(false);
    const checkFullscreenStatus = () => {
        isFullscreenFlag.value = isFullscreen();
    };
    onMounted(() => {
        checkFullscreenStatus();
        listenFullscreen(() => {
            checkFullscreenStatus();
        });
    });

    return {
        isFullscreenFlag,
    };
};
