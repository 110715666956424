import { message } from "ant-design-vue";
import { ActionContext } from "vuex";
import { authService } from "@/services/auth/auth";
import { userService } from "@/services/admin/user";
import storage from "@/utils/storage";
import {
    TABS_MODULE,
    CUSTOMER_MODULE,
    SET_ROUTE_TABS,
    SET_VERIFY_KEY,
    SET_ACCESS_INFO,
    SET_USER_INFO,
    SET_USER_MENUS,
    SET_BTN_AUTH_PATHS,
    FETCH_VERIFY_CODE,
    DISPATCH_LOGIN,
    DISPATCH_LOGOUT,
    GET_CURRENT_USER,
    GET_USER_MENUS,
    CHANGE_COM,
    FETCH_CUSTOMIZED_INFO,
    SET_BADGE_INFO,
} from "@/store/constants";
import router, { initRouter } from "@/router";
import { tree2Arr } from "@/utils/tree";
import { AUTH_TYPES } from "@/utils/const";
import { PlainObject } from "@/bean/base";
import { UserDTO, UserCompanyDTO, AccessInfoDTO, VerifyInfoDTO, MenuItemDTO, FlatMenuItemDTO, DynamicPageMeta } from "@/bean/dto";
import { LOGIN_ROUTE } from "@/router/no-auth";
import { checkJsonString } from "@/utils/helper";
import { isBool } from "@/utils/type";
import { RootState } from "../index";

function parseRemarks(menu: FlatMenuItemDTO) {
    const result = checkJsonString(menu.remarks);
    let meta: Partial<DynamicPageMeta> = {};
    if (result.valid) {
        meta = result.value;
    }
    if (!isBool(meta.inheritLayout)) {
        // 如果 inheritLayout 不是布尔值，也默认处理为 true
        meta.inheritLayout = true;
    }
    menu.meta = meta;
    return menu;
}

const verifyKey = storage.get("verifyKey", true);
let accessInfo = null;
let userMenus: MenuItemDTO[] = [];
let flatMenus: FlatMenuItemDTO[] = [];
let userInfo = null;

const accessInfoInStorage = storage.get("accessInfo", true);
if (accessInfoInStorage) {
    accessInfo = JSON.parse(accessInfoInStorage);
}

const userMenusInStorage = storage.get("userMenus", true);
if (userMenusInStorage) {
    userMenus = JSON.parse(userMenusInStorage);
    flatMenus = tree2Arr(userMenus, undefined, parseRemarks);
}

const userInfoInStorage = storage.get("userInfo", true);
if (userInfoInStorage) {
    userInfo = JSON.parse(userInfoInStorage);
}

export interface AuthState {
    verifyKey: string;
    accessInfo: AccessInfoDTO | null;
    userInfo: UserDTO | null;
    userMenus: MenuItemDTO[];
    flatMenus: FlatMenuItemDTO[];
    btnAuthPaths: string[];
    badgeInfo: PlainObject;
}

export interface DispatchLogoutPayload {
    messageKey: symbol;
}

export const auth = {
    namespaced: true,
    state: {
        verifyKey,
        accessInfo,
        userInfo,
        userMenus,
        flatMenus,
        btnAuthPaths: [],
        badgeInfo: {},
    },
    getters: {
        rootMenuId: (state: AuthState): number | undefined => (state?.userMenus.length > 0 ? state?.userMenus[0].parentId : undefined),
        comId: (state: AuthState): number | undefined => state?.accessInfo?.comId,
        realName: (state: AuthState): string | undefined => state?.userInfo?.realName,
        uid: (state: AuthState): number | undefined => state?.userInfo?.id,
        orgId: (state: AuthState): number | undefined => state?.userInfo?.defaultOrg?.id,
        orgName: (state: AuthState): string | undefined => state?.userInfo?.defaultOrg?.orgName,
        userAvatar: (state: AuthState): string | undefined => state?.userInfo?.picture,
        userComList: (state: AuthState): UserCompanyDTO[] => state?.userInfo?.userComList || [],
        flatPages: (state: AuthState): FlatMenuItemDTO[] =>
            state.flatMenus.filter((item) => item.authType === AUTH_TYPES.page && item.authRoute && item.authRoute.startsWith("/")),
    },
    mutations: {
        [SET_VERIFY_KEY](state: AuthState, data: string): void {
            if (data) {
                state.verifyKey = data;
                storage.set("verifyKey", data, true);
            } else {
                state.verifyKey = "";
                storage.remove("verifyKey", true);
            }
        },
        [SET_ACCESS_INFO](state: AuthState, data: AccessInfoDTO | null): void {
            if (data) {
                state.accessInfo = data;
                storage.set("accessInfo", JSON.stringify(data), true);
            } else {
                state.accessInfo = null;
                storage.remove("accessInfo", true);
            }
        },
        [SET_USER_INFO](state: AuthState, data: UserDTO | null): void {
            if (data) {
                state.userInfo = data;
                storage.set("userInfo", JSON.stringify(data), true);
            } else {
                state.userInfo = null;
                storage.remove("userInfo", true);
            }
        },
        // 按钮权限数据
        [SET_BTN_AUTH_PATHS](state: AuthState, data: string[]): void {
            state.btnAuthPaths = data || [];
        },
        // 处理用户菜单
        [SET_USER_MENUS](state: AuthState, data: MenuItemDTO[]): void {
            if (data) {
                state.userMenus = data;
                state.flatMenus = tree2Arr(data, undefined, parseRemarks);
                storage.set("userMenus", JSON.stringify(data), true);
            } else {
                state.userMenus = [];
                state.flatMenus = [];
                storage.remove("userMenus", true);
            }
        },
        // 设置 badge 数据，可部分更新
        [SET_BADGE_INFO](state: AuthState, data?: PlainObject): void {
            if (data) {
                Object.assign(state.badgeInfo, data);
            } else {
                state.badgeInfo = {};
            }
        },
    },
    actions: {
        // 获取验证码
        async [FETCH_VERIFY_CODE]({ commit, state }: ActionContext<AuthState, RootState>): Promise<VerifyInfoDTO> {
            const { result } = await authService.getVerifyCode(state.verifyKey);
            commit(SET_VERIFY_KEY, (result as VerifyInfoDTO).verifyKey);
            return result as VerifyInfoDTO;
        },
        // 用户登录
        async [DISPATCH_LOGIN]({ commit, state }: ActionContext<AuthState, RootState>, payload: PlainObject): Promise<void> {
            const res = await authService.login({
                ...payload,
                verifyKey: state.verifyKey,
            });
            commit(SET_ACCESS_INFO, res.result);
        },
        // 登出
        async [DISPATCH_LOGOUT]({ state }: ActionContext<AuthState, RootState>, payload: DispatchLogoutPayload | undefined): Promise<void> {
            const messageKey = payload ? payload.messageKey : Symbol("key");
            const hideLoading = message.loading({ content: "正在退出登录...", duration: 0, key: messageKey });
            const { accessInfo } = state;
            try {
                if (accessInfo) {
                    await authService.logout(accessInfo.refresh_token);
                }
            } finally {
                hideLoading();
                router.push("/login");
            }
        },
        // 获取用户信息
        async [GET_CURRENT_USER]({ commit }: ActionContext<AuthState, RootState>): Promise<void> {
            const res = await userService.getUser();
            commit(SET_USER_INFO, res.result);
        },
        // 获取用户菜单
        async [GET_USER_MENUS]({ commit }: ActionContext<AuthState, RootState>): Promise<void> {
            const res = await userService.getMenus();
            commit(SET_USER_MENUS, res.result);
        },
        // 切换租户
        async [CHANGE_COM]({ commit, state, dispatch }: ActionContext<AuthState, RootState>, comId: number): Promise<void> {
            const changeComMessageKey = Symbol("key");
            message.loading({ content: "正在为您切换租户，请稍候...", duration: 0, key: changeComMessageKey });
            const { accessInfo } = state;
            if (accessInfo) {
                const res = await authService.changeCom({
                    comId,
                    refreshToken: accessInfo.refresh_token,
                });
                commit(SET_ACCESS_INFO, res.result);
                commit(`${TABS_MODULE}/${SET_ROUTE_TABS}`, null, { root: true });
                message.loading({ content: "租户切换成功，正在加载租户信息...", duration: 0, key: changeComMessageKey });
                // 切换租户后当前页面有一个重刷的情况，需要解决
                await dispatch(`${CUSTOMER_MODULE}/${FETCH_CUSTOMIZED_INFO}`, comId, { root: true });
                await initRouter({ isChangeCom: true, messageKey: changeComMessageKey });
            } else {
                // 没有accessInfo，重新登录
                router.push(LOGIN_ROUTE.path);
            }
        },
    },
};
