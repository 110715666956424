import { findMenuChainByRoutePath, findRootMenuByRoutePath, findRootRedirectRoute } from "@/permissions/generate-routes";
import { key } from "@/store";
import { AUTH_MODULE } from "@/store/constants";
import { last } from "lodash-es";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export const useMenuChain = () => {
    // vuex
    const store = useStore(key);
    const flatMenus = computed(() => store.state.auth.flatMenus);
    const rootMenuId = computed(() => store.getters[`${AUTH_MODULE}/rootMenuId`]);
    // route
    const route = useRoute();
    const menuChain = computed(() => {
        const { isDynamic } = route.meta;
        return findMenuChainByRoutePath({
            menus: flatMenus.value,
            path: isDynamic ? last(route.matched)?.path : route.path,
            matchKey: isDynamic ? "authRoute" : "path",
            endParentId: rootMenuId.value,
        });
    });
    return {
        menuChain,
    };
};

export const useBreadcrumbList = () => {
    // vuex
    const store = useStore(key);
    const flatPages = computed(() => store.getters[`${AUTH_MODULE}/flatPages`]);
    const { menuChain } = useMenuChain();
    const breadcrumbList = computed(() =>
        menuChain.value.map((menu) => {
            return {
                id: menu.id,
                name: menu.nameCN,
                path: findRootRedirectRoute([menu], flatPages.value),
            };
        })
    );
    return {
        breadcrumbList,
    };
};

export const useRootMenu = () => {
    // vuex
    const store = useStore(key);
    const flatMenus = computed(() => store.state.auth.flatMenus);
    const rootMenuId = computed(() => store.getters[`${AUTH_MODULE}/rootMenuId`]);

    // route
    const route = useRoute();
    const rootMenu = computed(() => {
        const { isDynamic } = route.meta;
        return findRootMenuByRoutePath({
            menus: flatMenus.value,
            path: isDynamic ? last(route.matched)?.path : route.path,
            matchKey: isDynamic ? "authRoute" : "path",
            endParentId: rootMenuId.value,
        });
    });

    return {
        rootMenu,
    };
};
