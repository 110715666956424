/*
 * @Author: 蒋文斌
 * @Date: 2021-01-05 22:17:58
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-07 21:29:15
 * @Description: 租户模块
 */
import { ApiService } from "@/services/index";
import { admin } from "@/services/config";
import { PlainObject } from "@/bean/base";
import { ArrayResponse, QueryPageModel, RecordResponse } from "@/bean/xhr";
import { CompanyDTO } from "@/bean/dto";

class CompanyService extends ApiService {
    page(params: QueryPageModel) {
        return params.wd ? this.search(params) : this.$postJson("page", params);
    }

    search(params: QueryPageModel) {
        return this.$postJson("search", params);
    }

    delete(id: number) {
        return this.$del("delete", { id });
    }

    enable(id: number) {
        return this.$put("enable", { id });
    }

    disable(id: number) {
        return this.$put("disable", { id });
    }

    detail(id: number) {
        return this.$get<RecordResponse>("detail", { id });
    }

    update(params: PlainObject) {
        return this.$putJson("update", params);
    }

    find(params: PlainObject) {
        return this.$postJson("find", params);
    }

    findAll(params?: PlainObject) {
        return this.$get<ArrayResponse<CompanyDTO>>("findAll", params);
    }

    stepAdd(params: PlainObject) {
        return this.$postJson<RecordResponse>("stepAdd", params);
    }

    i() {
        return this.$get("i", undefined, { globalRequest: true });
    }

    current() {
        return this.$get("current", undefined, { globalRequest: true });
    }
}

export const companyService = new CompanyService(admin, "company");
