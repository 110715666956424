/*
 * @Author: 蒋文斌
 * @Date: 2021-02-22 16:52:34
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-11 10:29:47
 * @Description: resize处理
 */

import { throttle } from "lodash-es";
import { eventBus } from "./event-bus";

function resizeHandler(e: UIEvent) {
    eventBus.emit("resize", e);
}

export function registerResizeListener(): void {
    window.addEventListener("resize", throttle(resizeHandler, 150));
}
