import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91f1e144"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "onerror"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    style: _normalizeStyle({ width: _ctx.size + 'px', height: _ctx.size + 'px' }),
    class: "avatar v-a-m",
    src: _ctx.url || _ctx.customizedInfo.config.logoUrl,
    onerror: _ctx.avatarError
  }, null, 12, _hoisted_1))
}