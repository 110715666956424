import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { key } from "./store";
import globalComponents from "./components";
import { registerResizeListener } from "./utils/resize";
import "./styles/index.scss";
import { preventF11DefaultAction } from "./utils/fullscreen";
import { initWatchDevtools } from "./utils/devtools";

initWatchDevtools();

preventF11DefaultAction();

registerResizeListener();

export const app = createApp(App);

app.use(store, key).use(router).use(globalComponents).mount("#app");
