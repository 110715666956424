/*
 * @Author: 蒋文斌
 * @Date: 2021-05-19 11:05:22
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-07-05 09:17:38
 * @Description: table column 生成
 */

import { computed, CSSProperties, isRef, reactive, Ref } from "vue";
import { Button as AButton, Space as ASpace, Popconfirm as APopconfirm, Image as AImage } from "ant-design-vue";
import { RouterLink } from "vue-router";
import { CopyOutlined } from "@ant-design/icons-vue";
import { merge } from "lodash-es";
import { GeneralFunction, PlainObject } from "@/bean/base";
import { ParamRecordDTO } from "@/bean/dto";
import { CustomRender, CustomRenderFields, ProTableColumn } from "@/bean/pro";
import { useAsyncLoading } from "@/hooks/async";
import { paramFilter } from "@/utils/filters";
import { proxyProps } from "@/utils/helper";
import { isDefined, isFunction } from "@/utils/type";
import { generateColumnKey } from "./utils";
import styles from "./index.module.scss";

const DEFAULT_COLUMN: ProTableColumn = {
    align: "center",
    ellipsis: true,
    fixed: false,
    visible: true,
    order: 0,
};

interface UseTableColumnResponse {
    tableColumns: Ref<ProTableColumn[]>;
}

export const useTableColumn = (
    columns: Ref<ProTableColumn[]>,
    params: Ref<ParamRecordDTO>,
    commonColumnOption: Ref<ProTableColumn>,
    activeRowKey: Ref<number>,
    setActiveRowKey: GeneralFunction
): UseTableColumnResponse => {
    // hideInTable=true的不展示在表格区域
    const tableColumns = computed(() => {
        return columns.value
            .filter((column) => !column.hideInTable)
            .map((column, colIndex) => {
                let customRender: ((p: CustomRenderFields) => unknown) | null = null;
                const align = column.align || DEFAULT_COLUMN.align;
                const stylesForCopy: CSSProperties = {
                    justifyContent: align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start",
                };
                if (column.customRender) {
                    customRender = (data) => {
                        return (column.customRender as CustomRender)({
                            ...data,
                            colIndex,
                        });
                    };
                } else if (column.columnType === "params") {
                    customRender = ({ record, text }) => {
                        // 如果后端有返回翻译字段，优先使用；否则前端从参数列表翻译
                        return record[`${column.dataIndex}ByTran`] || paramFilter(text as string, params.value[column.paramCode as string]);
                    };
                } else if (column.columnType === "translate") {
                    customRender = ({ record, text }) => {
                        // 如果后端有返回id翻译字段，优先使用；否则直接显示id值
                        return record[`${column.dataIndex}ByTran`] || text;
                    };
                } else if (column.columnType === "image") {
                    customRender = ({ text }) => {
                        return text ? <AImage wrapperClassName={styles["pro-table__image"]} src={text as string}></AImage> : null;
                    };
                } else if (column.columnType === "actions") {
                    const { actions } = column;
                    if (actions && actions.length > 0) {
                        // 在这里主要针对loading劫持事件处理器
                        const processedActions = actions.map((action) => {
                            const { loadingInterceptor } = action;
                            const interceptedProps = reactive<PlainObject>({});
                            if (loadingInterceptor) {
                                const loadingRelations = reactive<Ref<boolean>[]>([]);
                                Object.keys(loadingInterceptor).forEach((handle) => {
                                    const listener = loadingInterceptor[handle];
                                    const { trigger, loading } = useAsyncLoading(listener);
                                    loadingRelations.push(loading);
                                    interceptedProps[handle] = trigger;
                                });
                                if (loadingRelations.length > 0) {
                                    interceptedProps.loading = computed(() => {
                                        return loadingRelations.some((l) => l.value);
                                    });
                                }
                            }

                            return {
                                ...action,
                                interceptedProps,
                            };
                        });

                        customRender = ({ record, index }) => {
                            return (
                                <ASpace>
                                    {processedActions
                                        .filter((action) => {
                                            const { visible } = action;
                                            return isDefined(visible) ? (isFunction(visible) ? visible(record) : visible) : true;
                                        })
                                        .map((action) => {
                                            const _props = isFunction<GeneralFunction<PlainObject>>(action.props)
                                                ? action.props(record)
                                                : { ...(action.props || {}) };
                                            const _allProps: PlainObject = {
                                                ...action.interceptedProps,
                                                ..._props,
                                            };
                                            Object.keys(_allProps).forEach((key) => {
                                                if (isRef(_allProps[key])) {
                                                    _allProps[key] = (_allProps[key] as Ref<unknown>).value;
                                                }
                                            });
                                            const isPromptEnable = action.type === "prompt" && !_allProps.disabled;
                                            const proxiedProps = proxyProps(
                                                isPromptEnable ? action.propsForPrompt || {} : _allProps,
                                                record,
                                                index,
                                                () => setActiveRowKey(record.id)
                                            );
                                            return isPromptEnable ? (
                                                <APopconfirm
                                                    title={`确认要执行${
                                                        isFunction(action.label) ? action.label(record) : action.label
                                                    }操作吗？`}
                                                    okText="确认"
                                                    cancelText="取消"
                                                    {...proxiedProps}
                                                >
                                                    <AButton
                                                        type="primary"
                                                        ghost
                                                        size="small"
                                                        {..._allProps}
                                                        loading={activeRowKey.value === record.id && (_allProps.loading as boolean)}
                                                    >
                                                        {isFunction(action.label) ? action.label(record) : action.label}
                                                    </AButton>
                                                </APopconfirm>
                                            ) : (
                                                <AButton
                                                    type="primary"
                                                    ghost
                                                    size="small"
                                                    {...proxiedProps}
                                                    loading={activeRowKey.value === record.id && (proxiedProps.loading as boolean)}
                                                >
                                                    {isFunction(action.label) ? action.label(record) : action.label}
                                                </AButton>
                                            );
                                        })}
                                </ASpace>
                            );
                        };
                    }
                } else if (column.columnType === "link") {
                    customRender = ({ record, text }) => {
                        if (column.copyable) {
                            return (
                                <div class={styles.copyable__wrapper} style={stylesForCopy}>
                                    <RouterLink
                                        class={styles.copyable__link}
                                        to={typeof column.link === "function" ? column.link(record) : (column.link as string)}
                                    >
                                        <span class={styles.copyable__text}>{text}</span>
                                    </RouterLink>
                                    {text ? (
                                        <CopyOutlined
                                            title="复制"
                                            class={`copyable-trigger ${styles.copyable__icon}`}
                                            style={{ color: "var(--color-primary)", fontSize: "16px" }}
                                            data-clipboard-text={text}
                                        ></CopyOutlined>
                                    ) : null}
                                </div>
                            );
                        } else {
                            return (
                                <RouterLink to={typeof column.link === "function" ? column.link(record) : (column.link as string)}>
                                    {text}
                                </RouterLink>
                            );
                        }
                    };
                } else if (column.copyable) {
                    customRender = (fields) => {
                        const formattedText = isFunction(column.transform) ? column.transform(fields) : fields.text;
                        return (
                            <div class={styles.copyable__wrapper} style={stylesForCopy}>
                                <span class={styles.copyable__text}>{formattedText}</span>
                                {formattedText ? (
                                    <CopyOutlined
                                        title="复制"
                                        class={`copyable-trigger ${styles.copyable__icon}`}
                                        style={{ color: "var(--color-primary)", fontSize: "16px" }}
                                        data-clipboard-text={formattedText}
                                    ></CopyOutlined>
                                ) : null}
                            </div>
                        );
                    };
                } else if (isFunction(column.transform)) {
                    customRender = (fields) => {
                        const transformedText = (column.transform as GeneralFunction)(fields);
                        return <span>{transformedText}</span>;
                    };
                }

                const processedCommonColumnOption = merge({}, DEFAULT_COLUMN, commonColumnOption.value);

                // fixed 列默认不采用 ellipsis
                const ellipsisObj = column.fixed
                    ? {
                          ellipsis: false,
                      }
                    : {};

                return {
                    ...processedCommonColumnOption,
                    ...column,
                    ...ellipsisObj,
                    key: column.key || generateColumnKey(column, colIndex),
                    customRender,
                    align,
                } as ProTableColumn;
            });
    });

    return {
        tableColumns,
    };
};
