
import { defineComponent } from "vue";

export default defineComponent({
    name: "ClSubMenu",
    props: {
        menuInfo: {
            type: Object,
            default: () => ({}),
        },
    },
});
