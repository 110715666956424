/*
 * @Author: 蒋文斌
 * @Date: 2021-04-23 13:27:21
 * @LastEditors: 杨鸿凯
 * @LastEditTime: 2021-08-11 20:39:29
 * @Description: 辅助方法
 */
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import { GeneralFunction, PlainObject } from "@/bean/base";
import { RecordRowDTO } from "@/bean/dto";
import { getType, isArray, isBasicType, isDefined, isFunction, isMap, isObject, isSet, isString, isSymbol } from "./type";

/**
 * 处理参数对象
 * @param {Object} obj 参数对象
 * @param {options} isArrayToString 是否需要将数组处理成逗号分隔的string
 * @returns {Object} 处理后的参数对象
 */
export function requestParamsFilter(obj: PlainObject | unknown[], isArrayToString = false): PlainObject | unknown[] {
    if (isArray(obj)) {
        return obj;
    } else if (getType(obj) !== "object") {
        return {};
    }
    const newObj = {} as PlainObject;
    Object.keys(obj).forEach((key) => {
        const element = obj[key];
        if (Array.isArray(element)) {
            if (element.length > 0) {
                newObj[key] = isArrayToString ? element.join(",") : [...element];
            }
        } else if (isDefined(element)) {
            newObj[key] = element;
        }
    });
    return newObj;
}

export function capitalize(str: string): string {
    return str.length > 0 ? str[0].toUpperCase() + str.substring(1) : str;
}

const camelizeRE = /-(\w)/g;

export function camelize(str: string): string {
    return str.replace(camelizeRE, (_, c: string) => (c ? c.toUpperCase() : ""));
}

// 常规的属性劫持
export function proxyProps(props: PlainObject, row: RecordRowDTO, rowIndex: number, handlerCallback: GeneralFunction): PlainObject {
    const proxiedProps = reactive<PlainObject>({});
    Object.keys(props).forEach((key) => {
        if (/on[A-Z][A-Za-z]+/.test(key)) {
            proxiedProps[key] = () => {
                // 劫持所有事件，事件原有参数依旧能获取到，只不过位置往后推2个。
                // 举例：const onClick = (row, rowIndex, e) => {}
                (props[key] as GeneralFunction).call(null, row, rowIndex);
                if (handlerCallback) {
                    handlerCallback();
                }
            };
        } else {
            proxiedProps[key] = props[key];
        }
    });
    return proxiedProps;
}

/**
 * 表单组件的属性劫持
 * @param props 外部传过来的 props
 * @param builtInProps 内置 props，有两个特点。如果是普通属性，则会让 props 同名属性失效；如果是事件回调，则会先调用，props 同名事件回调后调用，并且 props 事件回调会支持 formModel 参数。
 * @param formModel 表单数据对象，回传给外部事件回调
 * @param defaultProps 默认 props，可以被外部 props 覆盖
 * @returns 代理后的 props
 */
export function proxyFormElementProps(
    props: PlainObject,
    builtInProps: PlainObject,
    formModel: PlainObject,
    defaultProps?: PlainObject
): PlainObject {
    // 先把默认属性和内置属性设置上
    const proxiedProps = reactive<PlainObject>({
        ...defaultProps,
        ...builtInProps,
    });
    Object.keys(props).forEach((key) => {
        if (/on[A-Z][A-Za-z]+/.test(key)) {
            // 如果是函数类型
            proxiedProps[key] = (...args: any[]) => {
                if (Object.prototype.hasOwnProperty.call(builtInProps, key)) {
                    // 内置事件回调先调用
                    (builtInProps[key] as GeneralFunction).call(null, ...args);
                }
                // 劫持所有事件，事件原有参数依旧能获取到，只不过位置往后推1个。
                // 举例：const onClick = (formModel, e) => {}
                (props[key] as GeneralFunction).call(null, formModel, ...args);
            };
        } else if (!Object.prototype.hasOwnProperty.call(builtInProps, key)) {
            // 不同名的外部属性
            proxiedProps[key] = props[key];
        }
    });
    return proxiedProps;
}

// 比较值是否一样，如果是引用类型，会通过递归方式去比较值
export function isEqual(obj1: unknown, obj2: unknown): boolean {
    const [type1, type2] = [getType(obj1), getType(obj2)];
    if (type1 === type2) {
        if (isBasicType(obj1)) {
            return obj1 === obj2;
        } else if (isObject(obj1) && isObject(obj2)) {
            return Object.keys(obj1).every((key) => {
                return isEqual(obj1[key], obj2[key]);
            });
        } else if (isArray(obj1) && isArray(obj2)) {
            return obj1.every((item, index) => {
                return isEqual(item, obj2[index]);
            });
        } else if ((isFunction(obj1) && isFunction(obj2)) || (isSymbol(obj1) && isSymbol(obj2))) {
            return obj1.toString() === obj2.toString();
        } else if (isMap(obj1) && isMap(obj2)) {
            const handledKeys1 = Array.from(obj1.keys());
            const handledKeys2 = Array.from(obj2.keys());
            return handledKeys1.length === handledKeys2.length && handledKeys1.every((key) => isEqual(obj1.get(key), obj2.get(key)));
        } else if (isSet(obj1) && isSet(obj2)) {
            const handledArray1 = Array.from(obj1.values());
            const handledArray2 = Array.from(obj2.values());
            return (
                handledArray1.length === handledArray2.length && handledArray1.every((item, index) => isEqual(item, handledArray2[index]))
            );
        } else {
            // 未知类型
            return false;
        }
    } else {
        // 类型不一致，无需比较
        return false;
    }
}

function findIndex(arr: unknown[], item: unknown) {
    let targetIndex = -1;
    for (let index = 0; index < arr.length; index++) {
        if (isEqual(arr[index], item)) {
            targetIndex = index;
            break;
        }
    }
    return targetIndex;
}

function contains(obj: unknown[] | PlainObject, item: unknown) {
    let containFlag = false;
    if (isObject(obj)) {
        const keys = Object.keys(obj);
        for (let index = 0; index < keys.length; index++) {
            if (isEqual(keys[index], item)) {
                containFlag = true;
                break;
            }
        }
    } else if (isArray(obj)) {
        for (let index = 0; index < obj.length; index++) {
            if (isEqual(obj[index], item)) {
                containFlag = true;
                break;
            }
        }
    } else {
        throw new Error("only support parameter whose type is object or array!");
    }
    return containFlag;
}

// 合并两个数据，用于支撑merge方法
function mergeTwo(obj1: unknown, obj2: unknown) {
    const dataType1 = getType(obj1);
    const dataType2 = getType(obj2);
    if (dataType1 === dataType2) {
        // 如果合并的两个数据类型一致，才进行处理，否则直接返回obj1
        if (isObject(obj1) && isObject(obj2)) {
            // Object类型
            const o1 = obj1;
            Object.keys(obj2).forEach((key) => {
                // 遍历obj2的keys
                if (Object.prototype.hasOwnProperty.call(o1, key)) {
                    // 如果obj1包含obj2的key，采用合并策略
                    o1[key] = mergeTwo(o1[key], obj2[key]);
                } else {
                    // 不包含，则直接赋值
                    o1[key] = cloneDeep(obj2[key]);
                }
            });
        } else if (isArray(obj1) && isArray(obj2)) {
            const o1 = obj1;
            // Array类型
            obj2.forEach((item) => {
                // 遍历obj2
                if (contains(o1, item)) {
                    // 合并数组不能forEach按顺序遍历，只能判断是否包含，如果obj1包含item，采用合并策略
                    const dataindex = findIndex(o1, item);
                    o1[dataindex] = mergeTwo(o1[dataindex], item);
                } else {
                    // 不包含，直接push
                    o1.push(cloneDeep(item));
                }
            });
        } else {
            obj1 = obj2;
        }
        return obj1;
    } else if (obj2 !== null && obj2 !== undefined) {
        return obj2;
    }
    return obj1;
}

type MergeType = PlainObject | unknown[] | never;

// 深度合并多个对象
export function deepMerge(srcObj: MergeType, ...objs: MergeType[]): unknown {
    const srcObjType = getType(srcObj);
    if (isObject(srcObj) || isArray(srcObj)) {
        const isSameType = objs.every((item) => {
            return getType(item) === srcObjType;
        });
        if (isSameType) {
            // 是同样的类型，进行合并操作
            if (isObject(srcObj)) {
                // object
                return [srcObj, ...objs].reduce((preVal, curVal) => {
                    return mergeTwo(preVal, curVal) as PlainObject;
                }, {});
            } else {
                // array
                return [srcObj, ...objs].reduce((preVal, curVal) => {
                    return mergeTwo(preVal, curVal) as unknown[];
                }, []);
            }
        } else {
            // 类型不一致，直接深拷贝源对象
            return cloneDeep(srcObj);
        }
    } else {
        // 其他数据类型
        throw new Error("only support type of object or array!");
    }
}

// 去掉协议判断资源是否相同
function isSameUrl(url1: string, url2: string) {
    const handledUrl1 = /^(https?:)?\/\//.test(url1) ? url1.replace(/^(https?:)?\/\//, "") : url1;
    const handledUrl2 = /^(https?:)?\/\//.test(url2) ? url2.replace(/^(https?:)?\/\//, "") : url2;
    return handledUrl1 === handledUrl2;
}

interface EnhanceScriptElement extends HTMLScriptElement {
    onreadystatechange: GeneralFunction;
    readyState: string;
}

export function loadScript(src: string, parentNode = document.body, isCheckRepeat = true, replace = false): Promise<string> {
    return new Promise((resolve, reject) => {
        if (src[0] === ".") {
            throw new Error("the src parameter must be an absolute url");
        }
        if (isCheckRepeat) {
            const toCheckUrl = src[0] === "/" && src[1] !== "/" ? `${window.location.protocol}//${window.location.host}${src}` : src;
            const targetScript = [...document.scripts].find((item) => isSameUrl(item.src, toCheckUrl));
            if (targetScript) {
                if (replace) {
                    (targetScript as HTMLElement).parentNode?.removeChild(targetScript);
                } else {
                    // script标签已经加载过
                    return resolve("existed");
                }
            }
        }
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        parentNode.appendChild(script);
        const supportLoad = "onload" in script;
        if (supportLoad) {
            script.onload = function () {
                // script标签加载成功
                resolve("loaded");
            };
        } else {
            // 低版本ie不支持onload，其实一般也用不到
            (script as EnhanceScriptElement).onreadystatechange = function () {
                if (this.readyState === "loaded" || this.readyState === "complete") {
                    resolve("loaded");
                }
            };
        }
        script.onerror = (event) => {
            reject(event);
        };
    });
}

/**
 * 下载文件
 * @param {string} url 文件url
 * @param {string} filename 文件名
 */
export function downloadFile(url: string, filename?: string): void {
    let link: HTMLAnchorElement | null = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    if (filename) {
        link.download = filename;
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
}

export function downloadFromBlob(blobData: Blob, type: string, filename: string): void {
    const blob = new Blob([blobData], { type });
    const url = URL.createObjectURL(blob);
    downloadFile(url, decodeURIComponent(filename));
}

export function resolveIndex(index: string): string[] {
    return index.replace(/\]/g, "").replace(/\[/g, ".").split(".");
}

export function resolveDeep(data: PlainObject, dataIndex: string): unknown {
    const indexs = resolveIndex(dataIndex);
    let temp: unknown | PlainObject = data;
    let i = 0;
    while (i < indexs.length && (isObject(temp) || isArray(temp))) {
        temp = (temp as PlainObject)[indexs[i++]];
    }
    return temp;
}

/**
 * 检查 jsonstring 的合法性
 * @param str
 * @returns
 */
export function checkJsonString(str: string | null | undefined) {
    if (isString(str)) {
        if (str.charAt(0) === "{") {
            try {
                const value = JSON.parse(str);
                return {
                    valid: true,
                    value,
                };
            } catch (err) {
                console.error(err);
                return {
                    valid: false,
                };
            }
        }
    }
    return {
        valid: false,
    };
}
