import { defineComponent, Slot } from "vue";
import { Button } from "ant-design-vue";
import ClIconSvg from "@/components/icon/icon-svg/index.vue";

const props = {
    icon: {
        type: String,
    },
    iconSize: {
        type: Number,
        default: 14,
    },
    iconColor: {
        type: String,
    },
    mainColor: {
        type: String,
    },
};

export default defineComponent({
    name: "ClButton",
    props,
    setup(props, { attrs, slots }) {
        return () => {
            return (
                <Button
                    class="cl-button"
                    style={{ backgroundColor: props.mainColor, borderColor: props.mainColor }}
                    v-slots={{
                        default: () => {
                            return (
                                <>
                                    {props.icon && !attrs.loading ? (
                                        <ClIconSvg icon={props.icon} size={props.iconSize} color={props.iconColor} />
                                    ) : null}
                                    {(slots?.default as Slot)()}
                                </>
                            );
                        },
                    }}
                ></Button>
            );
        };
    },
});
