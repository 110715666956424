
import { defineComponent } from "vue";
import icons from "@/assets/json/icons.json";

export default defineComponent({
    name: "ClSelectIconSvg",
    emits: ["select"],
    setup(props, { emit }) {
        const onSelectIcon = (icon: string) => {
            emit("select", icon);
        };

        return {
            icons,
            onSelectIcon,
        };
    },
});
