import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cl_icon_svg = _resolveComponent("cl-icon-svg")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_cl_sub_menu = _resolveComponent("cl-sub-menu")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!

  return (_openBlock(), _createBlock(_component_a_sub_menu, _mergeProps({
    key: _ctx.menuInfo.id
  }, _ctx.$attrs), {
    title: _withCtx(() => [
      _createVNode(_component_cl_icon_svg, {
        icon: _ctx.menuInfo.icon
      }, null, 8, ["icon"]),
      _createElementVNode("span", {
        title: _ctx.menuInfo.nameCN
      }, _toDisplayString(_ctx.menuInfo.nameCN), 9, _hoisted_1)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuInfo.children, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.id
        }, [
          (!item.meta.isHidden)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (item.authType === '3')
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: item.id
                    }, {
                      default: _withCtx(() => [
                        (item.icon)
                          ? (_openBlock(), _createBlock(_component_cl_icon_svg, {
                              key: 0,
                              icon: item.icon
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          title: item.nameCN
                        }, _toDisplayString(item.nameCN), 9, _hoisted_2)
                      ]),
                      _: 2
                    }, 1024))
                  : (item.authType === '2' && item.children && item.children.length > 0)
                    ? (_openBlock(), _createBlock(_component_cl_sub_menu, {
                        "menu-info": item,
                        key: item.id
                      }, null, 8, ["menu-info"]))
                    : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 16))
}