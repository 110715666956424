/*
 * @Author: 蒋文斌
 * @Date: 2021-01-05 21:17:12
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-04-29 10:53:10
 * @Description: 微服务入口配置
 */

// 系统管理
export const admin = "iam";
// 认证
export const auth = "auth-web";
// 库存
export const inventory = "inventory-web";
// 客户
export const customer = "customer";
// 商品
export const product = "product";
// 资产
export const products = "product";
// 任务
export const task = "task-web";
// 订单
export const order = "order-web";
// 文件
export const file = "file-web";
// 财务
export const finance = "finance-web";
// 消息
export const message = "message-web";
// 流程
export const workflow = "workflow-web";
// 资产中心
export const assets = "assets";
//
export const stat = "stat";
// 微信公众号
export const mp = "mp";
