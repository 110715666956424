/*
 * @Author: 蒋文斌
 * @Date: 2021-05-21 14:37:03
 * @LastEditors: 彭一林
 * @LastEditTime: 2021-08-11 20:13:03
 * @Description: Pro Form
 */

// AntDesignVue强行依赖moment，也是相当不地道。
import moment from "moment";
import { defineComponent, reactive, PropType, ref, computed, watch, Ref } from "vue";
import {
    ConfigProvider,
    Button as AButton,
    Space as ASpace,
    Form as AForm,
    Input as AInput,
    Select as ASelect,
    InputNumber as AInputNumber,
    TreeSelect as ATreeSelect,
    Row as ARow,
    Col as ACol,
    DatePicker as ADatePicker,
    Checkbox as ACheckbox,
    Radio as ARadio,
    TimePicker as ATimePicker,
    Switch as ASwitch,
} from "ant-design-vue";
import { merge, omit } from "lodash-es";
import { FormProps } from "ant-design-vue/lib/form";
import { ValidationRule } from "ant-design-vue/lib/form/Form";
import { DatePickerMode, RangePickerValue } from "ant-design-vue/lib/date-picker/interface";
import { CheckboxValueType } from "ant-design-vue/lib/checkbox/Group";
import { ProFormItem, ProFormOptions, ValueType, SelectOption, ProSearchInputChangeData } from "@/bean/pro";
import { GeneralFunction, PlainObject } from "@/bean/base";
import { ParamRecordDTO } from "@/bean/dto";
import { useAsyncLoading } from "@/hooks/async";
import { isArray, isDefined, isFunction, isObject } from "@/utils/type";
import { proxyFormElementProps } from "@/utils/helper";
import { CheckedType, DatePickerValue, RadioGroupOption, SelectProps } from "@/bean/antd";
import ClUpload from "@/components/upload/index.vue";
import { RANGE_TYPES } from "./config";
import styles from "./index.module.scss";
import ClProSearchInput from "../pro-search-input";
import { useCalcDom } from "../pro-table/calc-dom";
import { useResponsiveSpan } from "../pro-table/responsive";
import { generateColumnKey } from "../pro-table/utils";
import { getDatePickerDefaultFormat } from "./helper";

type TreeSelectValue =
    | string
    | number
    | unknown[]
    | {
          [key: string]: unknown;
      };

const DEFAULT_FORM_PROPS: FormProps = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

type FallbackValue = {
    [key in ValueType]: unknown;
};

const FALLBACK_VALUE: Partial<FallbackValue> = {
    select: undefined,
    params: undefined,
    daterange: [],
    treeSelect: [],
    checkbox: false,
    checkboxGroup: [],
    radio: false,
    switch: false,
};

function getFallbackValue(formItem: ProFormItem & { valueType: ValueType }) {
    if (Object.prototype.hasOwnProperty.call(FALLBACK_VALUE, formItem.valueType)) {
        return FALLBACK_VALUE[formItem.valueType];
    } else if (
        formItem.valueType === "select" &&
        ["multiple", "tags"].includes(
            isFunction(formItem.props) ? (formItem.props({}) as SelectProps)?.mode : (formItem.props as SelectProps)?.mode
        )
    ) {
        return [];
    } else {
        return "";
    }
}

const DEFAULT_SELECT_OPTION: SelectOption = {
    key: "id",
    label: "name",
};

const DEFAULT_SWITCH_OPTION: Required<ProFormItem["switchOption"]> = {
    activeValue: true,
    inactiveValue: false,
};

const props = {
    // 传递一些附带配置
    options: {
        type: Object as PropType<ProFormOptions>,
        default() {
            return {
                formClass: "edit-form",
                btnsWrapperClass: "align-center mt-20",
            };
        },
    },
    // 回写表单
    writeBackForm: {
        type: Object as PropType<PlainObject>,
        default() {
            return null;
        },
    },
    // FormItem配置
    formItems: {
        type: Array as PropType<ProFormItem[]>,
        default() {
            return [];
        },
    },
    labelWidth: {
        type: String,
    },
    // 数据字典参数对象
    params: {
        type: Object as PropType<ParamRecordDTO>,
        default() {
            return {};
        },
    },
    // 是否使用底部表单按钮
    useButtons: {
        type: Boolean,
        default: true,
    },
    // 表单提交逻辑，需要返回 Promise，或者用 async 函数
    onSubmit: {
        type: Function as PropType<GeneralFunction<Promise<unknown>>>,
    },
    // 取消按钮文案
    cancelText: {
        type: String,
        default: "取消",
    },
    // 隐藏取消按钮
    hideCancel: {
        type: Boolean,
        default: false,
    },
    // 提交按钮文案
    submitText: {
        type: String,
        default: "保存",
    },
    // 隐藏保存按钮
    hideSubmit: {
        type: Boolean,
        default: false,
    },
    // 仅在 inline 布局时有效
    useGrid: {
        type: Boolean,
        default: true,
    },
    // 固定栅格，会让 isResponsive 失效
    fixedCol: {
        type: [Boolean, Number, String] as PropType<false | number | string>,
        default: false,
    },
    // 是否响应式计算栅格，前提是 useGrid
    isResponsive: {
        type: Boolean,
        default: true,
    },
    // ARow组件的props
    rowProps: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    // 禁用
    disabled: {
        type: Boolean,
        default: false,
    },
    // 是否已经排序，避免重复排序
    sorted: {
        type: Boolean,
        default: false,
    },
    // 支持指定 ant-form ref
    antFormRef: {
        type: Object as PropType<Ref>,
    },
    // 是否表单项内容自动填充剩余区域
    autoFill: {
        type: Boolean,
        default: true,
    },
};

export default defineComponent({
    name: "ClProForm",
    inheritAttrs: false,
    props,
    emits: ["cancel", "submit"],
    setup(props, { emit, expose, attrs, slots }) {
        // formItems处理
        const processedFormItems = computed(() => {
            const _items = props.formItems.map((item, index) => {
                return {
                    formItemVisible: true,
                    ...item,
                    valueType: item.valueType || "input",
                    key: item.key || generateColumnKey(item, index),
                    order: item.order || 0,
                    renderFormItemLevel: item.renderFormItemLevel || "widget",
                };
            });

            // 按 order 排序，考虑外部是不是已经排序过了
            if (!props.sorted) {
                _items.sort((a, b) => b.order - a.order);
            }

            return _items;
        });

        // 范围类型
        const rangeFormItems = computed(() => processedFormItems.value.filter((item) => RANGE_TYPES.includes(item.valueType)));

        // 处理默认值
        const defaultFormModelValue: PlainObject = {};
        processedFormItems.value.forEach((formItem) => {
            defaultFormModelValue[formItem.dataIndex] = formItem.defaultValue || getFallbackValue(formItem);
        });

        // 校验规则
        const rules = computed(() => {
            const rulesObj: PlainObject = {};
            processedFormItems.value.forEach((formItem) => {
                if (isArray<ValidationRule>(formItem.rule)) {
                    rulesObj[formItem.dataIndex] = formItem.rule;
                }
            });
            return rulesObj;
        });

        // 初始化 formModel
        const formModel = reactive(defaultFormModelValue);

        // 进行表单回写操作
        const writeBack = (writeBackForm: PlainObject) => {
            // 回写表单时，检查一下 range 类型，进行特殊的回写操作。
            const rangeForm: PlainObject = {};
            rangeFormItems.value.forEach((formItem) => {
                if (formItem.transformPropsForRange?.length === 2) {
                    const [prop1, prop2] = formItem.transformPropsForRange;
                    if (writeBackForm[prop1] && writeBackForm[prop2]) {
                        rangeForm[formItem.dataIndex] = [writeBackForm[prop1], writeBackForm[prop2]];
                    }
                }
            });
            Object.assign(formModel, props.writeBackForm, rangeForm);
        };

        // 监听 writeBackForm，有可能 writeBackForm 是异步过来的。
        watch(
            () => props.writeBackForm,
            (val) => {
                if (val) {
                    writeBack(val);
                }
            },
            { immediate: true }
        );

        // Form Ref
        const formRef = props.antFormRef || ref();

        // 处理下拉数据源
        const selectDataSources = computed(() => {
            const selectDataSourcesRaw: PlainObject<string, PlainObject[]> = {};
            processedFormItems.value
                .filter((item) => item.valueType === "select")
                .forEach((item) => {
                    selectDataSourcesRaw[item.dataIndex] = item.selectDataSource || [];
                });
            return selectDataSourcesRaw;
        });

        // 获取处理后的 formModel，主要是处理范围类型的参数
        const getProcessedFormModel = () => {
            const omitProps: Array<string> = [];
            const transformParams: PlainObject = {};
            rangeFormItems.value.forEach(({ dataIndex, transformPropsForRange }) => {
                if (Array.isArray(transformPropsForRange) && transformPropsForRange.length === 2) {
                    const [prop1, prop2] = transformPropsForRange;
                    [transformParams[prop1], transformParams[prop2]] = formModel[dataIndex] as Array<string>;
                    omitProps.push(dataIndex);
                }
            });
            return {
                ...omit(formModel, omitProps),
                ...transformParams,
            };
        };
        // 重置表单
        const resetFields = () => formRef.value.resetFields();
        // 校验
        const validate = (...args: any[]) => formRef.value.validate(...args);
        // 取消
        const onClickCancel = () => emit("cancel");
        // 保存
        const handleSave = async () => {
            await validate();
            await props.onSubmit?.(getProcessedFormModel());
        };
        const { trigger: onClickSave, loading: isSaveLoading } = useAsyncLoading(handleSave);

        // 合并form props
        const layoutProps = props.labelWidth
            ? {
                  wrapperCol: { style: { width: props.autoFill && attrs.layout !== "vertical" ? `calc(100% - ${props.labelWidth})` : "" } },
                  labelCol: { style: { width: props.labelWidth } },
              }
            : {};

        const formProps = {
            ...DEFAULT_FORM_PROPS,
            ...attrs,
            ...layoutProps,
        };

        const isUseGridValid = computed(() => props.useGrid && formProps?.layout === "inline");

        // 动态计算表单项栅格
        const isResponsiveValid = computed(() => isUseGridValid.value && !props.fixedCol && props.isResponsive);
        const getContainer = () => formRef.value.$el;
        const { containerWidth: formWidth } = useCalcDom(getContainer, isResponsiveValid.value);
        const { responsiveSpan } = useResponsiveSpan(formWidth);

        const makeGrid = (ele: JSX.Element, responsiveSpan: number, colProps: ProFormItem["colProps"]) => {
            return (
                <ACol class={styles["pro-form__grid-col"]} span={props.fixedCol !== false ? props.fixedCol : responsiveSpan} {...colProps}>
                    {ele}
                </ACol>
            );
        };

        const createFormItem = (formItem: ProFormItem, disabled: boolean) => {
            return formItem.renderFormItemLevel === "formItem" &&
                isFunction<(formModel: PlainObject, formItem: ProFormItem) => JSX.Element>(formItem.customRenderFormItem) ? (
                formItem.customRenderFormItem(formModel, formItem)
            ) : (
                <AForm.Item key={formItem.key} label={formItem.title} name={formItem.dataIndex} {...formItem.formItemProps}>
                    {() => {
                        const processedFormItemProps =
                            typeof formItem.props === "function" ? formItem.props(formModel) : formItem.props || {};
                        // 支持表单应用disabled属性时，表单整体禁用；如果表单项定义了disabled，则以表单项的disabled的具体值为准。
                        if (disabled && !isDefined(processedFormItemProps.disabled)) {
                            processedFormItemProps.disabled = true;
                        }

                        let formElement: JSX.Element | null | undefined;

                        if (isFunction<(formModel: PlainObject, formItem: ProFormItem) => JSX.Element>(formItem.customRenderFormItem)) {
                            formElement = formItem.customRenderFormItem(formModel, formItem);
                        } else {
                            switch (formItem.valueType) {
                                case "params":
                                    const code = formItem.useFullCode ? formItem.fullCode : formItem.paramCode;
                                    formElement = (
                                        <ASelect
                                            {...proxyFormElementProps(
                                                processedFormItemProps,
                                                {
                                                    value: formModel[formItem.dataIndex],
                                                    onChange: (value: string) => (formModel[formItem.dataIndex] = value),
                                                },
                                                formModel,
                                                {
                                                    showSearch: true,
                                                    optionFilterProp: "children",
                                                    allowClear: true,
                                                    placeholder: `请选择${formItem.title}`,
                                                }
                                            )}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        >
                                            {(props.params[code as string] || []).map((param) => {
                                                const optionProps = isFunction(formItem.optionProps) ? formItem.optionProps(param) : {};
                                                return (
                                                    <ASelect.Option key={param.id} value={param.paramCode} {...optionProps}>
                                                        {param.paramName}
                                                    </ASelect.Option>
                                                );
                                            })}
                                        </ASelect>
                                    );
                                    break;
                                case "select":
                                    const selectOption = merge({}, DEFAULT_SELECT_OPTION, formItem.selectOption) as Required<SelectOption>;
                                    formElement = (
                                        <ASelect
                                            {...proxyFormElementProps(
                                                processedFormItemProps,
                                                {
                                                    value: formModel[formItem.dataIndex],
                                                    onChange: (value: string | number) => (formModel[formItem.dataIndex] = value),
                                                },
                                                formModel,
                                                {
                                                    showSearch: true,
                                                    optionFilterProp: "children",
                                                    allowClear: true,
                                                    placeholder: `请选择${formItem.title}`,
                                                }
                                            )}
                                        >
                                            {selectDataSources.value[formItem.dataIndex].map((option) => {
                                                const optionProps = isFunction(formItem.optionProps) ? formItem.optionProps(option) : {};
                                                return (
                                                    <ASelect.Option
                                                        key={option[selectOption.key] as string | number}
                                                        value={option[selectOption.value] as string | number}
                                                        {...optionProps}
                                                    >
                                                        {isFunction(selectOption.label)
                                                            ? selectOption.label(option)
                                                            : option[selectOption.label as string]}
                                                    </ASelect.Option>
                                                );
                                            })}
                                        </ASelect>
                                    );
                                    break;
                                case "treeSelect":
                                    formElement = (
                                        <ATreeSelect
                                            value={formModel[formItem.dataIndex] as TreeSelectValue}
                                            onChange={(value) => (formModel[formItem.dataIndex] = value)}
                                            allowClear
                                            maxTagCount={10}
                                            showCheckedStrategy={ATreeSelect.SHOW_ALL}
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></ATreeSelect>
                                    );
                                    break;
                                case "inputNumber":
                                    formElement = (
                                        <AInputNumber
                                            value={formModel[formItem.dataIndex] as string}
                                            placeholder={`请输入${formItem.title}`}
                                            onChange={(value) => {
                                                formModel[formItem.dataIndex] = value;
                                            }}
                                            {...processedFormItemProps}
                                        ></AInputNumber>
                                    );
                                    break;
                                case "checkbox":
                                    formElement = (
                                        <ACheckbox
                                            checked={formModel[formItem.dataIndex] as boolean}
                                            onChange={(e) => {
                                                formModel[formItem.dataIndex] = e.target.checked;
                                            }}
                                            {...processedFormItemProps}
                                        ></ACheckbox>
                                    );
                                    break;
                                case "checkboxGroup":
                                    formElement = (
                                        <ACheckbox.Group
                                            value={formModel[formItem.dataIndex] as CheckboxValueType[]}
                                            onChange={(checkedValue) => {
                                                formModel[formItem.dataIndex] = checkedValue;
                                            }}
                                            {...processedFormItemProps}
                                        ></ACheckbox.Group>
                                    );
                                    break;
                                case "radio":
                                    formElement = (
                                        <ARadio
                                            checked={formModel[formItem.dataIndex] as boolean}
                                            onChange={(e) => {
                                                formModel[formItem.dataIndex] = e.target.checked;
                                            }}
                                            {...processedFormItemProps}
                                        ></ARadio>
                                    );
                                    break;
                                case "radioGroup":
                                    formElement = (
                                        <ARadio.Group
                                            value={formModel[formItem.dataIndex] as boolean}
                                            onChange={(e) => {
                                                formModel[formItem.dataIndex] = e.target.value;
                                            }}
                                            {...processedFormItemProps}
                                        ></ARadio.Group>
                                    );
                                    break;
                                case "radioButtonGroup":
                                    formElement = (
                                        <ARadio.Group
                                            value={formModel[formItem.dataIndex] as boolean}
                                            onChange={(e) => {
                                                formModel[formItem.dataIndex] = e.target.value;
                                            }}
                                            {...omit(processedFormItemProps, "options")}
                                        >
                                            {(processedFormItemProps.options as RadioGroupOption[]).map((option) => {
                                                return <ARadio.Button value={option.value}>{option.label}</ARadio.Button>;
                                            })}
                                        </ARadio.Group>
                                    );
                                    break;
                                case "textarea":
                                    formElement = (
                                        <AInput.TextArea
                                            value={formModel[formItem.dataIndex] as string}
                                            placeholder={`请输入${formItem.title}`}
                                            onInput={(e) => {
                                                formModel[formItem.dataIndex] = e.target.value;
                                            }}
                                            allowClear
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></AInput.TextArea>
                                    );
                                    break;
                                case "inputPassword":
                                    formElement = (
                                        <AInput.Password
                                            value={formModel[formItem.dataIndex] as string}
                                            placeholder={`请输入${formItem.title}`}
                                            onInput={(e) => {
                                                formModel[formItem.dataIndex] = e.target.value;
                                            }}
                                            allowClear
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></AInput.Password>
                                    );
                                    break;
                                case "date":
                                    formElement = (
                                        <ADatePicker
                                            {...proxyFormElementProps(
                                                processedFormItemProps,
                                                {
                                                    value: formModel[formItem.dataIndex],
                                                    onChange: (date: DatePickerValue) => (formModel[formItem.dataIndex] = date),
                                                    onPanelChange: (date: DatePickerValue) => (formModel[formItem.dataIndex] = date),
                                                },
                                                formModel,
                                                {
                                                    format: getDatePickerDefaultFormat(processedFormItemProps.mode as DatePickerMode),
                                                    valueFormat: "YYYY-MM-DD HH:mm:ss",
                                                }
                                            )}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></ADatePicker>
                                    );
                                    break;
                                case "daterange":
                                    formElement = (
                                        <ADatePicker.RangePicker
                                            value={formModel[formItem.dataIndex] as [string, string]}
                                            onChange={(dates) => ((formModel[formItem.dataIndex] as RangePickerValue) = dates)}
                                            valueFormat="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                                            }}
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></ADatePicker.RangePicker>
                                    );
                                    break;
                                case "time":
                                    formElement = (
                                        <ATimePicker
                                            value={formModel[formItem.dataIndex]}
                                            onChange={(time) => (formModel[formItem.dataIndex] = time)}
                                            valueFormat="HH:mm:ss"
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></ATimePicker>
                                    );
                                    break;
                                case "switch":
                                    const switchOption = merge(DEFAULT_SWITCH_OPTION, formItem.switchOption);
                                    formElement = (
                                        <ASwitch
                                            checked={formModel[formItem.dataIndex] === switchOption.activeValue}
                                            onChange={(checked: CheckedType) =>
                                                (formModel[formItem.dataIndex] = checked
                                                    ? switchOption.activeValue
                                                    : switchOption.inactiveValue)
                                            }
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></ASwitch>
                                    );
                                    break;
                                case "upload":
                                    formElement = <ClUpload {...processedFormItemProps} v-slots={processedFormItemProps["v-slots"]} />;
                                    break;
                                case "inputSearch":
                                    formElement = (
                                        <AInput.Search
                                            value={formModel[formItem.dataIndex] as string}
                                            placeholder={`请输入${formItem.title}`}
                                            onInput={(e) => {
                                                formModel[formItem.dataIndex] = e.target.value;
                                            }}
                                            allowClear
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        />
                                    );
                                    break;
                                case "proSearchInput":
                                    const proSearchInputProps: PlainObject = {
                                        placeholder: `请选择${formItem.title}`,
                                        ...processedFormItemProps,
                                        value: formModel[formItem.dataIndex],
                                        onChange: ({ text, rows, selectOption }: ProSearchInputChangeData) => {
                                            // 显示文本的赋值
                                            formModel[formItem.dataIndex] = text;
                                            // 隐藏域的赋值，一般是 id 之类的
                                            if (processedFormItemProps.valueDataIndex) {
                                                if ((processedFormItemProps.proSearchProps as PlainObject).multiple) {
                                                    // 多选
                                                    const keys = rows.map((item) => item[selectOption.key]);
                                                    formModel[processedFormItemProps.valueDataIndex as string] = keys;
                                                } else {
                                                    // 单选
                                                    const row = rows[0];
                                                    formModel[processedFormItemProps.valueDataIndex as string] = row[selectOption.key];
                                                }
                                            }
                                            // 有时候业务方会希望去做更多自定义的赋值行为，比如选了一个人，不仅要做id，姓名的赋值，可能还要带出身份证之类的
                                            // 这里通过抛出事件由业务决定怎么使用
                                            if (isFunction(processedFormItemProps.onSelect)) {
                                                processedFormItemProps.onSelect(formModel, rows);
                                            }
                                        },
                                    };
                                    // form 禁用的处理
                                    if (disabled) {
                                        if (!isObject(proSearchInputProps.inputSearchProps)) {
                                            // 如果 inputSearchProps 没传对象，默认给个空对象
                                            proSearchInputProps.inputSearchProps = {};
                                        }
                                        // 然后处理禁用
                                        if (!isDefined(proSearchInputProps.inputSearchProps)) {
                                            (proSearchInputProps.inputSearchProps as PlainObject).disabled = true;
                                        }
                                    }
                                    formElement = <ClProSearchInput {...proSearchInputProps} />;
                                    break;
                                case "input":
                                default:
                                    formElement = (
                                        <AInput
                                            value={formModel[formItem.dataIndex] as string}
                                            placeholder={`请输入${formItem.title}`}
                                            onInput={(e) => {
                                                formModel[formItem.dataIndex] = e.target.value;
                                            }}
                                            allowClear
                                            {...processedFormItemProps}
                                            v-slots={processedFormItemProps["v-slots"]}
                                        ></AInput>
                                    );
                                    break;
                            }
                        }

                        return (
                            <>
                                {typeof formItem.beforeFormItem === "function" ? formItem.beforeFormItem(formModel, formItem) : null}
                                {formElement}
                                {typeof formItem.afterFormItem === "function" ? formItem.afterFormItem(formModel, formItem) : null}
                            </>
                        );
                    }}
                </AForm.Item>
            );
        };

        const formSection = computed(() => {
            const formSection = processedFormItems.value
                .filter((formItem) => {
                    const visible =
                        typeof formItem.formItemVisible === "function" ? formItem.formItemVisible(formModel) : formItem.formItemVisible;
                    return visible;
                })
                .map((formItem) => {
                    const formItemElement = createFormItem(formItem, props.disabled);
                    return isUseGridValid.value ? makeGrid(formItemElement, responsiveSpan.value, formItem.colProps) : formItemElement;
                });
            return isUseGridValid.value ? (
                <ARow class={styles["pro-form__grid-row"]} gutter={20} {...props.rowProps}>
                    {formSection}
                </ARow>
            ) : (
                formSection
            );
        });

        expose({
            // 特殊情况还是需要暴露 AForm 的 ref
            formRef,
            // 一般就是用到 resetFields，这里直接暴露方法
            resetFields,
            // 一般就是用到 validate，这里直接暴露方法
            validate,
            // 支持暴露未加工的formModel
            formModel,
            // 也支持暴露加工后的formModel，但是考虑到实时computed的性能损耗不必要，这里是通过方法暴露。
            getProcessedFormModel,
            // Form的宽度
            formWidth,
        });

        return () => {
            const formClass = `${styles["pro-form"]} ${props.options.formClass || ""} ${
                props.autoFill ? styles["pro-form--autofill"] : styles["pro-form--autofill-disabled"]
            } ${isUseGridValid.value ? styles["pro-form--grid"] : ""} ${props.labelWidth ? styles["pro-form--labelWidth-fixed"] : ""}`;
            const btnsWrapperClass = props.options.btnsWrapperClass;

            return (
                <ConfigProvider getPopupContainer={() => document.body}>
                    <AForm
                        ref={formRef}
                        model={formModel}
                        rules={rules.value as Record<string, ValidationRule | ValidationRule[]>}
                        class={formClass}
                        {...formProps}
                    >
                        {formSection.value}
                        {props.useButtons ? (
                            <AForm.Item class={btnsWrapperClass} wrapperCol={{ span: 24 }}>
                                <ASpace>
                                    {slots.beforeButton?.()}
                                    {props.hideCancel ? null : <AButton onClick={onClickCancel}>{props.cancelText}</AButton>}
                                    {slots.middleButton?.()}
                                    {props.hideSubmit ? null : (
                                        <AButton type="primary" loading={isSaveLoading.value} onClick={onClickSave}>
                                            {props.submitText}
                                        </AButton>
                                    )}
                                    {slots.afterButton?.()}
                                </ASpace>
                            </AForm.Item>
                        ) : null}
                    </AForm>
                </ConfigProvider>
            );
        };
    },
});
