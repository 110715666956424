/*
 * @Author: 蒋文斌
 * @Date: 2021-01-05 22:17:58
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-07 10:16:27
 * @Description: 附件模块
 */
import { ApiService } from "@/services/index";
import { admin } from "@/services/config";
import { RecordResponse } from "@/bean/xhr";
import { PlainObject } from "@/bean/base";

class AttachmentService extends ApiService {
    delete(id: number) {
        return this.$del("delete", { id });
    }

    deleteBatch(ids: number[]) {
        return this.$del("deleteBatch", { ids });
    }

    detail(id: number) {
        return this.$get<RecordResponse>("detail", { id });
    }

    uploadFiles(formdata: FormData) {
        // 超时设置为 300s
        return this.$upload("uploadFiles", formdata, { timeout: 300000 });
    }

    deleteByFileId(params: PlainObject) {
        return this.$del("deleteByFileId", params);
    }
}

export const attachmentService = new AttachmentService(admin, "attachment");
