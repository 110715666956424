/*
 * @Author: 蒋文斌
 * @Date: 2021-05-14 09:16:53
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-14 09:32:00
 * @Description: Tab页右键菜单交互
 */

import { throttle } from "lodash-es";
import { Ref, ref } from "vue";
import { GeneralFunction } from "@/bean/base";

interface UseContextmenuResponse {
    isContextmenuVisible: Ref<boolean>;
    isContextmenuDisabled: Ref<boolean>;
    tabKey: Ref<string | null>;
    closeContextmenu: GeneralFunction;
    onTabsMousemove: GeneralFunction;
    onTabContextmenu: GeneralFunction;
}

export const useContextmenu = (): UseContextmenuResponse => {
    const isContextmenuVisible = ref(false);
    const isContextmenuDisabled = ref(true);
    // 右键菜单操作的tab key
    const tabKey = ref<string | null>(null);
    // 触发关闭右键菜单，同时tabKey置空
    const closeContextmenu = () => {
        isContextmenuVisible.value = false;
        tabKey.value = null;
    };
    // 检查判断是否是可以唤起右键菜单的元素，在这里确定右键触发的元素还是有bug，暂时通过监听contextmenu确定
    const checkContextMenuElement = (e: MouseEvent) => {
        let isValid = false;
        if ((e.target as HTMLElement).classList.contains("ant-tabs-tab")) {
            // 说明直接是标签最外层容器，允许弹出右键菜单
            isValid = true;
        } else {
            // 检查其祖先元素有没有 ant-tabs-tab，检查终点是 ant-tabs。如果满足，允许弹出右键菜单
            let parentNode = (e.target as HTMLElement).parentNode as HTMLElement;
            while (!isValid && parentNode && !parentNode.classList.contains("ant-tabs")) {
                if (parentNode.classList.contains("ant-tabs-tab")) {
                    isValid = true;
                } else {
                    parentNode = parentNode.parentNode as HTMLElement;
                }
            }
        }
        if (isValid) {
            isContextmenuDisabled.value = false;
        } else {
            closeContextmenu();
            isContextmenuDisabled.value = true;
        }
    };
    // Tab鼠标移动
    const onTabsMousemove = throttle(checkContextMenuElement, 300, { leading: true });
    // Tab右键触发
    const onTabContextmenu = (e: MouseEvent) => {
        let target = null;
        if ((e.target as HTMLElement).classList.contains("ant-tabs-tab")) {
            target = e.target;
        } else {
            // 检查其祖先元素有没有 ant-tabs-tab，检查终点是 ant-tabs。如果满足，允许弹出右键菜单
            let parentNode = (e.target as HTMLElement).parentNode as HTMLElement;
            while (!target && parentNode && !parentNode.classList.contains("ant-tabs")) {
                if (parentNode.classList.contains("ant-tabs-tab")) {
                    target = parentNode;
                } else {
                    parentNode = parentNode.parentNode as HTMLElement;
                }
            }
        }
        if (target) {
            const span = (target as HTMLElement).querySelector("span[data-key]") as HTMLElement;
            tabKey.value = span.getAttribute("data-key");
        } else {
            tabKey.value = null;
        }
    };

    return {
        isContextmenuVisible,
        isContextmenuDisabled,
        tabKey,
        closeContextmenu,
        onTabsMousemove,
        onTabContextmenu,
    };
};
