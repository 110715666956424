/*
 * @Author: 蒋文斌
 * @Date: 2021-04-25 16:48:34
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-26 14:42:57
 * @Description: 已鉴权的公共路由，按照自动生成规范，路由name需要用大驼峰写法
 */
import { RouteRecordRaw } from "vue-router";
import { Test } from "./test";

export const MY_ROUTE: RouteRecordRaw = {
    name: "My",
    path: "/my",
    component: () => import(/* webpackChunkName: "my" */ "@/views/my/index.vue"),
    meta: {
        name: "个人中心",
        excludeInTab: true,
        layout: "ClBlankLayout",
    },
};

// 预留一些免权限页面（但是也要登录认证后访问）
export const COMMON_ROUTES: Array<RouteRecordRaw> = [MY_ROUTE];

if (process.env.NODE_ENV === "development") {
    // 测试页面只在开发环境使用，生产环境不能放出
    COMMON_ROUTES.push(Test);
}
