/*
 * @Author: 蒋文斌
 * @Date: 2021-06-24 09:39:12
 * @LastEditors: 杨鸿凯
 * @LastEditTime: 2021-08-11 20:56:44
 * @Description: Pro Search
 */

import { computed, defineComponent, nextTick, PropType, reactive, ref } from "vue";
import { Alert, Divider, Tag, Space, Button as AButton, message } from "ant-design-vue";
import ClProTable from "@/components/pro/pro-table";
import ClOrgLayout from "@/components/layouts/org-layout/index.vue";
import { GeneralFunction, PlainObject } from "@/bean/base";
import { RecordRowDTO } from "@/bean/dto";
import { isFunction, isObject } from "@/utils/type";
import { useAsyncLoading } from "@/hooks/async";
import { camelize } from "@/utils/helper";
import { BaseSelectOption } from "@/bean/pro";
import styles from "./index.module.scss";

const props = {
    // 是否是多选模式
    multiple: {
        type: Boolean,
        default: false,
    },
    // 选择配置项，决定值和名称取什么字段，配合 ProSearchInput 使用时，应该绑定在 ProSearchInput 上
    selectOption: {
        type: Object as PropType<Required<BaseSelectOption>>,
        default() {
            return {
                key: "id",
                label: "name",
            };
        },
    },
    checkedRows: {
        type: Array as PropType<PlainObject[]>,
        default() {
            return [];
        },
    },
    // 取消按钮文案
    cancelText: {
        type: String,
        default: "取消",
    },
    // 提交按钮文案
    submitText: {
        type: String,
        default: "保存",
    },
    // 提交回调，最好是异步的，可以支持按钮loading交互
    onSubmit: {
        type: Function as PropType<GeneralFunction<Promise<unknown>>>,
    },
    // 自定义的 rowSelection 配置
    customRowSelection: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    // 是否使用左侧树作为筛选条件
    useTree: {
        type: Boolean,
        default: false,
    },
    // 左侧树组件的 props
    treeLayoutProps: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
};

export default defineComponent({
    name: "ClProSearch",
    inheritAttrs: false,
    props,
    emits: ["cancel"],
    setup(props, { emit, attrs, expose, slots }) {
        const _attrs: PlainObject = {};
        const keys = Object.keys(attrs);
        keys.forEach((key) => {
            _attrs[camelize(key)] = attrs[key];
        });

        // refs
        const proTableRef = ref();
        const treeLayoutRef = ref();

        // 表单配置项
        const searchConf = reactive({
            labelWidth: "100px",
        });

        const appendRowSelection = computed<PlainObject>(() => {
            return {
                selectedRows: props.checkedRows,
                selectedRowKeys: props.checkedRows.map((item) => item[props.selectOption.key]),
                ...props.customRowSelection,
                type: props.multiple ? "checkbox" : "radio",
            };
        });

        // 移除已选择的
        const removeSelectedItem = (key: number) => proTableRef.value.removeSelectedItem(key);

        const selectedRows = computed<RecordRowDTO[]>(() => {
            const selection = proTableRef?.value?.rowSelection || {};
            return selection.selectedRows || [];
        });

        const selectedRowKeys = computed<RecordRowDTO[]>(() => {
            const selection = proTableRef?.value?.rowSelection || {};
            return selection.selectedRowKeys || [];
        });

        const selectInfo = computed(() => {
            return `已选择${selectedRows.value.length}项`;
        });

        const antTableProps = reactive({
            scroll: {},
        });

        const onClickCancel = () => emit("cancel");

        // 提交处理
        const handleSave = async () => {
            if (selectedRows.value.length === 0) {
                message.warning("请至少选择一行！");
                return Promise.reject(new Error("at least one row"));
            }
            if (isFunction(props.onSubmit)) {
                await props.onSubmit(selectedRows.value, selectedRowKeys.value);
            }
        };

        const { trigger: onClickSave, loading: isSaveLoading } = useAsyncLoading(handleSave);

        // 查询
        const search = () => proTableRef.value.search();

        // 左侧树带出的条件
        const fixedForm = reactive<{ orgId: number | null }>({
            orgId: null,
        });
        // 左侧树选择
        const onOrgTreeSelect = ([key]: number[]) => {
            fixedForm.orgId = key || null;
            nextTick(() => {
                search();
            });
        };

        // 对传给 ProTable 的 fixedForm 做一个组合
        const composedFixedForm = computed(() => {
            const fixedFormFromAttrs = isObject(_attrs.fixedForm) ? _attrs.fixedForm : {};
            return props.useTree
                ? {
                      ...fixedForm,
                      ...fixedFormFromAttrs,
                  }
                : fixedFormFromAttrs;
        });

        const _proTableProps = computed(() => {
            return {
                searchConf,
                enableSelect: true,
                appendRowSelection: appendRowSelection.value,
                toggleLimit: 1,
                ...antTableProps,
                ..._attrs,
                ref: proTableRef,
                fixedForm: composedFixedForm.value,
                "v-slots": {
                    "before-table": () => {
                        return (
                            <div class={styles.select__tips}>
                                <Alert message={selectInfo.value} type="info" show-icon />
                            </div>
                        );
                    },
                    ...slots,
                },
            };
        });

        const selectSection = computed(() => {
            return (
                <section class={styles["pro-search__top"]}>
                    <main class={styles["pro-search__main"]}>
                        <ClProTable {..._proTableProps.value} v-slots={_proTableProps.value["v-slots"]}></ClProTable>
                    </main>

                    <aside class={styles["pro-search__aside"]}>
                        <Divider>已选择</Divider>

                        <div class={styles.select__list}>
                            {selectedRows.value.map((tag) => {
                                return (
                                    <Tag
                                        key={tag[props.selectOption.key] as number}
                                        closable
                                        onClose={() => removeSelectedItem(tag[props.selectOption.key] as number)}
                                    >
                                        {tag[props.selectOption.label]}
                                    </Tag>
                                );
                            })}
                        </div>
                    </aside>
                </section>
            );
        });

        expose({
            treeLayoutRef,
            proTableRef,
            search,
        });

        return () => {
            const _orgLayoutProps: PlainObject = {
                ...props.treeLayoutProps,
                ref: treeLayoutRef,
                onSelect: onOrgTreeSelect,
            };

            return (
                <section class={styles["pro-search__wrapper"]}>
                    {props.useTree ? <ClOrgLayout {..._orgLayoutProps}>{selectSection.value}</ClOrgLayout> : selectSection.value}

                    <footer class={styles["pro-search__footer"]}>
                        <Space>
                            <AButton onClick={onClickCancel}>{props.cancelText}</AButton>
                            <AButton type="primary" loading={isSaveLoading.value} onClick={onClickSave}>
                                {props.submitText}
                            </AButton>
                        </Space>
                    </footer>
                </section>
            );
        };
    },
});
