/*
 * @Author: 蒋文斌
 * @Date: 2020-12-22 11:13:02
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-06-07 16:30:11
 * @Description: 生成路由
 */
import { RouteMeta } from "vue-router";
import { AUTH_TYPES, DEFAULT_CUSTOMIZED_INFO } from "@/utils/const";
import { LAYOUT_TEMPLATES } from "@/utils/templates";
import { FlatMenuItemDTO, DynamicPageMeta, MenuItemDTO, CustomizedInfoDTO } from "@/bean/dto";
import { RenderOptions } from "@/bean/base";

import { capitalize, checkJsonString } from "@/utils/helper";

interface JoinedMeta extends Partial<DynamicPageMeta>, RouteMeta {
    name?: string;
    sort?: number;
    authId: number;
}

interface PageRoute {
    name: string | undefined;
    path: string;
    component: () => Promise<unknown>;
    meta: JoinedMeta;
}

function generateRoute(page: FlatMenuItemDTO, customizedInfo: CustomizedInfoDTO, flatMenus: FlatMenuItemDTO[]): PageRoute {
    const { meta: dynamicMeta } = page;

    // 处理继承布局
    if (dynamicMeta.inheritLayout) {
        // 继承布局，向上扁平递归查询，找到第一个合法的布局，可能是模板，也可能是自定义布局
        dynamicMeta.layout = getInheritLayoutFlatten(page, flatMenus);
    } else if (dynamicMeta.useTemplate) {
        const templateLayout = getTemplateLayout(dynamicMeta.layoutTemplateCode);
        if (templateLayout) {
            dynamicMeta.layout = templateLayout;
        }
    } else if (dynamicMeta.layout) {
        dynamicMeta.layout = getParsedLayout(dynamicMeta.layout as string);
    }

    const splitValues = page.authRoute.split(/[/-]/);
    const routeName = splitValues.map(capitalize).join("");
    const compName =
        (dynamicMeta.useCustomizedCompName && dynamicMeta.compName) ||
        (page.component && page.component.split(/[/-]/).map(capitalize).join("")) ||
        "";

    const route: PageRoute = {
        name: routeName,
        path: page.authRoute,
        component: () =>
            import(/* webpackChunkName: "[request]" */ `@/views${page.component}.vue`)
                .then((m) => {
                    // 自动更新组件name
                    if (compName) {
                        m.default.name = compName;
                    }
                    return m;
                })
                .catch((err) => {
                    console.error(err);
                    return import("@/views/404/index.vue");
                }),
        meta: {
            name: page.nameCN,
            sort: page.sort,
            ...dynamicMeta,
            compName,
            // 本来考虑在render那里监听customizedInfo.config.defaultLayout，但是有多一次的重绘，最好还是在生成路由时确定layout。
            layout: dynamicMeta.layout || customizedInfo.config.defaultLayout || DEFAULT_CUSTOMIZED_INFO.config.defaultLayout,
            authId: page.id,
            isDynamic: page.authRoute.includes(":"),
        },
    };
    return route;
}

export function generateRoutesByPages(
    pages: FlatMenuItemDTO[],
    customizedInfo: CustomizedInfoDTO,
    flatMenus: FlatMenuItemDTO[]
): PageRoute[] {
    return pages.map((page) => generateRoute(page, customizedInfo, flatMenus));
}

/**
 * 找到顺序上第一个menu，判断是不是页面类型，如果是页面，取path跳转；
 * 如果只是目录，检查下remarks.redirect有没有值，有的话，需要检查remarks.redirect在不在路由表中，
 * 如果在，跳转remarks.redirect；如果不在，就检查menu下有没有children，针对children再做findRootRedirectRoute递归检查。
 * 如果第一个menu找不到，就继续找第二个，直到没有为止。
 */
export function findRootRedirectRoute(
    menuList: MenuItemDTO[],
    pageList: FlatMenuItemDTO[],
    excludePaths: string[] = []
): string | undefined {
    const sortedMenus = [...menuList].sort((a, b) => a.disOrder - b.disOrder);
    for (let index = 0; index < sortedMenus.length; index++) {
        const menu = sortedMenus[index];
        const result = findRedirectRouteByMenu(menu, pageList, excludePaths);
        if (result) {
            return result;
        }
    }
}

export function findRedirectRouteByMenu(menu: MenuItemDTO, pageList: FlatMenuItemDTO[], excludePaths: string[] = []): string | undefined {
    if (menu.authType === AUTH_TYPES.page && menu.authRoute && !excludePaths.includes(menu.path)) {
        return menu.path;
    } else if (menu.authType === AUTH_TYPES.directory) {
        const { meta } = menu;
        if (meta && meta.redirect) {
            const findPage = pageList.find((page) => page.path === meta.redirect);
            if (findPage && !excludePaths.includes(meta.redirect)) {
                return meta.redirect;
            }
        } else if (menu.children && menu.children.length > 0) {
            return findRootRedirectRoute(menu.children, pageList, excludePaths);
        }
    }
}

type FindMenuParams = {
    menus?: FlatMenuItemDTO[];
    matchKey?: "path" | "authRoute";
    path?: string;
    endParentId?: number;
};

export function findRootMenuByRoutePath({ menus = [], matchKey = "path", path, endParentId = 1 }: FindMenuParams = {}):
    | FlatMenuItemDTO
    | undefined {
    const page = menus.find(
        (item) => item.authType === AUTH_TYPES.page && decodeURIComponent(item[matchKey]) === decodeURIComponent(path as string)
    );
    let temp = page;
    function findIterator(item: FlatMenuItemDTO) {
        return temp && temp.parentId === item.id;
    }
    while (temp && temp.parentId !== endParentId) {
        temp = menus.find(findIterator);
    }
    return temp;
}

export function findMenuChainByRoutePath({ menus = [], matchKey = "path", path, endParentId = 1 }: FindMenuParams = {}): FlatMenuItemDTO[] {
    const page = menus.find(
        (item) => item.authType === AUTH_TYPES.page && decodeURIComponent(item[matchKey]) === decodeURIComponent(path as string)
    );
    const menuChain = [];
    let temp: FlatMenuItemDTO | undefined;
    function findIterator(item: FlatMenuItemDTO) {
        return temp && temp.parentId === item.id;
    }
    if (page) {
        temp = page;
        while (temp && temp.parentId !== endParentId) {
            menuChain.unshift(temp);
            temp = menus.find(findIterator);
        }
        if (temp) {
            menuChain.unshift(temp);
        }
    }
    return menuChain;
}

export function getParsedLayout(layout: string): RenderOptions | undefined {
    const result = checkJsonString(layout);
    if (result.valid) {
        try {
            const parsedLayoutOption = JSON.parse(layout);
            return parsedLayoutOption as RenderOptions;
        } catch (err) {
            // 不是合法的 json 字符串
            console.error(err);
        }
    }
}

export function getTemplateLayout(templateCode?: string) {
    const template = LAYOUT_TEMPLATES.find((item) => item.code === templateCode);
    if (template) {
        return template.option;
    }
}

/**
 * 向上扁平递归查询继承布局
 * @param page
 * @param flatMenus
 */
export function getInheritLayoutFlatten(page: FlatMenuItemDTO, flatMenus: FlatMenuItemDTO[]): RenderOptions | undefined {
    const parent = flatMenus.find((item) => item.id === page.parentId);
    if (parent) {
        // 先检查是不是继承
        if (parent.meta.inheritLayout) {
            // 继承，继续递归
            return getInheritLayoutFlatten(parent, flatMenus);
        } else {
            // 不继承，检查parent
            // 先判断是不是用模板
            if (parent.meta.useTemplate) {
                // 使用模板
                return getTemplateLayout(parent.meta.layoutTemplateCode);
            } else if (parent.meta.layout) {
                // 如果自定义布局
                return getParsedLayout(parent.meta.layout as string);
            }
        }
    }
}
