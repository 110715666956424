import { Theme } from "@/store/modules/prefer";
// import { darkThemeVars, lightThemeVars } from "@/config/theme";
import store from "@/store";
import { PREFER_MODULE } from "@/store/constants";

/**
 * 初始化调用一次。
 * 异步获取到租户信息后调用一次，防止租户默认主题和系统内置默认主题不一致。
 */
export function initTheme() {
    const value = store.getters[`${PREFER_MODULE}/theme`];
    setTheme(value);
}

export function setTheme(value: Theme) {
    document.documentElement.setAttribute("data-theme", value);
    // window.less.modifyVars(value === "light" ? lightThemeVars : darkThemeVars);
}
