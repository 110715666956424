
import { defineComponent, PropType, ref } from "vue";
import { DownloadOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { AxiosResponse } from "axios";
import { useAsyncLoading } from "@/hooks/async";
import { downloadFromBlob } from "@/utils/helper";
import { GeneralFunction } from "@/bean/base";

export default defineComponent({
    name: "ClImportTemplate",
    components: {
        DownloadOutlined,
    },
    props: {
        templateApi: {
            type: Function as PropType<GeneralFunction<Promise<AxiosResponse>>>,
            required: true,
        },
        importApi: {
            type: Function as PropType<GeneralFunction<Promise<AxiosResponse>>>,
            required: true,
        },
    },
    emits: ["success"],
    setup(props, { emit }) {
        // refs
        const uploadRef = ref();

        const handleDownloadTemplate = async () => {
            const { data, headers } = await props.templateApi();
            downloadFromBlob(data, headers["content-type"], headers.filename);
        };

        const { trigger: onClickDownload, loading: isDownloadLoading } = useAsyncLoading(handleDownloadTemplate);

        const handleConfirm = async () => {
            const formData = new FormData();
            formData.append("file", uploadRef.value.fileList[0]);
            formData.append("override", "0");
            await props.importApi(formData);
            message.success("导入成功！");
            emit("success");
        };

        const { trigger: onConfirm, loading: isImportLoading } = useAsyncLoading(handleConfirm);

        return {
            uploadRef,
            onClickDownload,
            isDownloadLoading,
            onConfirm,
            isImportLoading,
        };
    },
});
