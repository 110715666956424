/*
 * @Author: 蒋文斌
 * @Date: 2021-05-01 19:38:49
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-07-05 09:23:10
 * @Description: 描述列表
 */

import { defineComponent, PropType, computed } from "vue";
import { Descriptions, Image as AImage } from "ant-design-vue";
import { isFunction } from "lodash-es";
import { ProTableColumn } from "@/bean/pro";
import { RecordRowDTO, ParamRecordDTO } from "@/bean/dto";
import { PrimitiveType } from "@/bean/base";

import { paramFilter } from "@/utils/filters";
import { resolveDeep } from "@/utils/helper";
import styles from "./index.module.scss";

interface EnhancedDescriptionItem extends ProTableColumn {
    value: PrimitiveType;
}

type DescriptionSize = "default" | "middle" | "small";

const props = {
    fields: {
        type: Array as PropType<ProTableColumn[]>,
        default() {
            return [];
        },
    },
    dataSource: {
        type: Object as PropType<RecordRowDTO>,
        default() {
            return {};
        },
    },
    params: {
        type: Object as PropType<ParamRecordDTO>,
        default() {
            return {};
        },
    },
    size: {
        type: String as PropType<DescriptionSize>,
        default: "small",
    },
    labelWidth: {
        type: Number,
    },
    contentSpan: {
        type: Number,
        default: 3,
    },
};

export default defineComponent({
    name: "ClDescriptions",
    props,
    setup(props, { attrs }) {
        const descriptionList = computed(() => {
            return props.fields.map((field) => {
                const value = resolveDeep(props.dataSource, field.dataIndex as string);
                return {
                    ...field,
                    descriptionType: field.descriptionType || "text",
                    value,
                } as EnhancedDescriptionItem;
            });
        });

        return () => {
            return (
                <Descriptions
                    class={styles["descriptions-wrapper"]}
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    size={props.size}
                    {...attrs}
                >
                    {descriptionList.value.map((item, index) => {
                        return (
                            <Descriptions.Item
                                label={<div style={{ width: props.labelWidth && `${props.labelWidth}px` }}>{item.title}</div>}
                                key={item.dataIndex}
                            >
                                {() => {
                                    if (isFunction(item.customDescriptionRender)) {
                                        return item.customDescriptionRender && item.customDescriptionRender(item.value, props.dataSource);
                                    }
                                    switch (item.descriptionType) {
                                        case "params":
                                            return (
                                                props.dataSource[`${item.dataIndex}ByTran`] ||
                                                paramFilter(item.value as string, props.params[item.paramCode as string])
                                            );
                                        case "translate":
                                            return props.dataSource[`${item.dataIndex}ByTran`] || item.value;
                                        case "image":
                                            return item.value ? (
                                                <AImage wrapperClassName={styles.descriptionImage} src={item.value as string}></AImage>
                                            ) : null;
                                        case "text":
                                        default:
                                            return (
                                                <div style={{ width: props.labelWidth && `${props.labelWidth * props.contentSpan}px` }}>
                                                    {isFunction(item.transform)
                                                        ? item.transform({
                                                              text: item.value,
                                                              record: props.dataSource,
                                                              index,
                                                              colIndex: -1,
                                                          })
                                                        : item.value}
                                                </div>
                                            );
                                    }
                                }}
                            </Descriptions.Item>
                        );
                    })}
                </Descriptions>
            );
        };
    },
});
