
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { DEFAULT_CUSTOMIZED_INFO } from "@/utils/const";
import { key } from "@/store";

export default defineComponent({
    name: "ClAvatar",
    props: {
        size: {
            type: Number,
        },
        url: {
            type: String,
        },
    },
    setup() {
        const store = useStore(key);
        const avatarError = ref(`this.src='${DEFAULT_CUSTOMIZED_INFO.config.logoUrl}';this.onerror=null`);
        const customizedInfo = computed(() => store.state.customer.customizedInfo);
        return {
            avatarError,
            customizedInfo,
        };
    },
});
