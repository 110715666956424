import ClipboardJS from "clipboard";
import { message } from "ant-design-vue";

export const initClipboard = (): void => {
    // data-clipboard-text
    const copyHelper = new ClipboardJS(".copyable-trigger");

    copyHelper.on("success", function () {
        message.success("内容已复制到剪贴板！");
    });
};
