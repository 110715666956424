
import { createFromIconfontCN } from "@ant-design/icons-vue";
import { defineComponent, computed } from "vue";
import { SVG_ICON_PREFIX, SVG_ICON_SCRIPT_URL, SVG_FALLBACK_ICON } from "@/config";
import icons from "@/assets/json/icons.json";

const MyIcon = createFromIconfontCN({
    // 每次在 iconfont 修改了图标，需要重新生成链接，替换掉 src/config/index 的 SVG_ICON_SCRIPT_URL，并且执行 yarn gen-icon 命令
    scriptUrl: SVG_ICON_SCRIPT_URL,
});

export default defineComponent({
    name: "ClIconSvg",
    props: {
        icon: {
            type: String,
        },
        size: {
            type: Number,
        },
        // 只对单色图标有用
        color: {
            type: String,
            default: "inherit",
        },
    },
    setup(props, { attrs }) {
        // 有可能传过来是null, prop的default不会生效
        const validIcon = computed(() => {
            const isValid = props.icon && icons.includes(props.icon);
            return `${SVG_ICON_PREFIX}${isValid ? props.icon : SVG_FALLBACK_ICON}`;
        });
        return () => (
            <MyIcon
                class="cl-icon-svg"
                type={validIcon.value}
                style={{
                    fontSize: props.size ? `${props.size}px` : "inherit",
                    color: props.color,
                }}
                {...attrs}
            />
        );
    },
});
