/*
 * @Author: 蒋文斌
 * @Date: 2021-02-22 16:50:40
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-04-26 14:22:46
 * @Description: 事件总线
 */

import { EventEmitter2 } from "eventemitter2";

// 事件总线
export const eventBus = new EventEmitter2();
