/*
 * @Author: 蒋文斌
 * @Date: 2021-05-18 10:22:55
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-21 21:59:55
 * @Description: pro table utils
 */

import { ProTableColumn } from "@/bean/pro";

export const generateColumnKey = <T extends { dataIndex?: string } = ProTableColumn>(column: T, index: number): string => {
    return column.dataIndex ? column.dataIndex : index.toString(16);
};
