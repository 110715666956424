/*
 * @Author: 蒋文斌
 * @Date: 2020-12-22 11:17:27
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-20 16:40:22
 * @Description: 404路由
 */

import { RouteRecordRaw } from "vue-router";

export const NOT_FOUND_ROUTE: RouteRecordRaw = {
    name: "NotFound",
    path: "/404",
    component: () => import(/* webpackChunkName: "not-found" */ "@/views/404/index.vue"),
    meta: {
        // 无限制路由标志
        noAuth: true,
        name: "404 Not Found",
        excludeInTab: true,
        layout: "ClBlankLayout",
    },
};

export const FALLBACK_ROUTE = {
    name: "Fallback",
    path: "/:pathMatch(.*)*",
    redirect: "/404",
};
