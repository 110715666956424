/*
 * @Author: 蒋文斌
 * @Date: 2021-04-25 11:46:53
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-12 09:02:48
 * @Description: Tree Helper
 */

import { TreeNode } from "@/bean/base";
import { last } from "lodash-es";

// overload
export function tree2Arr<T extends TreeNode>(tree: Array<T>, replaceChildren?: string): Array<T>;
export function tree2Arr<T extends TreeNode, D = unknown>(
    tree: Array<T>,
    replaceChildren?: string,
    mapper?: (item: T, index: number, arr: Array<T>) => D
): Array<D>;
export function tree2Arr<T extends TreeNode, D = unknown>(
    tree: Array<T>,
    replaceChildren = "children",
    mapper?: (item: T, index: number, arr: Array<T>) => D
): Array<T> | Array<D> {
    const result = tree.reduce((prev, curr) => {
        const children = curr[replaceChildren] as T[];
        const list = children && children.length > 0 ? [curr, ...tree2Arr(children, replaceChildren, mapper)] : [curr];
        return prev.concat(list as ConcatArray<T>);
    }, [] as Array<T>);
    return typeof mapper === "function" ? result.map(mapper) : result;
}

interface FieldsMapper {
    id: string;
    children: string;
}

/**
 * 根据目标key，递归树获取到目标节点对象
 */
export function getTreeNodeByKey<T extends TreeNode>(
    tree: Array<T>,
    key: number,
    fieldsMapper: FieldsMapper = { id: "id", children: "children" }
): T | null {
    let result: T | null = null;
    function deepSearch(tree: Array<T>) {
        if (result) {
            return;
        }
        const mappedTree: Array<T> = tree.map((item) => {
            return {
                ...item,
                id: item[fieldsMapper.id],
                children: item[fieldsMapper.children],
            } as T;
        });
        for (let index = 0; index < mappedTree.length; index++) {
            const element = mappedTree[index];
            if (element.id === key) {
                result = element;
                break;
            } else if (element.children && element.children.length > 0) {
                deepSearch(element.children);
            }
        }
    }
    deepSearch(tree);
    return result;
}

/**
 * 根据自定义条件 condition，递归树获取到目标节点对象
 */
export function getTreeNodeByCondition<T extends TreeNode>(
    tree: T[],
    condition: (node: T) => boolean,
    fieldsMapper: FieldsMapper = { id: "id", children: "children" }
): T | null {
    let result: T | null = null;
    function deepSearch(tree: Array<T>) {
        if (result) {
            return;
        }
        const mappedTree: Array<T> = tree.map((item) => {
            return {
                ...item,
                id: item[fieldsMapper.id],
                children: item[fieldsMapper.children],
            } as T;
        });
        for (let index = 0; index < mappedTree.length; index++) {
            const element = mappedTree[index];
            if (condition(element)) {
                result = element;
                break;
            } else if (element.children && element.children.length > 0) {
                deepSearch(element.children);
            }
        }
    }
    deepSearch(tree);
    return result;
}

interface EnhancedFieldsMapper extends FieldsMapper {
    parentId: string;
}

/**
 * 根据parentId获取祖先节点，得到的是反序的节点数组
 */
export function getAncestorsByParentId<T extends TreeNode>(
    tree: Array<T>,
    parentKey: number,
    fieldsMapper: EnhancedFieldsMapper = { id: "id", children: "children", parentId: "parentId" }
): Array<T> {
    const results: Array<T> = [];
    /**
     * 根据parentId向上查找
     */
    function searchUpTree(tree: Array<T>, parentKey: number) {
        if (parentKey) {
            const parentNode = getTreeNodeByKey<T>(tree, parentKey, fieldsMapper);
            if (parentNode) {
                results.push(parentNode);
                searchUpTree(tree, parentNode[fieldsMapper.parentId] as number);
            }
        }
    }
    searchUpTree(tree, parentKey);
    return results;
}

/**
 * 根据 node 的 parentId 获取顶级节点
 */
export function getTopAncestor<T extends TreeNode>(
    tree: Array<T>,
    node: T,
    fieldsMapper: EnhancedFieldsMapper = { id: "id", children: "children", parentId: "parentId" }
): T | undefined {
    const ancestors = getAncestorsByParentId(tree, node[fieldsMapper.parentId] as number, fieldsMapper);
    return last(ancestors);
}

/**
 * 根据树的深度得到一个节点key组成的数组
 */
export function getKeysByTreeDepth<T extends TreeNode>(
    tree: T[],
    targetDepth = 1,
    currentDepth = 0,
    results: number[] = [],
    fieldsMapper: FieldsMapper = { id: "id", children: "children" }
): number[] {
    tree.forEach((element) => {
        if (currentDepth < targetDepth) {
            results.push(element[fieldsMapper.id] as number);
            if (element[fieldsMapper.children] && (element[fieldsMapper.children] as T[]).length > 0) {
                getKeysByTreeDepth(element[fieldsMapper.children] as T[], targetDepth, currentDepth + 1, results);
            }
        }
    });
    return results;
}

/**
 * 根据树的深度得到一个节点key组成的数组
 */
export function getAncenstorLabels<T extends TreeNode>(tree: T[], data: T, labelKey = "label"): string[] {
    if (data.parentId) {
        const ancenstors = getAncestorsByParentId(tree, data.parentId);
        return ancenstors.map((item) => item[labelKey] as string);
    } else {
        return [];
    }
}

/**
 * 获取所有节点及其后代节点的key
 * @param {Array} nodes 所有节点
 * @param {Array} results key结果集
 * @returns {Array} key结果集
 */
export function getAllKeysByNodes(
    nodes: TreeNode[],
    results: number[] = [],
    fieldsMapper: FieldsMapper = { id: "id", children: "children" }
): number[] {
    nodes.forEach((item) => {
        results.push(item[fieldsMapper.id] as number);
        if (item[fieldsMapper.children] && (item[fieldsMapper.children] as TreeNode[]).length > 0) {
            getAllKeysByNodes(item[fieldsMapper.children] as TreeNode[], results, fieldsMapper);
        }
    });
    return results;
}

/**
 * 根据指定的key获取所有后代节点（不包括自身）的key
 * @param {Array} tree 树
 * @param {number} key 指定的节点key
 * @returns {Array} key结果集
 */
export function getDescendantKeysByKey(
    tree: TreeNode[],
    key: number,
    fieldsMapper: FieldsMapper = { id: "id", children: "children" }
): number[] {
    const node = getTreeNodeByKey(tree, key, fieldsMapper);
    if (node && node[fieldsMapper.children] && (node[fieldsMapper.children] as TreeNode[]).length > 0) {
        return getAllKeysByNodes(node[fieldsMapper.children] as TreeNode[], [], fieldsMapper);
    } else {
        return [];
    }
}

export function getTreeDepth(tree: TreeNode[], fieldsMapper: FieldsMapper = { id: "id", children: "children" }) {
    if (tree.length === 0) {
        return 0;
    }
    let depth = 1;
    function deepQueryDepth(tree: TreeNode[]) {
        const hasChildren = tree.some((item) => (item[fieldsMapper.children] as TreeNode[]).length > 0);
        if (hasChildren) {
            depth++;
            const subTree = tree.reduce((prev, curr) => {
                return prev.concat(curr[fieldsMapper.children] as TreeNode[]);
            }, [] as TreeNode[]);
            deepQueryDepth(subTree);
        }
    }
    deepQueryDepth(tree);
    return depth;
}
