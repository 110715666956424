
import { defineComponent, ref, computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { debounce, last } from "lodash-es";
import { ReloadOutlined } from "@ant-design/icons-vue";
import { key } from "@/store";
import {
    AUTH_MODULE,
    DISPATCH_LOGOUT,
    EXECUTE_TAB_SHORTCUT,
    TABS_MODULE,
    REMOVE_ROUTE_TAB,
    CHANGE_COM,
    RELOAD_CURRENT_ROUTE_TAB,
} from "@/store/constants";
import { MY_ROUTE } from "@/router/common";
import { isDefined } from "@/utils/type";
import { DEFAULT_CUSTOMIZED_INFO } from "@/utils/const";
import { RemoveRouteTabPayload } from "@/store/modules/tabs";
import { ContextmenuShortCutDTO } from "@/bean/dto";
import { useAsyncLoading } from "@/hooks/async";
import { eventBus } from "@/utils/event-bus";
import { useTheme } from "@/hooks/theme";
import { useContextmenu } from "./contextmenu";
import { useBreadcrumbList } from "../helper";

interface MenuState {
    collapsed: boolean;
    openKeys: number[];
    preOpenKeys: number[];
}

export default defineComponent({
    name: "ClBaseLayout",
    components: {
        ReloadOutlined,
    },
    props: {
        useBreadcrumb: {
            type: Boolean,
            default: true,
        },
        // 从二级菜单开始展示（过滤掉一级菜单）
        useSubMenu: {
            type: Boolean,
            default: true,
        },
        // 使用此布局的根菜单的 url
        parentMenuUrl: {
            type: String,
            default: "/backend",
        },
    },
    setup(props) {
        // router
        const route = useRoute();
        const router = useRouter();
        // vuex
        const store = useStore(key);
        // refs
        const tabCompRef = ref();

        // 权限相关
        const flatMenus = computed(() => store.state.auth.flatMenus);
        const showMenus = computed(() => {
            if (props.useSubMenu) {
                const rootMenu = store.state.auth.userMenus.find((item) => item.path === props.parentMenuUrl);
                return (rootMenu && rootMenu.children) || [];
            } else {
                return store.state.auth.userMenus;
            }
        });
        // 徽章信息
        const badgeInfo = computed(() => store.state.auth.badgeInfo);
        // 模拟动态更新 badge 数据，实际上应该通过接口调取数据后再更新
        // setTimeout(() => {
        //     store.commit(`${AUTH_MODULE}/${SET_BADGE_INFO}`, {
        //         accountInfo: 10,
        //         customerCenter: 7,
        //         volunteer: 44,
        //     });
        // }, 3000);

        // 租户相关
        const customizedInfo = computed(() => store.state.customer.customizedInfo);
        // 用户相关
        const userAvatar = computed(() => store.getters[`${AUTH_MODULE}/userAvatar`]);
        const realName = computed(() => store.getters[`${AUTH_MODULE}/realName`]);
        const comId = computed(() => store.getters[`${AUTH_MODULE}/comId`]);
        const userComList = computed(() => store.getters[`${AUTH_MODULE}/userComList`]);
        // tab页相关
        const routeTabs = computed(() => store.state.tabs.routeTabs);
        const currentTabReloadKey = computed(() => store.getters[`${TABS_MODULE}/currentTabReloadKey`]);
        const visibleRouteTabs = computed(() => routeTabs.value.filter((item) => !item.hiddenInTab));
        const includeCompNames = computed(() => routeTabs.value.map((item) => item.compName).filter((item) => isDefined(item)));

        // 布局相关
        const xOptions = ref({
            containerClass: "base-layout__wrapper",
            asideClass: "base-layout__aside",
            mainClass: "padding-0",
        });
        const yOptions = ref({
            headerClass: "base-layout__right-header",
            mainClass: "padding-0",
        });

        // 面包屑
        const { breadcrumbList } = useBreadcrumbList();

        // 菜单相关
        const initOpenKeys = breadcrumbList.value.map((item) => item.id);
        // 菜单状态项
        const menuState = reactive<MenuState>({
            collapsed: false,
            openKeys: initOpenKeys,
            preOpenKeys: initOpenKeys,
        });
        // 监听openKeys的变化，记录preOpenKeys
        watch(
            () => menuState.openKeys,
            (val, oldVal) => {
                menuState.preOpenKeys = oldVal;
            }
        );
        // 路由的变化会引起面包屑更新，而openKeys恰好和面包屑对应
        watch(breadcrumbList, (val) => {
            menuState.openKeys = val.map((item) => item.id);
        });
        // 选中keys
        const selectedKeys = computed(() => {
            const menu = flatMenus.value.find((item) => item.path === decodeURIComponent(route.path));
            return menu ? [menu.id] : [];
        });
        // 手动收起/展开菜单
        const toggleMenu = () => {
            menuState.collapsed = !menuState.collapsed;
            menuState.openKeys = menuState.collapsed ? [] : menuState.preOpenKeys;
            eventBus.emit("toggle-menu", menuState.collapsed);
        };
        // 侧边收起和展开事件
        const onSiderCollapse = (collapsed: boolean) => {
            menuState.collapsed = collapsed;
            menuState.openKeys = menuState.collapsed ? [] : menuState.preOpenKeys;
            eventBus.emit("toggle-menu", menuState.collapsed);
        };
        // 点击菜单
        const onClickMenu = ({ key }: { key: number; keyPath: number[] }) => {
            const page = flatMenus.value.find((menu) => menu.id === key);
            if (page) {
                router.push(page.path);
            }
        };

        // 导航栏操作
        // logout action映射
        const handleLogout = () => store.dispatch(`${AUTH_MODULE}/${DISPATCH_LOGOUT}`);
        // 用户下拉操作
        const handleCommand = ({ key }: { key: string }) => {
            switch (key) {
                case "user":
                    router.push(MY_ROUTE.path);
                    break;
                case "logout":
                    handleLogout();
                    break;
                default:
                    break;
            }
        };
        // 切换租户
        const onComChange = (val: number) => store.dispatch(`${AUTH_MODULE}/${CHANGE_COM}`, val);

        // 涉及tab页操作
        const removeRouteTab = (payload: RemoveRouteTabPayload) => store.dispatch(`${TABS_MODULE}/${REMOVE_ROUTE_TAB}`, payload);
        // 点击tab页
        const onRouteTabClick = (fullPath: string) => {
            router.push(fullPath);
            closeContextmenu();
        };
        // 编辑tab页，主要是处理删除
        const onEdit = (targetKey: string | MouseEvent, action: "add" | "remove") => {
            if (action === "remove") {
                handleRouteTabRemove(targetKey as string);
                closeContextmenu();
            }
        };
        // 处理Tab删除
        const handleRouteTabRemove = (fullPath: string) => {
            const tab = routeTabs.value.find((item) => item.fullPath === fullPath);
            if (tab) {
                const { matchedPath } = tab;
                removeRouteTab({
                    matchedPath,
                });
            }
        };

        // 右键菜单交互
        const {
            isContextmenuVisible,
            isContextmenuDisabled,
            onTabsMousemove,
            onTabContextmenu,
            closeContextmenu,
            tabKey,
        } = useContextmenu();

        // Tab页右键快捷操作
        const handleTabShortcut = async ({ key }: { key: string }) => {
            await store.dispatch(`${TABS_MODULE}/${EXECUTE_TAB_SHORTCUT}`, { action: key, fullPath: tabKey.value });
            isContextmenuVisible.value = false;
        };

        const contextmenuShortcuts = ref<ContextmenuShortCutDTO[]>([
            { key: "closeother", name: "关闭其他" },
            { key: "closeleft", name: "关闭左侧" },
            { key: "closeright", name: "关闭右侧" },
            { key: "closeall", name: "关闭所有" },
        ]);

        // 当前 Tab Reload
        const handleActiveTabReload = async () => {
            if (tabCompRef.value.onTabReload) {
                // 如果提供了定制化的刷新接口，使用定制化刷新
                await tabCompRef.value.onTabReload();
            } else {
                // 否则全量刷新
                store.dispatch(`${TABS_MODULE}/${RELOAD_CURRENT_ROUTE_TAB}`);
                // 提供一个 1s loading的效果。
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(true);
                    }, 1000);
                });
            }
        };

        const { trigger: triggerTabReload, loading: isTabReloadLoading } = useAsyncLoading(handleActiveTabReload);

        // 返回上一页
        const goback = () => {
            const matchedPath = (last(route.matched) as { path: string }).path;
            removeRouteTab({
                matchedPath,
            });
        };

        // 自定义 title
        const layoutTitle = computed(() => tabCompRef.value?.layoutTitle);
        // custom vnodes
        const renderToolBtns = computed(() => tabCompRef.value?.renderToolBtns);
        const renderDescription = computed(() => tabCompRef.value?.renderDescription);

        const { theme, changeTheme } = useTheme();

        return {
            route,
            xOptions,
            yOptions,
            customizedInfo,
            menuState,
            selectedKeys,
            // logo fallback
            menuLogoError: `this.src='${DEFAULT_CUSTOMIZED_INFO.config.logoUrl}';this.onerror=null`,
            showMenus,
            badgeInfo,
            toggleMenu,
            onSiderCollapse,
            onClickMenu,
            breadcrumbList,
            userAvatar,
            realName,
            comId,
            userComList,
            handleCommand,
            onComChange,
            visibleRouteTabs,
            includeCompNames,
            onRouteTabClick,
            onEdit,
            goback,
            contextmenuShortcuts,
            isContextmenuVisible,
            isContextmenuDisabled,
            onTabsMousemove,
            onTabContextmenu,
            handleTabShortcut,
            tabCompRef,
            onActiveTabReload: debounce(triggerTabReload, 500, { leading: true }),
            isTabReloadLoading,
            layoutTitle,
            renderToolBtns,
            renderDescription,
            theme,
            changeTheme,
            currentTabReloadKey,
        };
    },
});
