import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-579df1a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "org-layout__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_cl_x_layout = _resolveComponent("cl-x-layout")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_cl_x_layout, {
      options: _ctx.layoutOptions,
      theme: "light"
    }, {
      aside: _withCtx(() => [
        _createVNode(_component_a_input_search, {
          value: _ctx.searchValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
          onInput: _ctx.onSearchInput,
          placeholder: "请输入关键字搜索"
        }, null, 8, ["value", "onInput"]),
        _createVNode(_component_a_tree, _mergeProps({
          class: "org-tree",
          "expanded-keys": _ctx.expandedKeys,
          "auto-expand-parent": _ctx.autoExpandParent,
          "tree-data": _ctx.treeData,
          "replace-fields": _ctx.replaceFields,
          "filter-tree-node": _ctx.filterTreeNode,
          "selected-keys": _ctx.selectedKeys,
          onExpand: _ctx.onExpand
        }, _ctx.$attrs, { onSelect: _ctx.onSelect }), null, 16, ["expanded-keys", "auto-expand-parent", "tree-data", "replace-fields", "filter-tree-node", "selected-keys", "onExpand", "onSelect"])
      ]),
      main: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["options"])
  ]))
}