import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b84f7d7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-icon__wrapper" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cl_icon_svg = _resolveComponent("cl-icon-svg")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            span: 4,
            key: icon
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "icon-cell",
                onClick: ($event: any) => (_ctx.onSelectIcon(icon))
              }, [
                _createVNode(_component_cl_icon_svg, { icon: icon }, null, 8, ["icon"])
              ], 8, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}