import { defineComponent, h, PropType, VNode } from "vue";

const props = {
    vnode: {
        type: Object as PropType<VNode>,
        default() {
            return null;
        },
    },
};

export default defineComponent({
    name: "ClRenderVnode",
    props,
    setup(props) {
        return () => {
            return props.vnode ? h(props.vnode) : null;
        };
    },
});
