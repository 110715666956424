/*
 * @Author: 蒋文斌
 * @Date: 2021-01-05 22:17:58
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-11 11:20:41
 * @Description: 组织机构
 */
import { ApiService } from "@/services/index";
import { admin } from "@/services/config";
import { PlainObject } from "@/bean/base";
import { QueryPageModel, ArrayResponse, RecordResponse } from "@/bean/xhr";
import { OrgDTO } from "@/bean/dto";

class OrganizationService extends ApiService {
    page(params: QueryPageModel) {
        return params.wd ? this.search(params) : this.$postJson("page", params);
    }

    search(params: QueryPageModel) {
        return this.$postJson("search", params);
    }

    delete(id: number) {
        return this.$del("delete", { id });
    }

    enable(id: number) {
        return this.$put("enable", { id });
    }

    disable(id: number) {
        return this.$put("disable", { id });
    }

    detail(id: number) {
        return this.$get<RecordResponse>("detail", { id });
    }

    update(params: PlainObject) {
        return this.$putJson("update", params);
    }

    find(params: PlainObject) {
        return this.$postJson<ArrayResponse>("find", params);
    }

    add(params: PlainObject) {
        return this.$postJson("add", params);
    }

    getOrgTree() {
        return this.$get<RecordResponse<OrgDTO>>("getOrgTree");
    }

    getAllOrgTree() {
        return this.$get<RecordResponse<OrgDTO>>("getAllOrgTree");
    }
}

export const organizationService = new OrganizationService(admin, "organization");
