/*
 * @Author: 蒋文斌
 * @Date: 2021-05-26 14:30:32
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-26 15:03:04
 * @Description: 免鉴权路由
 */

import { RouteRecordRaw } from "vue-router";

const NO_AUTH_TEST_ROUTE: RouteRecordRaw = {
    name: "NoAuthTest",
    path: "/no-auth-test",
    component: () => import(/* webpackChunkName: "no-auth-test" */ "@/views/404/index.vue"),
    meta: {
        noAuth: true,
        name: "门户网站免认证测试",
        excludeInTab: true,
        layout: "ClBlankLayout",
    },
};

export const LOGIN_ROUTE: RouteRecordRaw = {
    name: "Login",
    path: "/login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
    meta: {
        noAuth: true,
        name: "登录",
        excludeInTab: true,
        layout: "ClBlankLayout",
    },
};

export const PRIVACY_ROUTE: RouteRecordRaw = {
    name: "Privacy",
    path: "/privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "@/views/privacy/index.vue"),
    meta: {
        noAuth: true,
        name: "用户隐私协议",
        excludeInTab: true,
        layout: "ClBlankLayout",
    },
};

// 放在第一个位置的将成为门户网站的首页
export const NO_AUTH_ROUTES = [NO_AUTH_TEST_ROUTE, LOGIN_ROUTE, PRIVACY_ROUTE];
