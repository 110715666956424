/*
 * @Author: 蒋文斌
 * @Date: 2021-05-19 10:28:30
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-28 15:07:23
 * @Description: 计算DOM尺寸
 */

import { nextTick, onActivated, onMounted, onUnmounted, ref, Ref } from "vue";
import { eventBus } from "@/utils/event-bus";
import { GeneralFunction } from "@/bean/base";

interface UseCalcDomResponse {
    containerWidth: Ref<number>;
    trigger: GeneralFunction;
}

interface UseCalcProTableResponse extends UseCalcDomResponse {
    tableBodyHeight: Ref<number>;
    calcTableBodyHeight: GeneralFunction<void>;
}

export const useCalcDom = (
    getContainer: GeneralFunction<HTMLElement>,
    isAutoCalc = false,
    resizeCallback?: GeneralFunction
): UseCalcDomResponse => {
    // 容器宽度
    const containerWidth = ref(1920);
    // 计算容器宽度
    const calcContainerWidth = () => {
        const el = getContainer();
        const { width } = el.getBoundingClientRect();
        containerWidth.value = width;
    };
    onMounted(() => {
        setTimeout(() => {
            calcContainerWidth();
        }, 0);
    });
    if (isAutoCalc) {
        const resizeHandler = () => {
            calcContainerWidth();
            resizeCallback?.();
        };
        onActivated(() => {
            calcContainerWidth();
        });
        // 监听resize事件，调整布局
        eventBus.on("resize", resizeHandler);
        onUnmounted(() => {
            eventBus.off("resize", resizeHandler);
        });
    }

    return {
        containerWidth,
        trigger: () => {
            calcContainerWidth();
        },
    };
};

export const useCalcProTable = (getContainer: GeneralFunction<HTMLElement>, tableRef: Ref): UseCalcProTableResponse => {
    const { containerWidth, trigger } = useCalcDom(getContainer, true, () => {
        nextTick(() => {
            calcTableBodyHeight();
        });
    });

    const tableBodyHeight = ref(400);
    // 计算表格主体区域可用高度
    const calcTableBodyHeight = () => {
        const tableEle = tableRef.value.$el.querySelector(".ant-table");
        if (tableEle) {
            const tableHeaderEle = tableEle.querySelector(".ant-table-thead");
            const { height: tableHeight } = tableEle.getBoundingClientRect();
            const { height: tableHeaderHeight } = tableHeaderEle.getBoundingClientRect();
            tableBodyHeight.value = tableHeight - tableHeaderHeight - 20;
        }
    };

    return {
        containerWidth,
        trigger,
        tableBodyHeight,
        calcTableBodyHeight,
    };
};
