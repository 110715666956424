/*
 * @Author: 蒋文斌
 * @Date: 2021-05-26 14:51:30
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-26 15:02:11
 * @Description: 配置文件
 */

// 是否是门户网站（举例：官网，可以带登录和后台，也可以不带，都兼容）
export const isPortal = false;
