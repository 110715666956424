import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: _normalizeClass(["y-layout", _ctx.options.containerClass])
  }, {
    default: _withCtx(() => [
      (_ctx.$slots.header)
        ? (_openBlock(), _createBlock(_component_a_layout_header, {
            key: 0,
            class: _normalizeClass(_ctx.options.headerClass)
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (_ctx.$slots.main)
        ? (_openBlock(), _createBlock(_component_a_layout_content, {
            key: 1,
            class: _normalizeClass(_ctx.options.mainClass)
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "main", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createBlock(_component_a_layout_footer, {
            key: 2,
            class: _normalizeClass(_ctx.options.footerClass)
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}