/*
 * @Author: 蒋文斌
 * @Date: 2021-01-09 14:00:01
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-06-07 16:29:11
 * @Description: 用户偏好
 */

import storage from "@/utils/storage";
import { SET_PREFERENCE } from "@/store/constants";
import { TableSize } from "@/bean/pro";
import { Module } from "vuex";
import { RootState } from "..";

export type Theme = "dark" | "light";

interface Preference {
    density: TableSize;
    viewType: 1 | 2;
    defaultTheme: Theme;
    userTheme: Theme | "";
}

export interface PreferState {
    preference: Preference;
}

const DEFAULT_PREFERENCE: Preference = {
    // 密度
    density: "middle",
    // 视图模式，1代表详细模式，2代表简洁模式
    viewType: 1,
    // 默认主题
    defaultTheme: "light",
    // 用户所选主题
    userTheme: "",
};

let preference = DEFAULT_PREFERENCE;

const preferenceInStorage = storage.get("preference", true);

if (preferenceInStorage) {
    preference = {
        ...DEFAULT_PREFERENCE,
        ...JSON.parse(preferenceInStorage),
    };
}

export const prefer: Module<PreferState, RootState> = {
    namespaced: true,
    state: {
        preference,
    },
    getters: {
        /**
         * 优先判断租户是否允许切换主题，如果不允许，则以租户的默认主题为优先。
         * 其次判断用户是否自行选择了主题。
         * 如果用户选择了主题，以用户选择的为准。
         * 否则判断是否有租户默认主题，最后取 defaultTheme
         * @param state
         * @param getters
         * @param rootState
         */
        theme(state, getters, rootState) {
            const { enableSwitchTheme, tenantDefaultTheme } = rootState.customer.customizedInfo.config;
            const { userTheme, defaultTheme } = state.preference;
            if (enableSwitchTheme) {
                return userTheme || tenantDefaultTheme || defaultTheme;
            } else {
                return tenantDefaultTheme || defaultTheme;
            }
        },
    },
    mutations: {
        // 设置Tabs数据
        [SET_PREFERENCE](state: PreferState, payload?: Partial<Preference>): void {
            if (payload) {
                Object.assign(state.preference, payload);
                storage.set("preference", JSON.stringify(state.preference), true);
            } else {
                state.preference = DEFAULT_PREFERENCE;
                storage.remove("preference", true);
            }
        },
    },
};
