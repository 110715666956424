
import type { GeneralFunction } from "@/bean/base";
import type { RecordResponse } from "@/bean/xhr";
import type { OrgDTO } from "@/bean/dto";
import type { ReplaceFields } from "@/bean/antd";
import { defineComponent, PropType, ref } from "vue";
import { organizationService } from "@/services/admin/organization";
import { getKeysByTreeDepth, tree2Arr } from "@/utils/tree";
import { ORG_REPLACE_FIELDS } from "@/utils/replace-fields";

const props = {
    layoutOptions: {
        type: Object,
        default() {
            return {
                asideClass: "org-layout__aside",
                mainClass: "org-layout__main",
            };
        },
    },
    replaceFields: {
        type: Object as PropType<ReplaceFields>,
        default() {
            return ORG_REPLACE_FIELDS;
        },
    },
    orgApi: {
        type: Function as PropType<GeneralFunction<RecordResponse<OrgDTO>>>,
        default: organizationService.getOrgTree.bind(organizationService),
    },
};

export default defineComponent({
    name: "ClOrgLayout",
    props,
    emits: ["tree-loaded", "select"],
    setup(props, { emit }) {
        const expandedKeys = ref<number[]>([]);
        const autoExpandParent = ref<boolean>(true);
        const searchValue = ref<string>("");
        const treeData = ref<OrgDTO[]>([]);
        const flatTree = ref<OrgDTO[]>([]);
        const highlightColor = "var(--color-primary)";
        const selectedKeys = ref<number[]>([]);

        // 获取组织数据
        const getOrgTreeData = async () => {
            const { result } = await props.orgApi();
            treeData.value = [result];
            flatTree.value = tree2Arr<OrgDTO>(treeData.value, props.replaceFields.children);
            expandedKeys.value = getKeysByTreeDepth(treeData.value, 2);
            selectedKeys.value = [result.id];
            emit("tree-loaded", result);
        };
        getOrgTreeData();

        // 展开
        const onExpand = (keys: number[]) => {
            expandedKeys.value = keys;
            autoExpandParent.value = false;
        };

        // 选择
        const onSelect = (keys: number[], e: { node: { dataRef: OrgDTO } }) => {
            if (keys.length === 0) {
                // 反选的情况，根据业务要求，不能反选，这里直接重新设置
                keys = [e.node.dataRef.id];
            }
            selectedKeys.value = keys;
            emit("select", keys, e);
        };

        // 高亮
        const filterTreeNode = (node: { dataRef: OrgDTO }) => {
            const value = searchValue.value;
            const data = node.dataRef;
            const title = data[props.replaceFields.title as string] as string;
            if (!value) return false;
            if (title.indexOf(value) !== -1) {
                return true;
            }
        };

        // 过滤
        const onSearchInput = (e: UIEvent) => {
            const { value } = e.target as HTMLInputElement;
            if (!value) {
                expandedKeys.value = [];
                autoExpandParent.value = false;
            } else {
                const matchNodes = flatTree.value.filter(
                    (node) => (node[props.replaceFields.title as string] as string).indexOf(value) !== -1
                );
                expandedKeys.value = matchNodes.map((item) => item.id);
                autoExpandParent.value = true;
            }
        };

        // 刷新
        const refresh = async () => {
            if (!props.orgApi) {
                return;
            }
            return getOrgTreeData();
        };

        return {
            expandedKeys,
            autoExpandParent,
            searchValue,
            treeData,
            highlightColor,
            onExpand,
            filterTreeNode,
            onSearchInput,
            flatTree,
            refresh,
            selectedKeys,
            onSelect,
        };
    },
});
