/**
 * @file 所有的常量在这里维护，常量名全部采用大写，可用_分隔单词.
 * @author Robin Jang
 */

import logo from "@/assets/img/logo.png";
import systemPicture from "@/assets/img/system_picture.png";
import { PlainObject } from "@/bean/base";
import { CustomizedInfoDTO } from "@/bean/dto";

export const DEFAULT_CUSTOMIZED_INFO: CustomizedInfoDTO = {
    logo,
    systemPicture,
    platformChineseName: "垃圾分类系统",
    platformEnglishName: "QiaoYin Environment Cloud Platform",
    comName: "侨银环保有限公司",
    shortName: "侨银垃圾分类",
    comIntro: "侨银环保，人居环境服务专家！",
    sysMiiRegisteredCode: "湘ICP备10003152号-2",
    // 额外的扩展配置
    config: {
        defaultLayout: "ClBaseLayout",
        loginLogoUrl: "/static/img/login_logo.png",
        logoUrl: "/static/img/logo.png",
        whiteTextLogoUrl: "/static/img/white_logo_with_text.png",
        copyRightStartYear: "2017",
    },
};

export const REQUIRED_VALIDATOR_BLUR = {
    required: true,
    message: "必填项",
    trigger: "blur",
};

export const REQUIRED_VALIDATOR_BLUR_FOR_NUMBER = {
    required: true,
    message: "必填项",
    trigger: "blur",
    type: "number",
};

export const REQUIRED_VALIDATOR_CHANGE = {
    required: true,
    message: "必填项",
    trigger: "change",
};

export const REQUIRED_VALIDATOR_CHANGE_FOR_NUMBER = {
    required: true,
    message: "必填项",
    trigger: "change",
    type: "number",
};

export const REQUIRED_VALIDATOR_CHANGE_FOR_ARRAY = {
    required: true,
    message: "必填项",
    trigger: "change",
    type: "array",
};

export const INTERGER_VALIDATOR = {
    pattern: /^\d*$/,
    message: "请输入整数",
    trigger: "blur",
};
export const LOOSE_PASSWORD_VALIDATOR = {
    required: true,
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,30}$/,
    message: "密码必须包含大小写字母、数字，长度不少于8位",
    trigger: "change",
};

export const FLOAT_VALIDATOR = {
    pattern: /^(\d+(\.\d+)?)?$/,
    message: "请输入正确的数值",
    trigger: "blur",
};

export const PLATE_NUMBER_VALIDATOR = {
    pattern: /^(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-HJ-NP-Z]{1}(?:(?:[0-9]{5}[DF])|(?:[DF](?:[A-HJ-NP-Z0-9])[0-9]{4})))$|(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9 挂学警港澳测试]{1})$/,
    message: "请输入正确的车牌号",
    trigger: "blur",
};

export const DOMAIN_VALIDATOR = {
    pattern: /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/,
    message: "请输入合法域名",
    trigger: "blur",
};

export const IDCARD_VALIDATOR = {
    pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
    message: "请输入格式正确的身份证号码",
    trigger: "blur",
};

export const CHINESENAME_VALIDATOR = {
    pattern: /^(?:[\u4e00-\u9fa5·]{2,16})$/,
    message: "请输入合法的中文姓名",
    trigger: "blur",
};

export const STRICT_TELEPHONE_VALIDATOR = {
    pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
    message: "请输入正确的手机号",
    trigger: "blur",
};

export const LOOSE_TELEPHONE_VALIDATOR = {
    pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
    message: "请输入正确的手机号",
    trigger: "blur",
};

export const EMAIL_VALIDATOR = {
    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "请输入正确的邮箱",
    trigger: "blur",
};

export const PASS_RATE_VALIDATOR = {
    pattern: /^100$|^(\d|[1-9]\d)(\.\d{1,4})*$/,
    required: true,
    message: "必填项(1-100)",
    trigger: "blur",
};

export const DEFAULT_CENTER_POSITION = {
    lng: 112.903626,
    lat: 28.12317,
};

export const ZOOM_LEVEL = {
    M20: 19,
    M50: 18,
    M100: 17,
    M200: 16,
    M500: 15,
    KM1: 14,
    KM2: 13,
    KM5: 12,
    KM10: 11,
    KM20: 10,
    KM25: 9,
    KM50: 8,
    KM100: 7,
    KM200: 6,
    KM500: 5,
    KM1000: 4,
};

export const MAP_STYLE = {
    // 暗夜简约
    DARK_SIMPLE: "amap://styles/82b9487b028dc30b8d4e43b7a362b5da",
    // 暗夜详细
    DARK_DETAIL: "amap://styles/66520d7b1b770d58678118acc5f5966f",
    // 明亮简约
    LIGHT_SIMPLE: "amap://styles/8ccb196fbd442ecccc9e819d40b8e9d0",
    // 明亮详细
    LIGHT_DETAIL: "amap://styles/4f96bb50a99892bc99bbf030914a7f6c",
};

export const BREAK_POINTS = {
    xs: 768,
    sm: 992,
    md: 1200,
    lg: 1920,
};

export const AUTH_TYPES = {
    directory: "2",
    page: "3",
    button: "4",
};

export const PRIORITY_COLOR: PlainObject<string, string> = {
    HIGH: "#f5222d",
    MIDDLE: "#fa8c16",
    LOW: "#52c41a",
};
