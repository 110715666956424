/*
 * @Author: 蒋文斌
 * @Date: 2021-04-29 15:18:13
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-07-05 09:23:25
 * @Description: pro组件数据类型
 */

import { ValidationRule } from "ant-design-vue/lib/form/Form";
import { ColProps } from "ant-design-vue/lib/grid/Col";
import { TooltipProps } from "ant-design-vue/lib/tooltip";

import { GeneralFunction, PlainObject, PrimitiveType } from "./base";
import { RecordRowDTO } from "./dto";

// params是数据字典
export type ValueType =
    | "input"
    | "inputNumber"
    | "inputSearch"
    | "inputPassword"
    | "textarea"
    | "select"
    | "checkbox"
    | "checkboxGroup"
    | "radio"
    | "radioGroup"
    | "radioButtonGroup"
    | "date"
    | "daterange"
    | "time"
    | "switch"
    | "params"
    | "treeSelect"
    | "upload"
    | "proSearchInput";

type ColumnType = "params" | "translate" | "actions" | "text" | "link" | "image";

type DescriptionType = "params" | "translate" | "text" | "image";

interface LoadingTriggerMap {
    [k: string]: GeneralFunction<Promise<unknown>>;
}

type RowActionType = "prompt" | "default";

type PropsForPrompt = {
    title?: string;
    onConfirm?: GeneralFunction;
};

type GetActionLabel<T> = (row: T) => string;

type VisibleControl<T> = (row: T) => boolean;

type GetRowActionProp<T> = (row: T) => PlainObject;

interface RowAction<T> {
    label: string | GetActionLabel<T>;
    type?: RowActionType;
    // 对应Popconfirm的属性
    propsForPrompt?: PropsForPrompt;
    // button props
    props?: PlainObject | GetRowActionProp<T>;
    // loading拦截器
    loadingInterceptor?: LoadingTriggerMap;
    // 是否可见，等价于v-if控制
    visible?: boolean | VisibleControl<T>;
}

export interface CustomRenderFields<T extends RecordRowDTO = RecordRowDTO> {
    text: unknown;
    record: T;
    // 行索引
    index: number;
    // 列索引，适用于 ProTable
    colIndex?: number;
}

type GetRecordLink<T = RecordRowDTO> = (record: T) => string;

export type CustomRender<T extends RecordRowDTO = RecordRowDTO> = (p: CustomRenderFields<T>) => JSX.Element | PrimitiveType | PlainObject;

export interface ProTableColumn<T extends RecordRowDTO = RecordRowDTO> {
    // 标题
    title?: string;
    // 数据索引名称
    dataIndex?: string;
    // 唯一键值
    key?: string;
    // 表单组件类型
    valueType?: ValueType;
    // 列类型
    columnType?: ColumnType;
    // 详情字段类型
    descriptionType?: DescriptionType;
    // 操作列表
    actions?: RowAction<T>[];
    // 对应的参数Code
    paramCode?: string;
    // 是否使用完整code，防止出现重复code
    useFullCode?: boolean;
    // 完整code
    fullCode?: string;
    // 是否在查询表单中隐藏
    hideInSearch?: boolean;
    // 是否在查询表格中隐藏
    hideInTable?: boolean;
    // 是否在详情中隐藏
    hideInDescriptions?: boolean;
    // 表单项默认值
    defaultValue?: unknown;
    // 表格列宽度
    width?: string | number;
    // 支持数据转换
    transform?: (fields: CustomRenderFields) => unknown;
    // Table Column 自定义渲染函数
    customRender?: CustomRender<T>;
    // 详情字段自定义渲染函数
    customDescriptionRender?: (text: PrimitiveType, record: T) => JSX.Element | PrimitiveType;
    // 针对范围类型的日期时间类组件，最终传递到后端是两个参数
    transformPropsForRange?: [string, string];
    // 列是否固定，可选 true(等效于 left) 'left' 'right'
    fixed?: boolean | "left" | "right";
    // 对齐方式
    align?: "left" | "right" | "center";
    // 是否可复制，不兼容 customRender
    copyable?: boolean;
    // 链接
    link?: string | GetRecordLink<T>;
    // 排序，越大越靠前，不传视为0
    order?: number;
    // 是否可见，区别于 hidenInTable，可以作为筛选依据
    visible?: boolean;
    // 是否支持省略显示...
    ellipsis?: boolean;
    // 表头分组
    children?: ProTableColumn<T>[];
    // 列 span
    colSpan?: number;
    // 自定义渲染表单的级别，默认是渲染表单项内部的表单组件，可提升为渲染整个FormItem
    renderFormItemLevel?: RenderFormItemLevel;
    // Form Item 内容区域自定义渲染函数
    customRenderFormItem?: (formModel: Partial<T>, formItem: ProFormItem) => JSX.Element;
    // Form Item 前部区域
    beforeFormItem?: (formModel: Partial<T>, formItem: ProFormItem) => JSX.Element;
    // Form Item 后部区域
    afterFormItem?: (formModel: Partial<T>, formItem: ProFormItem) => JSX.Element;
    // 校验规则
    rule?: PlainObject[];
    // 组件属性
    props?: PlainObject | GeneralFunction<PlainObject>;
    // 下拉字段映射
    selectOption?: SelectOption;
    // 下拉组件的数据源
    selectDataSource?: PlainObject[];
    // option props
    propsForOption?: PlainObject;
    // form item props
    formItemProps?: PlainObject;
    // form col props，这里不用 ColProps 类型是为了防止 useTableColumn报错
    colProps?: PlainObject;
    // switch 增强配置
    switchOption?: {
        activeValue?: string | number | boolean;
        inactiveValue?: string | number | boolean;
    };
    optionProps?: GeneralFunction;
}

export interface SearchConf {
    // label宽度
    labelWidth?: string;
    // 是否隐藏搜索等按钮
    hideButtons?: boolean;
}

export type TableSize = "default" | "middle" | "small";

export interface TableDensityItem {
    title: string;
    value: TableSize;
}

export interface ToolbarItem {
    tooltip: TooltipProps;
}

export type ToolbarKey = "reload" | "density" | "column" | "fullscreen";

export interface ToolbarConfig {
    position: "left" | "right";
}

type GeneralToolbarOption = {
    config?: ToolbarConfig;
};

export type ToolbarOption = GeneralToolbarOption &
    {
        [key in ToolbarKey]: false | ToolbarItem;
    };

export interface ProTableOptions {
    containerClass?: string;
    headerClass?: string;
    mainClass?: string;
    prependClass?: string;
    toolbarClass?: string;
    toolbarBuiltInClass?: string;
}

export type SettingColumn = Pick<ProTableColumn, "title" | "fixed" | "key" | "visible">;

export interface SettingColumnGroup {
    position: "left" | "middle" | "right";
    title: string;
    list: SettingColumn[];
}

type GetSelectLabel = (option: RecordRowDTO) => string;

export interface BaseSelectOption {
    key?: string;
    label?: string;
}

export interface SelectOption {
    key?: string;
    value?: string;
    label?: string | GetSelectLabel;
}

export type RenderFormItemLevel = "formItem" | "widget";

export const ProFormItemKeys = [
    "title",
    "key",
    "valueType",
    "paramCode",
    "useFullCode",
    "fullCode",
    "defaultValue",
    "renderFormItemLevel",
    "customRenderFormItem",
    "beforeFormItem",
    "afterFormItem",
    "transformPropsForRange",
    "order",
    "rule",
    "props",
    "selectOption",
    "selectDataSource",
    "propsForOption",
    "dataIndex",
    "rule",
    "formItemProps",
    "colProps",
    "switchOption",
    "optionProps",
] as const;

type ProFormItemKeys = typeof ProFormItemKeys[number];

export type ProFormItem<T extends RecordRowDTO = RecordRowDTO> = Pick<ProTableColumn<T>, ProFormItemKeys> & {
    // 数据索引名称
    dataIndex: string;
    // rule 和 colProps 直接在 ProTableColumn 指定类型，使用时报错 Type instantiation is excessively deep and possibly infinite.
    rule?: ValidationRule[];
    // form col props
    colProps?: ColProps;
    // 是否可见，是直接用 v-if 控制的。formItemVisible的目的是保证外部传入 ProFormItem[] 的写法简洁，而不是通过逻辑判断组合出 ProFormItem[]
    formItemVisible?: boolean | GeneralFunction<boolean>;
};

export interface ProFormOptions {
    formClass?: string;
    btnsWrapperClass?: string;
}

export interface ProSearchInputChangeData {
    text: string;
    rows: RecordRowDTO[];
    selectOption: {
        key: string;
        label: string;
    };
}
