/*
 * @Author: 蒋文斌
 * @Date: 2021-05-20 09:37:23
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-24 17:31:22
 * @Description: pro table 的一些配置
 */

import { TableDensityItem, ToolbarOption } from "@/bean/pro";

export const DENSITY_LIST: TableDensityItem[] = [
    {
        title: "默认",
        value: "default",
    },
    {
        title: "中等",
        value: "middle",
    },
    {
        title: "紧凑",
        value: "small",
    },
];

export const DEFAULT_TOOLBAR: ToolbarOption = {
    reload: {
        tooltip: {
            placement: "top",
            title: "刷新",
        },
    },
    density: {
        tooltip: {
            placement: "top",
            title: "密度",
        },
    },
    column: {
        tooltip: {
            placement: "top",
            title: "列设置",
        },
    },
    fullscreen: {
        tooltip: {
            placement: "top",
        },
    },
    config: {
        position: "right",
    },
};
