/*
 * @Author: 蒋文斌
 * @Date: 2021-03-16 11:30:46
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-24 22:03:36
 * @Description: 布局模板
 */

import { RenderOptions } from "@/bean/base";

interface LayoutTemplate {
    code: string;
    name: string;
    option: RenderOptions;
}

export const LAYOUT_TEMPLATES: Array<LayoutTemplate> = [
    {
        code: "new_arch_backend",
        name: "新框架通用后台（左右结构）",
        option: {
            tag: "ClBaseLayout",
        },
    },
    {
        code: "new_arch_frontend",
        name: "新框架通用前台（上下结构）",
        option: {
            tag: "ClNavbarLayout",
        },
    },
    {
        code: "coollu_cloud_backend",
        name: "酷陆云通用后台（上下+左右结构）",
        option: {
            tag: "ClNavbarLayout",
            props: {
                useSlot: true,
            },
            slots: {
                default: (h, r) => [h(r("ClBackendLayout"))],
            },
        },
    },
    {
        code: "coollu_cloud_map",
        name: "酷陆云地图布局",
        option: {
            tag: "ClNavbarLayout",
            props: {
                useSlot: true,
            },
            slots: {
                default: (h, r) => [h(r("ClMapLayout"))],
            },
        },
    },
    {
        code: "blank",
        name: "空白布局（适合独立页面）",
        option: {
            tag: "ClBlankLayout",
        },
    },
];
