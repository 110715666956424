
import { defineComponent } from "vue";

export default defineComponent({
    name: "ClIconFont",
    props: {
        icon: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
        },
        color: {
            type: String,
        },
    },
    data() {
        return {
            iconPrefix: "cl-icon-",
        };
    },
});
