/*
 * @Author: 蒋文斌
 * @Date: 2021-04-15 16:56:01
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-07-05 10:53:49
 * @Description: 路由入口
 */
import { createRouter, createWebHistory, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { last } from "lodash-es";
import { message } from "ant-design-vue";
import store from "@/store";
import {
    AUTH_MODULE,
    CUSTOMER_MODULE,
    GET_CURRENT_USER,
    GET_USER_MENUS,
    TABS_MODULE,
    UPDATE_ROUTE_TAB,
    REMOVE_ALL_ROUTE_TABS_BY_MATCH,
    ADD_ROUTE_TAB,
    SET_ROUTE_TABS,
    SET_ACCESS_INFO,
    SET_USER_INFO,
    SET_USER_MENUS,
    SET_BTN_AUTH_PATHS,
    FETCH_CUSTOMIZED_INFO,
    SET_CURRENT_TAB,
    SET_BADGE_INFO,
    SET_VERIFY_KEY,
} from "@/store/constants";
import { findRootRedirectRoute, generateRoutesByPages } from "@/permissions/generate-routes";
import { isNumber, isObject } from "@/utils/type";
import { InnerCode } from "@/services";
import { userService } from "@/services/admin/user";
import { AUTH_TYPES } from "@/utils/const";
import { cancelSourceMap } from "@/services/cancel";
import { isPortal } from "./config";
import { NO_AUTH_ROUTES, LOGIN_ROUTE } from "./no-auth";
import { COMMON_ROUTES } from "./common";
import { NOT_FOUND_ROUTE, FALLBACK_ROUTE } from "./404";

const DEFAULT_REDIRECT_NAME = Symbol("default route name");

const removeDefaultRoute = () => {
    if (router.hasRoute(DEFAULT_REDIRECT_NAME)) {
        router.removeRoute(DEFAULT_REDIRECT_NAME);
    }
};

const removeDynamicRoutes = () => {
    router.getRoutes().forEach((route) => {
        if (!route.meta.noAuth) {
            // 业务处保障路由name一定存在
            router.removeRoute(route.name as string);
        }
    });
};

const createInitRouter = () => {
    const _routes = [NOT_FOUND_ROUTE, ...NO_AUTH_ROUTES];
    if (isPortal) {
        const defaultRedirectRoute = {
            name: DEFAULT_REDIRECT_NAME,
            path: "/",
            redirect: NO_AUTH_ROUTES[0].path,
        };
        _routes.push(defaultRedirectRoute);
    }
    return createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: _routes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { top: 0 };
            }
        },
    });
};

const router = createInitRouter();

initRouter();

const clearAuthInfo = () => {
    store.commit(`${AUTH_MODULE}/${SET_ACCESS_INFO}`, null);
    store.commit(`${AUTH_MODULE}/${SET_VERIFY_KEY}`, null);
    // 登录页不清理租户信息，否则会出现logo等信息闪烁问题
    store.commit(`${TABS_MODULE}/${SET_ROUTE_TABS}`, null);
    store.commit(`${AUTH_MODULE}/${SET_USER_INFO}`, null);
    store.commit(`${AUTH_MODULE}/${SET_USER_MENUS}`, null);
    store.commit(`${AUTH_MODULE}/${SET_BTN_AUTH_PATHS}`, null);
    store.commit(`${AUTH_MODULE}/${SET_BADGE_INFO}`, null);
};

router.beforeEach((to, from, next) => {
    window._isReload = to.fullPath === from.fullPath;
    const cancelSource = cancelSourceMap.get(from.path);
    if (cancelSource) {
        // 取消即将离开的页面发出的请求
        cancelSource.cancel("request has been canceled");
    }
    // 基本校验
    if (to.path === LOGIN_ROUTE.path) {
        const hasAccessInfo = !!store.state.auth.accessInfo;
        clearAuthInfo();
        next();
        if (hasAccessInfo) {
            // 重置路由
            initRouter();
        }
    } else if (to.meta.noAuth) {
        next();
    } else if (!store.state.auth.accessInfo) {
        next(LOGIN_ROUTE.path);
    } else {
        next();
    }
});

async function getCurrentPageBtnAuth() {
    try {
        const res = await userService.getUserAuth({
            authId: router.currentRoute.value.meta.authId,
            authTypes: [AUTH_TYPES.button],
        });
        const authPaths = res.result.map((item) => item.path).filter((item) => item !== "");
        store.commit(`${AUTH_MODULE}/${SET_BTN_AUTH_PATHS}`, authPaths);
    } catch (error) {
        resetCurrentPageBtnAuth();
    }
}

function resetCurrentPageBtnAuth() {
    store.commit(`${AUTH_MODULE}/${SET_BTN_AUTH_PATHS}`, null);
}

router.afterEach((to, from) => {
    const { path, fullPath, matched, meta, params } = to;
    const lastMatchedOne = last(matched);
    if (!lastMatchedOne) {
        return;
    }
    const matchedPath = lastMatchedOne.path;
    // 这里用path做匹配
    const index = store.state.tabs.routeTabs.findIndex((item) => item.path === path);
    const currentTab = store.state.tabs.routeTabs[index];
    // 如果excludeInTab为false，才处理tab
    if (!meta.excludeInTab) {
        if (index === -1) {
            // 如果path匹配不到，则是一个新的Tab页
            const payload = {
                matchedPath, // 匹配的路由
                path, // 当前路径
                fullPath, // 完整路径
                name: params.tabName || meta.name, // 中文name
                compName: meta.compName,
                uncloseable: meta.uncloseable || false,
                parentRoutePath: meta.isSubPage ? from.fullPath : null,
                hiddenInTab: meta.hiddenInTab, // 是否是隐藏的Tab
            };
            if (meta.isSubPage) {
                // 配置了meta.isSubPage，是广义上的详情页

                // 首先看看tabs中有没有一个可见的并且matchedPath相同的
                const visibleSameCompTabIndex = store.state.tabs.routeTabs.findIndex(
                    (item) => !item.hiddenInTab && item.matchedPath === matchedPath
                );
                if (visibleSameCompTabIndex !== -1) {
                    // 如果有
                    if (to.name === from.name) {
                        // 并且name相同，考虑是详情页一直下钻的情况，先把上级Tab隐藏起来
                        const visibleSameCompTab = store.state.tabs.routeTabs[visibleSameCompTabIndex];
                        visibleSameCompTab.hiddenInTab = true;
                        store.dispatch(`${TABS_MODULE}/${UPDATE_ROUTE_TAB}`, visibleSameCompTab);
                    } else {
                        // 不是详情页下钻，说明是从其他路由过来的，先清空同matchedPath的所有Tab，防止栈冗余
                        store.dispatch(`${TABS_MODULE}/${REMOVE_ALL_ROUTE_TABS_BY_MATCH}`, { matchedPath });
                    }
                    // mock nextTick
                    setTimeout(() => {
                        // 统一在nextTick新增Tab
                        store.dispatch(`${TABS_MODULE}/${ADD_ROUTE_TAB}`, payload);
                    }, 0);
                    // TODO: 全部Tab都展示
                    // store.dispatch(`${TABS_MODULE}/${ADD_ROUTE_TAB}`, payload);
                } else {
                    // 没有找到matchedPath相同的可见tab，直接新增一个tab
                    store.dispatch(`${TABS_MODULE}/${ADD_ROUTE_TAB}`, payload);
                }
            } else {
                // 不是详情页，直接加Tab
                store.dispatch(`${TABS_MODULE}/${ADD_ROUTE_TAB}`, payload);
            }
        } else if (currentTab.hiddenInTab) {
            // path匹配到了，说明是旧的Tab页，但是隐藏了
            // 现在直接把hiddenInTab设置为true，并且把可能存在的子孙节点全部清理掉。（主要是防止Tabs冗余）
            currentTab.hiddenInTab = false;
            // 用一个item.path === path是为了把自己包含进来。暂时把同compName的清理掉，后面再优化
            const newTabs = store.state.tabs.routeTabs.filter((item) => item.compName !== meta.compName || item.path === path);
            store.commit(`${TABS_MODULE}/${SET_ROUTE_TABS}`, newTabs);
        } else {
            // 旧的Tab页，没有隐藏
            // 场景一：在一个Tab页中修改了路由参数，这个时候需要更新Tab中的fullPath，否则会有切换上的bug
            currentTab.fullPath = fullPath;
            store.dispatch(`${TABS_MODULE}/${UPDATE_ROUTE_TAB}`, currentTab);
        }
    }

    // 兼容详情页下钻的情况
    setTimeout(() => {
        // 处理完tab后，重新计算currentTab，并更新state
        const tab = store.state.tabs.routeTabs.find((item) => item.path === path);
        store.commit(`${TABS_MODULE}/${SET_CURRENT_TAB}`, tab);
    }, 0);

    // 更新网页title
    document.title = `${meta.name}|${store.state.customer.customizedInfo.platformChineseName}`;
    // 处理按钮权限
    if (router.currentRoute.value.meta.enableBtnAuth) {
        // 设置 enableBtnAuth 开关，防止不必要的接口频繁调用
        getCurrentPageBtnAuth();
    } else {
        resetCurrentPageBtnAuth();
    }
});

router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    if (isChunkLoadFailed) {
        location.reload(true);
    }
});

const resolveRoute = (route: RouteLocationRaw, defaultRoute: RouteLocationRaw) => {
    const routeLocation = router.resolve(route);
    const { matched } = routeLocation;
    if (matched.length > 0) {
        const routes = router.getRoutes();
        const { isDynamic } = routeLocation.meta;
        const matchedPath = isDynamic ? matched[0].path : routeLocation.path;
        if (routes.find((item) => item.path === matchedPath)) {
            // 解析出来的 routeLocation 必须包含在 routes 中，以适应删除动态路由重建的场景
            router.replace(route);
        } else {
            router.replace(defaultRoute);
        }
    } else {
        router.replace(defaultRoute);
    }
};

interface InitRouterParams {
    isChangeCom?: boolean;
    messageKey?: symbol;
    redirect?: string;
    isFromLogin?: boolean;
    excludePaths?: string[];
    hideMsg?: boolean;
    navigate?: boolean;
}

/**
 * 调用场景
 * 1. 登录后路由权限重新生成 -- DONE
 * 2. 切换租户路由权限重新生成 -- DONE
 * 3. 退出登录销毁路由 -- DONE
 * @param {InitRouterParams} initRouterParams
 */
export async function initRouter({
    isFromLogin = false,
    isChangeCom = false,
    messageKey = Symbol("key"),
    redirect = "",
    excludePaths = [],
    hideMsg = false,
    navigate = true,
}: InitRouterParams = {}): Promise<void> {
    removeDefaultRoute();
    removeDynamicRoutes();
    if (store.state.auth.accessInfo) {
        // 认证信息有效
        store.dispatch(`${AUTH_MODULE}/${GET_CURRENT_USER}`);
        message.loading({ content: "正在加载用户菜单，请稍候...", duration: 0, key: messageKey });
        try {
            // 获取用户菜单
            const tasks = [store.dispatch(`${AUTH_MODULE}/${GET_USER_MENUS}`)];
            if (!isChangeCom) {
                // 正常情况下，获取用户菜单和获取租户信息同时进行
                tasks.push(store.dispatch(`${CUSTOMER_MODULE}/${FETCH_CUSTOMIZED_INFO}`));
            }
            // 等待租户信息和用户信息都获取正常
            await Promise.all(tasks);
            const { userMenus } = store.state.auth;
            const flatPages = store.getters[`${AUTH_MODULE}/flatPages`];
            // 计算出 defaultAuthedRoutePath
            let defaultAuthedRoutePath = findRootRedirectRoute(userMenus, flatPages, excludePaths);
            if (!defaultAuthedRoutePath) {
                const includeCommonRoutes = COMMON_ROUTES.filter((item) => !excludePaths.includes(item.path));
                if (includeCommonRoutes.length > 0) {
                    defaultAuthedRoutePath = includeCommonRoutes[0].path;
                } else {
                    defaultAuthedRoutePath = NOT_FOUND_ROUTE.path;
                }
            }
            const defaultRedirectRoute: RouteRecordRaw = {
                name: DEFAULT_REDIRECT_NAME,
                path: "/",
                redirect: isPortal && !isFromLogin ? NO_AUTH_ROUTES[0] : defaultAuthedRoutePath,
            };
            // 生成动态路由
            const dynamicRoutes = generateRoutesByPages(flatPages, store.state.customer.customizedInfo, store.state.auth.flatMenus);
            // 添加动态路由
            [defaultRedirectRoute, ...COMMON_ROUTES, ...dynamicRoutes, FALLBACK_ROUTE].forEach((route) => {
                router.addRoute(route);
            });

            // 如果需要重新导航，才执行下面逻辑
            if (navigate) {
                // 解析路由
                if (isFromLogin) {
                    // redirect的目标不能是login或者404
                    if (redirect && ![LOGIN_ROUTE.path, NOT_FOUND_ROUTE.path].includes(redirect)) {
                        resolveRoute(redirect, defaultRedirectRoute);
                    } else {
                        router.replace(defaultAuthedRoutePath);
                    }
                } else if (isChangeCom) {
                    router.replace(defaultRedirectRoute);
                } else if (location.href.replace(location.origin, "") === NOT_FOUND_ROUTE.path) {
                    // 当前在404页面，尝试重定向到有权限的页面
                    router.replace("/");
                } else {
                    // 如果当前路由在路由表中，直接跳转；否则跳转到默认路由
                    resolveRoute(location.href.replace(location.origin, ""), defaultRedirectRoute);
                }
            }

            if (!hideMsg) {
                message.success({ content: "用户菜单加载成功！", duration: 1, key: messageKey });
            }
        } catch (err) {
            console.error(err);
            // 调接口失败，分为两种情况：
            // 1. TokenEmpty，TokenInvalid，TokenExpired
            // 2. 一般的调用失败
            if (
                isObject(err) &&
                isNumber(err.code) &&
                [InnerCode.TokenEmpty, InnerCode.TokenExpired, InnerCode.TokenInvalid].includes(err.code)
            ) {
                message.destroy();
                router.replace(LOGIN_ROUTE.path);
            } else {
                const defaultRedirectRoute = {
                    name: DEFAULT_REDIRECT_NAME,
                    path: "/",
                    redirect: NOT_FOUND_ROUTE.path,
                };
                [defaultRedirectRoute, ...COMMON_ROUTES, FALLBACK_ROUTE].forEach((route) => {
                    router.addRoute(route);
                });
                router.replace(defaultRedirectRoute);
                if (!hideMsg) {
                    message.error({ content: "用户菜单加载异常！", duration: 1, key: messageKey });
                }
            }
        }
    } else {
        // 认证信息不存在，未登录
        store.dispatch(`${CUSTOMER_MODULE}/${FETCH_CUSTOMIZED_INFO}`);
        const defaultRedirectRoute: RouteRecordRaw = {
            name: DEFAULT_REDIRECT_NAME,
            path: "/",
            redirect: isPortal ? NO_AUTH_ROUTES[0].path : LOGIN_ROUTE.path,
        };
        router.addRoute(defaultRedirectRoute);
    }
}

export default router;
