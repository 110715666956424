/*
 * @Author: 蒋文斌
 * @Date: 2021-05-19 10:20:42
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-28 11:01:51
 * @Description: 响应式计算栅格和间距
 */

import { computed, Ref } from "vue";
import { BREAK_POINTS } from "@/utils/const";

interface UseResponsiveFormLayoutResponse {
    responsiveSpan: Ref<number>;
    buttonsOffsetLeft: Ref<number>;
}

export const useResponsiveSpan = (containerWidth: Ref<number>): Pick<UseResponsiveFormLayoutResponse, "responsiveSpan"> => {
    // 响应式的查询表单栅格所占列数
    const responsiveSpan = computed(() => {
        const containerRawWidth = containerWidth.value;
        let span = 6; // 默认6
        if (containerRawWidth <= BREAK_POINTS.xs) {
            span = 24;
        } else if (containerRawWidth <= BREAK_POINTS.sm) {
            span = 12;
        } else if (containerRawWidth <= BREAK_POINTS.md) {
            span = 8;
        } else {
            span = 6;
        }
        return span;
    });
    return {
        responsiveSpan,
    };
};

export const useResponsiveFormLayout = (
    containerWidth: Ref<number>,
    isQueryFormExpanded: Ref<boolean>,
    searchColumnsLength: Ref<number>
): UseResponsiveFormLayoutResponse => {
    const { responsiveSpan } = useResponsiveSpan(containerWidth);
    // 计算按钮的左间隔
    const buttonsOffsetLeft = computed(() => {
        const containerRawWidth = containerWidth.value;
        let modBase = 1;
        let remainder = 0;
        if (containerRawWidth <= BREAK_POINTS.xs) {
            modBase = 1;
        } else if (containerRawWidth <= BREAK_POINTS.sm) {
            modBase = 2;
        } else if (containerRawWidth <= BREAK_POINTS.md) {
            modBase = 3;
        } else {
            modBase = 4;
        }
        if (isQueryFormExpanded.value) {
            // 展开情况
            remainder = searchColumnsLength.value % modBase;
        } else {
            // 折叠情况
            const visibleLength = Math.min(searchColumnsLength.value, 3);
            remainder = visibleLength % modBase;
        }
        const rowColNumber = 24 / responsiveSpan.value;
        return (rowColNumber - remainder - 1) * responsiveSpan.value;
    });

    return {
        responsiveSpan,
        buttonsOffsetLeft,
    };
};
