/*
 * @Author: 蒋文斌
 * @Date: 2021-06-04 09:08:31
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-06-04 09:11:22
 * @Description: 自动生成
 */

export const ORG_REPLACE_FIELDS = { key: "id", value: "id", children: "subList", title: "orgName" };

export const AUTH_REPLACE_FIELDS = { children: "subList", title: "authName", key: "id", value: "id" };

export const CANTON_REPLACE_FIELDS = { label: "cantonName", value: "id", children: "subList" };

export const FILEDIR_REPLACE_FIELDS = { key: "id", value: "id", children: "subDirs", title: "fileName" };
