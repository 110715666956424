
import { useStore } from "vuex";
import { pick } from "lodash-es";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DownOutlined } from "@ant-design/icons-vue";
import Autoscroll from "@/directives/autoscroll";
import { key } from "@/store";
import { DEFAULT_CUSTOMIZED_INFO } from "@/utils/const";
import { DISPATCH_LOGOUT, CHANGE_COM, AUTH_MODULE } from "@/store/constants";
import { findRedirectRouteByMenu, findRootMenuByRoutePath } from "@/permissions/generate-routes";
import { MY_ROUTE } from "@/router/common";

export default defineComponent({
    name: "ClNavbarLayout",
    components: {
        DownOutlined,
    },
    props: {
        useSlot: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        // vuex
        const store = useStore(key);
        // router
        const route = useRoute();
        const router = useRouter();

        // 权限相关
        const userMenus = computed(() => store.state.auth.userMenus);
        const flatMenus = computed(() => store.state.auth.flatMenus);
        const flatPages = computed(() => store.getters[`${AUTH_MODULE}/flatPages`]);
        const rootMenuId = computed(() => store.getters[`${AUTH_MODULE}/rootMenuId`]);
        // 租户相关
        const customizedInfo = computed(() => store.state.customer.customizedInfo);
        // 用户相关
        const userAvatar = computed(() => store.getters[`${AUTH_MODULE}/userAvatar`]);
        const realName = computed(() => store.getters[`${AUTH_MODULE}/realName`]);
        const comId = computed(() => store.getters[`${AUTH_MODULE}/comId`]);
        const userComList = computed(() => store.getters[`${AUTH_MODULE}/userComList`]);

        const layoutOptions = ref({
            headerClass: "header-nav flex-align-center",
            mainClass: "padding-0",
        });

        const backendLogoError = ref(`this.src="${DEFAULT_CUSTOMIZED_INFO.config.whiteTextLogoUrl}";this.onerror=null`);

        // 导航
        const navs = computed(() => {
            const showMenus = userMenus.value.filter((item) => !item.meta.isHidden);
            const result = showMenus.map((item) => {
                return {
                    ...pick(item, ["id", "icon", "path", "authType", "stateId", "nameCN"]),
                    href: findRedirectRouteByMenu(item, flatPages.value) || item.path,
                };
            });
            return result;
        });
        const rootMenu = computed(() => {
            return findRootMenuByRoutePath({
                menus: flatMenus.value,
                path: route.path,
                endParentId: rootMenuId.value,
            });
        });
        const selectedKeys = computed(() => {
            const nav = navs.value.find((item) => item.id === (rootMenu.value && rootMenu.value.id));
            return nav ? [nav.id] : [];
        });
        // 点击导航
        const onClickMenu = ({ key }: { key: number; keyPath: number[] }) => {
            const navItem = navs.value.find((menu) => menu.id === key);
            if (navItem) {
                router.push(navItem.href);
            }
        };

        // 导航栏右侧操作
        // logout action映射
        const handleLogout = () => store.dispatch(`${AUTH_MODULE}/${DISPATCH_LOGOUT}`);
        // 用户下拉操作
        const handleCommand = ({ key }: { key: string }) => {
            switch (key) {
                case "user":
                    router.push(MY_ROUTE.path);
                    break;
                case "logout":
                    handleLogout();
                    break;
                default:
                    break;
            }
        };
        // 切换租户
        const onComChange = (val: number) => store.dispatch(`${AUTH_MODULE}/${CHANGE_COM}`, val);

        return {
            customizedInfo,
            layoutOptions,
            backendLogoError,
            onClickMenu,
            handleCommand,
            onComChange,
            userAvatar,
            realName,
            comId,
            userComList,
            selectedKeys,
            navs,
        };
    },
    directives: {
        autoscroll: Autoscroll,
    },
});
