/*
 * @Author: 蒋文斌
 * @Date: 2021-05-28 09:30:55
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-28 09:32:08
 * @Description: pro form 的一些配置
 */

import { SelectOption } from "@/bean/pro";

export const RANGE_TYPES = ["daterange"];

export const DEFAULT_SELECT_OPTION: SelectOption = {
    key: "id",
    label: "name",
};
