/*
 * @Author: 蒋文斌
 * @Date: 2021-04-29 10:56:56
 * @LastEditors: 刘苗
 * @LastEditTime: 2021-08-12 16:40:59
 * @Description: Pro Table
 */

import { defineComponent, reactive, PropType, toRefs, computed, ref, nextTick, toRef, onMounted } from "vue";
import {
    Table as ATable,
    Button as AButton,
    Space as ASpace,
    Tooltip as ATooltip,
    Dropdown as ADropdown,
    Menu as AMenu,
    Checkbox as ACheckbox,
    Popover as APopover,
    ConfigProvider,
    Pagination,
} from "ant-design-vue";
import { DownOutlined, SettingOutlined, ReloadOutlined, ColumnHeightOutlined } from "@ant-design/icons-vue";
import { merge, pick, uniqBy } from "lodash-es";
import { useStore } from "vuex";
import { FormProps } from "ant-design-vue/lib/form";
import { GeneralFunction, PlainObject } from "@/bean/base";
import { ParamRecordDTO, RecordRowDTO } from "@/bean/dto";
import { PageResponse } from "@/bean/xhr";
import { ProTableColumn, SearchConf, TableSize, ToolbarOption, ProTableOptions, ProFormItem, ProFormItemKeys } from "@/bean/pro";
import { deepMerge } from "@/utils/helper";
import { BREAK_POINTS } from "@/utils/const";
import { initClipboard } from "@/utils/clipboard";
import ClFullscreen from "@/components/fullscreen/index.vue";
import { isBool, isDefined, isFunction } from "@/utils/type";
import { key } from "@/store";
import { PREFER_MODULE, SET_PREFERENCE } from "@/store/constants";
import { EnhancedHTMLElement } from "@/utils/fullscreen";
import { EnhancedTableRowSelection } from "@/bean/common";
import { DEFAULT_TOOLBAR, DENSITY_LIST } from "./config";
import { useTableColumn } from "./table-column";
import { useCalcProTable } from "./calc-dom";
import { useResponsiveFormLayout } from "./responsive";
import { useColumnSetting } from "./column-setting";
import styles from "./index.module.scss";
import ClProForm from "../pro-form/index";

initClipboard();

const props = {
    // 传递一些附带配置
    options: {
        type: Object as PropType<ProTableOptions>,
        default() {
            return {};
        },
    },
    // 分页查询API，如果不是分页的情况，请使用 providedData
    searchApi: {
        type: Function as PropType<(q: PlainObject) => Promise<PageResponse>>,
    },
    // 是否自动查询
    autoSearch: {
        type: Boolean,
        default: true,
    },
    // 是否需要header
    useHeader: {
        type: Boolean,
        default: true,
    },
    // 是否使用分页
    usePagination: {
        type: Boolean,
        default: true,
    },
    // 外部查询参数，除自动渲染表单外的参数都放在这里
    fixedForm: {
        type: Object,
        default() {
            return {};
        },
    },
    // 表单配置对象
    searchConf: {
        type: Object as PropType<SearchConf>,
        default() {
            return {};
        },
    },
    // 表单表格数据行
    columns: {
        type: Array as PropType<Array<ProTableColumn>>,
        default() {
            return [];
        },
    },
    // column通用默认配置
    commonColumnOption: {
        type: Object as PropType<ProTableColumn>,
        default() {
            return {};
        },
    },
    // 数据字典参数对象
    params: {
        type: Object as PropType<ParamRecordDTO>,
        default() {
            return {};
        },
    },
    // 是否使用外部数据源，配合providedData
    useProvided: {
        type: Boolean,
        default: false,
    },
    // 外部数据源，不另外调用接口去查，需要配合 useProvided 使用
    providedData: {
        type: Array as PropType<Array<PlainObject>>,
        default() {
            return [];
        },
    },
    // 列表格式化
    listFormatter: {
        type: Function as PropType<(list: RecordRowDTO[]) => RecordRowDTO[]>,
    },
    // 是否使用toolbar
    useToolbar: {
        type: Boolean,
        default: true,
    },
    // toolbar配置
    toolbar: {
        type: Object as PropType<ToolbarOption>,
        default() {
            return DEFAULT_TOOLBAR;
        },
    },
    // 定制全屏元素，适用于有关联性组件的场景，比如左侧组织树
    getFullscreenElement: {
        type: Function as PropType<() => EnhancedHTMLElement>,
    },
    // 是否允许选择
    enableSelect: {
        type: Boolean,
        default: false,
    },
    // 自定义额外的 rowSelection 配置
    appendRowSelection: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    // 透传给 pro-form 的属性
    formProps: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    // 显示展示/收起的最小表单项数量
    toggleLimit: {
        type: Number,
        default: 3,
    },
    defaultPageSize: {
        type: Number,
        default: 10,
    },
    customPagination: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    onCheckedRowsChange: {
        type: Function as PropType<GeneralFunction>,
    },
    beforeSearch: {
        type: Function as PropType<GeneralFunction>,
    },
    clearSelectionAfterSearch: {
        type: Boolean,
    },
};

export default defineComponent({
    name: "ClProTable",
    inheritAttrs: false,
    props,
    emits: ["reload", "reset"],
    setup(props, { attrs, slots, emit, expose }) {
        // props解构
        const { columns, params, commonColumnOption } = toRefs(props);
        // vuex
        const store = useStore(key);
        // 按 order 排序
        columns.value.sort((a, b) => (b.order || 0) - (a.order || 0));
        // 容器Ref
        const containerRef = ref();
        // 表格Ref
        const tableRef = ref();
        // ProForm Ref
        const proFormRef = ref();

        const getContainer = () => containerRef.value;
        const { containerWidth, calcTableBodyHeight } = useCalcProTable(getContainer, tableRef);

        // pro table 是否全屏状态
        const isProTableFullscreen = ref(false);
        const onFullscreenchange = (fullscreen: boolean) => {
            isProTableFullscreen.value = fullscreen;
        };

        // 表单处理，hideInSearch=true的不展示在查询区域
        const searchColumns = computed(() => {
            return columns.value.filter((item) => !item.hideInSearch);
        });
        // 是否展开查询表单
        const isQueryFormExpanded = ref(false);
        const toggleForm = () => {
            isQueryFormExpanded.value = !isQueryFormExpanded.value;
            nextTick(() => {
                calcTableBodyHeight();
            });
        };
        const toggleClass = computed(
            () => `${styles.toggle__wrapper} ${isQueryFormExpanded.value ? styles["is-expanded"] : styles["is-folden"]}`
        );
        const formProps = computed<FormProps>(() => {
            return {
                layout: "inline",
                ...props.formProps,
            };
        });

        const proFormItems = computed<ProFormItem[]>(() => {
            const formItems = searchColumns.value.map((column, index) => {
                const isHidden = !props.searchConf.hideButtons && !isQueryFormExpanded.value && index > props.toggleLimit - 1;
                const colStatusClass = isHidden ? "hidden" : "";
                return merge(
                    {
                        colProps: {
                            class: colStatusClass,
                        },
                    } as Partial<ProFormItem>,
                    pick(column, ProFormItemKeys)
                ) as ProFormItem;
            });
            if (!props.searchConf.hideButtons) {
                formItems.push({
                    dataIndex: "submitter",
                    colProps: {
                        class: "form-btns-col",
                        offset: buttonsOffsetLeft.value,
                    },
                    renderFormItemLevel: "formItem",
                    customRenderFormItem: () => {
                        return (
                            <ASpace>
                                <AButton onClick={resetForm}>重置</AButton>
                                <AButton type="primary" loading={isTableLoading.value} onClick={onClickSearch}>
                                    查询
                                </AButton>
                                {searchColumns.value.length > props.toggleLimit ? (
                                    <div class={toggleClass.value} onClick={toggleForm}>
                                        <span>{isQueryFormExpanded.value ? "收起" : "展开"}</span>
                                        <DownOutlined style={{ color: "var(--color-primary)", fontSize: "16px" }}></DownOutlined>
                                    </div>
                                ) : null}
                            </ASpace>
                        );
                    },
                } as ProFormItem);
            }
            return formItems;
        });

        const formWidth = computed(() => proFormRef.value?.formWidth || containerWidth);

        // 计算布局间距
        const { buttonsOffsetLeft } = useResponsiveFormLayout(formWidth, isQueryFormExpanded, toRef(searchColumns.value, "length"));

        // hideInTable=true的不展示在表格区域
        // table size
        const tableSize = computed(() => store.state.prefer.preference.density);
        const onSelectDensity = ({ key }: { key: TableSize }) => store.commit(`${PREFER_MODULE}/${SET_PREFERENCE}`, { density: key });
        // 当前活跃的主键键值，用于唯一确定一行的状态
        const activeRowKey = ref(-1);
        const setActiveRowKey = (val: number) => (activeRowKey.value = val);
        // table列处理
        const { tableColumns } = useTableColumn(columns, params, commonColumnOption, activeRowKey, setActiveRowKey);
        // 修复 ant-design-vue 2.2.8 版本 table checkbox 在左侧列固定场景下位置异常的bug
        const isSelectionFixed = tableColumns.value.some((item) => item.fixed === true || item.fixed === "left");

        // 多选/单选处理
        const rowSelection = reactive<EnhancedTableRowSelection>({
            fixed: true,
            selectedRowKeys: [],
            selectedRows: [],
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
                rowSelection.selectedRowKeys = selectedRowKeys;
                rowSelection.selectedRows = uniqBy([...rowSelection.selectedRows, ...selectedRows], "id").filter((item) =>
                    selectedRowKeys.includes(item.id)
                );
                if (props.onCheckedRowsChange) {
                    props.onCheckedRowsChange(rowSelection.selectedRows, rowSelection.selectedRowKeys);
                }
            },
            ...props.appendRowSelection,
        });

        // 清空选择
        const clearSelection = () => {
            rowSelection.selectedRowKeys = [];
            rowSelection.selectedRows = [];
        };

        // 根据key移除一项选择
        const removeSelectedItem = (key: number) => {
            const index = rowSelection.selectedRowKeys.findIndex((item) => item === key);
            rowSelection.selectedRowKeys.splice(index, 1);
            rowSelection.selectedRows.splice(index, 1);
        };

        // 接口查询流程
        const isTableLoading = ref(false);
        const pageNo = ref(1);
        const pageSize = ref(props.defaultPageSize);
        const total = ref(0);
        const tableData = ref<RecordRowDTO[]>([]);
        const handlePageNoChange = (page: number) => {
            pageNo.value = page;
            search();
        };
        const handlePageSizeChange = (current: number, size: number) => {
            pageNo.value = 1;
            pageSize.value = size;
            search();
        };
        const pagination = computed(() => {
            return props.usePagination
                ? {
                      class: "ant-table-pagination",
                      showQuickJumper: true,
                      showSizeChanger: true,
                      size: "small",
                      showTotal: (total: number) => `总共${total}条`,
                      simple: containerWidth.value <= BREAK_POINTS.xs,
                      ...props.customPagination,
                      total: total.value,
                      current: pageNo.value,
                      pageSize: pageSize.value,
                      onChange: handlePageNoChange,
                      onShowSizeChange: handlePageSizeChange,
                  }
                : false;
        });
        const getSearchParams = () => {
            const formModel = proFormRef.value && proFormRef.value.getProcessedFormModel();
            const params = {
                ...formModel,
                ...props.fixedForm,
            };
            if (props.usePagination) {
                params.pageNo = pageNo.value;
                params.pageSize = pageSize.value;
            }
            return params;
        };
        const tableKey = ref(Date.now());
        const reloadTable = () => {
            tableKey.value = Date.now();
        };
        const search = async () => {
            if (!props.searchApi) {
                return;
            }
            isTableLoading.value = true;
            try {
                let model = getSearchParams();
                if (isFunction(props.beforeSearch)) {
                    model = props.beforeSearch(model);
                }
                const { result } = await props.searchApi(model);
                const { list, totalRow } = result;
                tableData.value = props.listFormatter ? props.listFormatter(list) : list;
                total.value = totalRow;
            } finally {
                isTableLoading.value = false;
                nextTick(() => {
                    calcTableBodyHeight();
                });
                if (props.clearSelectionAfterSearch) {
                    clearSelection();
                }
            }
        };
        const resetForm = () => {
            proFormRef.value.resetFields();
            emit("reset");
            onClickSearch();
        };
        const onClickSearch = () => {
            pageNo.value = 1;
            search();
        };
        const onReload = () => {
            search();
            // useProvided 的情况下，如果使用了 toolbar，需要自行实现 onReload
            emit("reload");
        };

        onMounted(() => {
            if (props.autoSearch) {
                search();
            }
        });

        // toolbar
        const toolbarConfig = reactive<ToolbarOption>(deepMerge(DEFAULT_TOOLBAR, props.toolbar) as ToolbarOption);

        const {
            columnSettingState,
            onColumnCheckAllChange,
            onColumnItemCheckChange,
            resetColumnSetting,
            settingColumns,
            tableColumnGroups,
            generateColumnSettingOperations,
        } = useColumnSetting(tableColumns);

        // 最终处理的 table columns
        const processedTableColumns = computed(() => {
            return tableColumns.value
                .filter((item) => columnSettingState.columnCheckedList.includes(item.key as string))
                .map((item) => {
                    const settingColumn = settingColumns.value.find((item2) => item2.key === item.key);
                    return {
                        ...item,
                        fixed: settingColumn && isDefined(settingColumn.fixed) ? settingColumn.fixed : item.fixed,
                    };
                });
        });

        // 暴露属性或方法
        expose({
            proFormRef,
            getSearchParams,
            search,
            activeRowKey,
            setActiveRowKey,
            rowSelection,
            removeSelectedItem,
            toggleLoading: () => {
                isTableLoading.value = !isTableLoading.value;
            },
            getTableDataSource: () => {
                return props.useProvided ? props.providedData : tableData.value;
            },
            clearSelection,
            tableRef,
            reloadTable,
        });

        // 渲染函数
        return () => {
            const containerClass = `${styles["pro-table-layout__container"]} ${props.options.containerClass || ""}`;
            const headerClass = `${styles["pro-table-layout__header"]} ${props.options.headerClass || ""}`;
            const mainClass = `${styles["pro-table-layout__main"]} ${props.options.mainClass || ""}`;

            const prependSection = slots.prepend ? (
                <section class={`${styles["pro-table-layout__prepend"]} ${props.options.prependClass || ""}`}>{slots.prepend()}</section>
            ) : null;

            const headerSection = props.useHeader ? (
                <header class={headerClass}>
                    <ClProForm
                        ref={proFormRef}
                        {...formProps.value}
                        labelWidth={props.searchConf.labelWidth}
                        formItems={proFormItems.value}
                        useButtons={false}
                        params={params.value}
                        sorted={true}
                    ></ClProForm>
                </header>
            ) : null;

            const tableProps: PlainObject = {
                rowKey: "id",
                columns: processedTableColumns.value,
                dataSource: props.useProvided ? props.providedData : tableData.value,
                pagination: false,
                loading: isTableLoading.value,
                size: tableSize.value,
                scroll: {
                    x: 1500,
                    // TODO: 自适应 body 区域滚动
                    // y: tableBodyHeight.value,
                },
                rowSelection: props.enableSelect ? rowSelection : null,
                ...attrs,
            };

            const mainSection = (
                <main class={mainClass}>
                    {props.useToolbar ? (
                        <section
                            class={`${styles["pro-table-layout__toolbar"]} ${props.options.toolbarClass || ""}`}
                            style={{
                                justifyContent: toolbarConfig.config?.position === "left" ? "flex-start" : "flex-end",
                            }}
                        >
                            <ASpace size="middle">
                                {slots.toolbar?.()}
                                <div class={`${styles["pro-table-layout__toolbar-builtin"]} ${props.options.toolbarBuiltInClass || ""}`}>
                                    <ASpace size="middle">
                                        {!isBool(toolbarConfig.reload) ? (
                                            <ATooltip {...(toolbarConfig.reload.tooltip as PlainObject)}>
                                                <ReloadOutlined onClick={onReload} />
                                            </ATooltip>
                                        ) : null}
                                        {!isBool(toolbarConfig.density) ? (
                                            <ATooltip {...(toolbarConfig.density.tooltip as PlainObject)}>
                                                <ADropdown
                                                    placement="bottomCenter"
                                                    trigger={["click"]}
                                                    v-slots={{
                                                        overlay: () => {
                                                            return (
                                                                <AMenu selectedKeys={[tableSize.value]} onClick={onSelectDensity}>
                                                                    {DENSITY_LIST.map((item) => {
                                                                        return <AMenu.Item key={item.value}>{item.title}</AMenu.Item>;
                                                                    })}
                                                                </AMenu>
                                                            );
                                                        },
                                                    }}
                                                >
                                                    <ColumnHeightOutlined />
                                                </ADropdown>
                                            </ATooltip>
                                        ) : null}

                                        {!isBool(toolbarConfig.column) ? (
                                            <ATooltip {...(toolbarConfig.column.tooltip as PlainObject)}>
                                                <APopover
                                                    placement="bottom"
                                                    trigger="click"
                                                    overlayClassName={styles["column-setting__overlay"]}
                                                    v-slots={{
                                                        title: () => {
                                                            return (
                                                                <div class={styles["column-setting__header"]}>
                                                                    <ACheckbox
                                                                        checked={columnSettingState.isColumnCheckAll}
                                                                        onChange={onColumnCheckAllChange}
                                                                        indeterminate={columnSettingState.indeterminate}
                                                                    >
                                                                        列展示
                                                                    </ACheckbox>
                                                                    <AButton type="link" onClick={resetColumnSetting}>
                                                                        重置
                                                                    </AButton>
                                                                </div>
                                                            );
                                                        },
                                                        content: () => {
                                                            return (
                                                                <ACheckbox.Group
                                                                    class={styles["column-setting__checkbox-group"]}
                                                                    value={columnSettingState.columnCheckedList}
                                                                    onChange={onColumnItemCheckChange}
                                                                >
                                                                    {tableColumnGroups.value.map((group) => {
                                                                        return (
                                                                            <div
                                                                                key={group.position}
                                                                                class={styles["column-setting__group"]}
                                                                            >
                                                                                {group.list.length > 0 ? (
                                                                                    <div class={styles["column-setting__group-title"]}>
                                                                                        {group.title}
                                                                                    </div>
                                                                                ) : null}

                                                                                {group.list.map((column) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={column.key}
                                                                                            class={styles["column-setting__item"]}
                                                                                        >
                                                                                            <ACheckbox value={column.key}>
                                                                                                {column.title}
                                                                                            </ACheckbox>
                                                                                            <div class={styles["column-setting__ops"]}>
                                                                                                {generateColumnSettingOperations(
                                                                                                    group.position,
                                                                                                    column
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </ACheckbox.Group>
                                                            );
                                                        },
                                                    }}
                                                >
                                                    <SettingOutlined />
                                                </APopover>
                                            </ATooltip>
                                        ) : null}

                                        {!isBool(toolbarConfig.fullscreen) ? (
                                            <ATooltip
                                                title={isProTableFullscreen.value ? "退出全屏" : "全屏"}
                                                {...(toolbarConfig.fullscreen.tooltip as PlainObject)}
                                            >
                                                <ClFullscreen
                                                    useText={false}
                                                    iconSize={18}
                                                    getElement={props.getFullscreenElement || (() => containerRef.value)}
                                                    style="margin-top:1px;"
                                                    onFschange={onFullscreenchange}
                                                />
                                            </ATooltip>
                                        ) : null}
                                    </ASpace>
                                </div>
                            </ASpace>
                        </section>
                    ) : null}
                    {slots["before-table"]?.()}
                    {slots["render-table"] ? (
                        slots["render-table"]?.({ list: tableData.value })
                    ) : (
                        <ATable
                            ref={tableRef}
                            key={tableKey.value}
                            class={[styles["pro-table-layout__table-wrapper"], isSelectionFixed ? styles["pro-table-selection-fixed"] : ""]}
                            {...tableProps}
                        ></ATable>
                    )}
                    {props.usePagination ? <Pagination {...pagination.value}></Pagination> : null}
                </main>
            );
            return (
                <section ref={containerRef} class={containerClass}>
                    <ConfigProvider getPopupContainer={() => containerRef.value}>
                        {prependSection}
                        {headerSection}
                        {mainSection}
                        {slots.default?.()}
                    </ConfigProvider>
                </section>
            );
        };
    },
});
