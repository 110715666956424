
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { pick } from "lodash-es";
import { FlatMenuItemDTO } from "@/bean/dto";
import { useRootMenu } from "../helper";

const PickMenuKeys = ["id", "icon", "path", "authType", "authRoute", "stateId", "nameCN"] as const;

type FormattedMenuItem = Pick<FlatMenuItemDTO, typeof PickMenuKeys[number]> & {
    children: FormattedMenuItem[];
};

function menusFormatter(menus: FlatMenuItemDTO[]): FormattedMenuItem[] {
    return menus.map((item) => {
        return {
            ...pick(item, PickMenuKeys),
            children: menusFormatter(item.children || []),
        };
    });
}

export default defineComponent({
    name: "ClMapLayout",
    props: {
        useSlot: Boolean,
    },
    setup() {
        // router
        const router = useRouter();
        // 权限相关
        // 菜单相关
        const { rootMenu } = useRootMenu();

        const navs = computed(() => {
            return rootMenu.value && rootMenu.value.children ? menusFormatter(rootMenu.value.children) : [];
        });

        const onNavChange = (val: string) => {
            router.push(val);
        };
        return {
            navs,
            onNavChange,
        };
    },
});
