import { Input, Modal } from "ant-design-vue";
import { computed, defineComponent, PropType, ref } from "vue";
import { GeneralFunction, PlainObject } from "@/bean/base";
import { RecordRowDTO } from "@/bean/dto";
import { BaseSelectOption } from "@/bean/pro";
import { isFunction } from "@/utils/type";
import { ArrayResponse } from "@/bean/xhr";
import { useAsyncLoading } from "@/hooks/async";
import ClProSearch from "../pro-search";

const DEFAULT_SELECT_OPTION = {
    key: "id",
    label: "name",
};

type FindByIdsType = GeneralFunction<Promise<ArrayResponse>>;

const props = {
    title: {
        type: String,
        default: "选择",
    },
    value: {
        type: String,
        required: true,
    },
    inputSearchProps: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    proSearchProps: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    dialogWidth: {
        type: String,
        default: "1000px",
    },
    checkedRows: {
        type: Array as PropType<PlainObject[]>,
        default() {
            return [];
        },
    },
    checkedRowKeys: {
        type: Array as PropType<number[]>,
        default() {
            return [];
        },
    },
    findByIds: {
        type: Function as PropType<FindByIdsType>,
    },
    // 选择配置项，决定值和名称取什么字段
    selectOption: {
        type: Object as PropType<Required<BaseSelectOption>>,
        default() {
            return {
                key: "id",
                label: "name",
            };
        },
    },
};

// 对外提供v-model能力，将选择的逻辑内聚，支持单选和多选
export default defineComponent({
    name: "ClProSearchInput",
    props,
    inheritAttrs: false,
    emits: ["update:value", "change"],
    setup(props, { attrs, emit }) {
        const isSelectVisible = ref(false);

        const openSelectDialog = () => {
            isSelectVisible.value = true;
        };

        const closeSelectDialog = () => {
            isSelectVisible.value = false;
        };

        const onConfirmSelect = async (rows: RecordRowDTO[]) => {
            let text = "";
            if (props.proSearchProps.multiple) {
                // 多选
                text = rows.map((item) => item[props.selectOption.label]).join("，");
            } else {
                // 单选
                const row = rows[0];
                text = row[props.selectOption.label] as string;
            }
            // 支持 v-model
            emit("update:value", text);
            // 支持更丰富的定制能力
            emit("change", {
                text,
                rows,
                selectOption: props.selectOption,
            });
            closeSelectDialog();
            await Promise.resolve();
        };

        const _searchProps = computed(() => {
            return {
                ...attrs,
                ...props.proSearchProps,
                selectOption: props.selectOption || DEFAULT_SELECT_OPTION,
                checkedRows: props.checkedRows,
                onCancel: closeSelectDialog,
                onSubmit: onConfirmSelect,
            };
        });

        // 根据接口异步回显
        const getCheckedRowsByApi = async () => {
            const { result } = await (props.findByIds as FindByIdsType)({
                ids: props.checkedRowKeys,
            });
            const text = result.map((item) => item[props.selectOption.label]).join(", ");
            emit("update:value", text);
            emit("change", {
                text,
                rows: result,
                selectOption: props.selectOption,
            });
            if (isFunction(attrs.onCheckedRowsChange)) {
                attrs.onCheckedRowsChange(result);
            }
        };

        const { trigger: getApiData, loading: isFetchingData } = useAsyncLoading(getCheckedRowsByApi);

        // 默认值处理
        if (props.checkedRows.length > 0) {
            // 优先判断 checkedRows
            const text = props.checkedRows.map((item) => item[props.selectOption.label]).join(", ");
            emit("update:value", text);
            emit("change", {
                text,
                rows: props.checkedRows,
                selectOption: props.selectOption,
            });
        } else if (props.checkedRowKeys.length > 0 && isFunction(props.findByIds)) {
            // 如果需要异步通过接口回显，需要提供 checkedRowKeys 和 findByIds 接口。
            getApiData();
        }

        return () => {
            return (
                <>
                    <Input.Search
                        value={props.value}
                        enterButton={true}
                        readonly={true}
                        loading={isFetchingData.value}
                        onSearch={openSelectDialog}
                        {...props.inputSearchProps}
                    />

                    <Modal
                        title={props.title}
                        visible={isSelectVisible.value}
                        width={props.dialogWidth}
                        footer={null}
                        onCancel={closeSelectDialog}
                    >
                        {isSelectVisible.value ? <ClProSearch {..._searchProps.value} /> : null}
                    </Modal>
                </>
            );
        };
    },
});
