import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2c1793e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-layout--column cl-steps__wrapper" }
const _hoisted_2 = { class: "cl-steps__content" }
const _hoisted_3 = { class: "align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_cl_button = _resolveComponent("cl-button")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_a_steps, { current: _ctx.currentStep }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item) => {
          return (_openBlock(), _createBlock(_component_a_step, {
            key: item.title,
            title: item.title
          }, null, 8, ["title"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["current"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.steps[_ctx.currentStep].comp), _mergeProps({ ref: "compRef" }, { ..._ctx.commonProps, ...(_ctx.steps[_ctx.currentStep].props || {}) }), null, 16))
      ], 1024))
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_a_space, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "beforeButton", {}, undefined, true),
          (_ctx.isPrevVisible)
            ? (_openBlock(), _createBlock(_component_cl_button, {
                key: 0,
                disabled: _ctx.currentStep === _ctx.startStep,
                loading: _ctx.isPrevLoading,
                onClick: _ctx.onClickPrevStep
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.prevTitle), 1)
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "middleButton", {}, undefined, true),
          (_ctx.isNextVisible)
            ? (_openBlock(), _createBlock(_component_cl_button, {
                key: 1,
                type: "primary",
                disabled: _ctx.currentStep === _ctx.finishStep,
                onClick: _ctx.onClickNextStep,
                loading: _ctx.isNextLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.nextTitle), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick", "loading"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "afterButton", {}, undefined, true)
        ]),
        _: 3
      })
    ])
  ]))
}