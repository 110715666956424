import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadOutlined = _resolveComponent("UploadOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_upload, _mergeProps({ class: "cl-upload" }, _ctx.processedAttrs), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createVNode(_component_a_button, null, {
            default: _withCtx(() => [
              _createVNode(_component_UploadOutlined),
              _createTextVNode(" " + _toDisplayString(_ctx.uploadText), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 3
    }, 16),
    _createVNode(_component_a_image, {
      ref: "previewImgRef",
      src: _ctx.previewURL,
      style: {"display":"none"}
    }, null, 8, ["src"])
  ], 64))
}