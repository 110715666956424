/*
 * @Author: 蒋文斌
 * @Date: 2021-01-05 22:17:58
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-13 11:51:00
 * @Description: 用户模块
 */
import { ApiService } from "@/services/index";
import { admin } from "@/services/config";
import { PlainObject } from "@/bean/base";
import { ArrayResponse, RecordResponse } from "@/bean/xhr";
import { GroupDTO, MenuRouteDTO, RoleDTO, UserDTO } from "@/bean/dto";

class UserService extends ApiService {
    // 获取当前用户信息
    getUser() {
        return this.$get("getUser", undefined, { globalRequest: true });
    }

    getMenus() {
        return this.$get("getMenus", undefined, { globalRequest: true });
    }

    add(params: PlainObject) {
        return this.$postJson("add", params);
    }

    update(params: PlainObject) {
        return this.$putJson("update", params);
    }

    delete(id: number) {
        return this.$del("delete", { id });
    }

    page(params: PlainObject) {
        return params.wd ? this.search(params) : this.$postJson("page", params);
    }

    search(params: PlainObject) {
        return this.$postJson("search", params);
    }

    detail(id: number) {
        return this.$get<RecordResponse>("detail", { id });
    }

    getUserNotHaveRole(params: PlainObject) {
        return this.$get<ArrayResponse<RoleDTO>>("getUserNotHaveRole", params);
    }

    grantUserRole(params: PlainObject) {
        return this.$putJson("grantUserRole", params);
    }

    getUserNotHaveGroup(params: PlainObject) {
        return this.$get<ArrayResponse<GroupDTO>>("getUserNotHaveGroup", params);
    }

    getUserOrgNotHaveGroup(params: PlainObject) {
        return this.$get<ArrayResponse<GroupDTO>>("getUserOrgNotHaveGroup", params);
    }

    grantUserGroup(params: PlainObject) {
        return this.$putJson("grantUserGroup", params);
    }

    grantUserDefaultGroup(params: PlainObject) {
        return this.$get("grantUserDefaultGroup", params);
    }

    getUserByComId(params: PlainObject) {
        return this.$get<ArrayResponse<UserDTO>>("getUserByComId", params);
    }

    find(params: PlainObject) {
        return this.$postJson("find", params);
    }

    findAll(params?: PlainObject) {
        return this.$get<ArrayResponse<UserDTO>>("findAll", params);
    }

    findByIds(params?: PlainObject) {
        return this.$get<ArrayResponse<UserDTO>>("findByIds", params);
    }

    checkPassword(params: PlainObject) {
        return this.$get("checkPassword", params);
    }

    updatePwd(params: PlainObject) {
        return this.$post("updatePwd", params);
    }

    resetPassword(userId: number) {
        return this.$put("resetPassword", { userId });
    }

    userPartTime(params: PlainObject) {
        return this.$putJson("userPartTime", params);
    }

    getUserAuth(params: PlainObject) {
        return this.$get<ArrayResponse<MenuRouteDTO>>("getUserAuth", params);
    }

    getUserGroupChief(userId: number) {
        return this.$get<ArrayResponse>("getUserGroupChief", { userId });
    }
}

export const userService = new UserService(admin, "user");
