/*
 * @Author: 蒋文斌
 * @Date: 2021-05-18 11:14:31
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-28 16:38:28
 * @Description: 列设置
 */

import { pick } from "lodash-es";
import { computed, reactive, ref, Ref, watchEffect } from "vue";
import { VerticalAlignTopOutlined, VerticalAlignBottomOutlined, VerticalAlignMiddleOutlined } from "@ant-design/icons-vue";
import { Space as ASpace, Tooltip as ATooltip } from "ant-design-vue";
import { ProTableColumn, SettingColumn, SettingColumnGroup } from "@/bean/pro";
import { GeneralFunction } from "@/bean/base";

interface ColumnSettingState {
    isColumnCheckAll: boolean;
    initColumnCheckedList: string[];
    columnCheckedList: string[];
    indeterminate: boolean;
}

interface UseColumnSettingResponse {
    columnSettingState: ColumnSettingState;
    onColumnCheckAllChange: GeneralFunction;
    onColumnItemCheckChange: GeneralFunction;
    resetColumnSetting: GeneralFunction;
    settingColumns: Ref<SettingColumn[]>;
    tableColumnGroups: Ref<SettingColumnGroup[]>;
    generateColumnSettingOperations: (position: SettingColumnGroup["position"], column: SettingColumn) => JSX.Element;
}

export const useColumnSetting = (tableColumns: Ref<ProTableColumn[]>): UseColumnSettingResponse => {
    // 列设置
    const columnSettingState = reactive<ColumnSettingState>({
        isColumnCheckAll: true,
        // 初始勾选的 keys
        initColumnCheckedList: tableColumns.value.filter((item) => item.visible).map((item) => item.key as string),
        // 已勾选的 key
        columnCheckedList: [],
        // 是否半选中，仅控制样式
        indeterminate: false,
    });
    watchEffect(() => {
        const columnCheckedList = tableColumns.value.filter((item) => item.visible).map((item) => item.key);
        const isColumnCheckAll = columnCheckedList.length === tableColumns.value.length;
        Object.assign(columnSettingState, {
            columnCheckedList,
            isColumnCheckAll,
            indeterminate: columnCheckedList.length > 0 && isColumnCheckAll === false,
        });
    });
    const onColumnCheckAllChange = (e: { target: HTMLInputElement }) => {
        const checked = e.target.checked;
        Object.assign(columnSettingState, {
            isColumnCheckAll: checked,
            columnCheckedList: checked ? tableColumns.value.map((item) => item.key) : [],
            indeterminate: false,
        });
    };
    const onColumnItemCheckChange = (checkedValue: string[]) => {
        const isColumnCheckAll = checkedValue.length === tableColumns.value.length;
        Object.assign(columnSettingState, {
            columnCheckedList: checkedValue,
            isColumnCheckAll,
            indeterminate: checkedValue.length > 0 && isColumnCheckAll === false,
        });
    };
    const resetColumnSetting = () => {
        // checkbox重置
        const isColumnCheckAll = columnSettingState.initColumnCheckedList.length === tableColumns.value.length;
        Object.assign(columnSettingState, {
            isColumnCheckAll,
            columnCheckedList: columnSettingState.initColumnCheckedList,
            indeterminate: columnSettingState.initColumnCheckedList.length > 0 && isColumnCheckAll === false,
        });

        // fixed重置
        settingColumns.value = tableColumns.value.map((item) => {
            return pick(item, ["key", "fixed", "title", "visible"]);
        });
    };

    const settingColumns = ref<SettingColumn[]>([]);
    watchEffect(() => {
        settingColumns.value = tableColumns.value.map((item) => {
            return pick(item, ["key", "fixed", "title", "visible"]);
        });
    });

    const tableColumnGroups = computed<SettingColumnGroup[]>(() => {
        const fixedLeftGroup = settingColumns.value.filter((item) => item.fixed === "left" || item.fixed === true);
        const fixedRightGroup = settingColumns.value.filter((item) => item.fixed === "right");
        const middleGroup = settingColumns.value.filter((item) => item.fixed === false);
        return [
            {
                position: "left",
                title: "固定在左侧",
                list: fixedLeftGroup,
            },
            {
                position: "middle",
                title: "不固定",
                list: middleGroup,
            },
            {
                position: "right",
                title: "固定在右侧",
                list: fixedRightGroup,
            },
        ];
    });

    const onChangeFixed = (to: ProTableColumn["fixed"], column: SettingColumn) => {
        const index = settingColumns.value.findIndex((item) => item.key === column.key);
        if (index !== -1) {
            settingColumns.value[index].fixed = to;
        }
    };

    const generateColumnSettingOperation = (to: ProTableColumn["fixed"], column: SettingColumn) => {
        if (to === false) {
            return (
                <ATooltip placement="top" title="不固定">
                    <VerticalAlignMiddleOutlined onClick={() => onChangeFixed(to, column)} />
                </ATooltip>
            );
        } else if (to === "left" || to === true) {
            return (
                <ATooltip placement="top" title="固定到左侧">
                    <VerticalAlignTopOutlined onClick={() => onChangeFixed(to, column)} />
                </ATooltip>
            );
        } else {
            return (
                <ATooltip placement="top" title="固定到右侧">
                    <VerticalAlignBottomOutlined onClick={() => onChangeFixed(to, column)} />
                </ATooltip>
            );
        }
    };

    const generateColumnSettingOperations = (position: SettingColumnGroup["position"], column: SettingColumn) => {
        if (position === "left") {
            return (
                <ASpace>
                    {generateColumnSettingOperation(false, column)}
                    {generateColumnSettingOperation("right", column)}
                </ASpace>
            );
        } else if (position === "middle") {
            return (
                <ASpace>
                    {generateColumnSettingOperation("left", column)}
                    {generateColumnSettingOperation("right", column)}
                </ASpace>
            );
        } else {
            return (
                <ASpace>
                    {generateColumnSettingOperation("left", column)}
                    {generateColumnSettingOperation(false, column)}
                </ASpace>
            );
        }
    };

    return {
        columnSettingState,
        onColumnCheckAllChange,
        onColumnItemCheckChange,
        resetColumnSetting,
        settingColumns,
        tableColumnGroups,
        generateColumnSettingOperations,
    };
};
