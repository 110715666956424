import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: _normalizeClass(["x-layout", _ctx.options.containerClass])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, _mergeProps({
        class: _ctx.options.asideClass
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "aside", {}, undefined, true)
        ]),
        _: 3
      }, 16, ["class"]),
      _createVNode(_component_a_layout_content, {
        class: _normalizeClass(_ctx.options.mainClass)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "main", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["class"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}