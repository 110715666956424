/*
 * @Author: 蒋文斌
 * @Date: 2021-04-29 21:47:00
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-07 10:57:41
 * @Description: 过滤器
 */

import { ParamDTO } from "@/bean/dto";

export function paramFilter(val: string, paramSubList: Array<ParamDTO>, useFullCode = false): string {
    if (paramSubList && paramSubList.length > 0) {
        const findOne = paramSubList.find((item) => item[useFullCode ? "fullCode" : "paramCode"] === String(val));
        return (findOne && findOne.paramName) || val;
    } else {
        return val;
    }
}
