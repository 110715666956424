/*
 * @Author: 蒋文斌
 * @Date: 2021-01-09 14:00:01
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-17 11:11:47
 * @Description: Tab页状态
 */

import { ActionContext } from "vuex";
import { last } from "lodash-es";
import router from "@/router";
import storage from "@/utils/storage";
import {
    SET_ROUTE_TABS,
    ADD_ROUTE_TAB,
    REMOVE_ROUTE_TAB,
    REMOVE_ALL_ROUTE_TABS_BY_MATCH,
    UPDATE_ROUTE_TAB,
    CLOSE_CURRENT_TAB,
    REOPEN_ROUTE_TAB,
    EXECUTE_TAB_SHORTCUT,
    RELOAD_CURRENT_ROUTE_TAB,
    SET_CURRENT_TAB,
} from "@/store/constants";
import { ContextmenuShortCutDTO } from "@/bean/dto";
import { RootState } from "../index";

let routeTabs = [];

const routeTabsInStorage = storage.get("routeTabs", true);
if (routeTabsInStorage) {
    routeTabs = JSON.parse(routeTabsInStorage);
}

interface RouteTab {
    path: string;
    fullPath: string;
    matchedPath: string;
    name: string;
    compName: string;
    parentRoutePath: string;
    hiddenInTab: boolean;
    uncloseable: boolean;
    reloadKey: number;
}

export interface RemoveRouteTabPayload {
    matchedPath: string;
    autoNavigate?: boolean;
}

export interface TabsState {
    routeTabs: RouteTab[];
    currentTab: RouteTab | undefined;
}

interface ShortcutPayload {
    action: ContextmenuShortCutDTO["key"];
    fullPath: string;
}

export const tabs = {
    namespaced: true,
    state: {
        routeTabs,
        currentTab: undefined,
    },
    getters: {
        currentTabReloadKey: (state: TabsState) => state.currentTab && state.currentTab.reloadKey,
    },
    mutations: {
        // 设置Tabs数据
        [SET_ROUTE_TABS](state: TabsState, payload: RouteTab[]): void {
            if (payload) {
                state.routeTabs = payload;
                storage.set("routeTabs", JSON.stringify(payload), true);
            } else {
                state.routeTabs = [];
                storage.remove("routeTabs", true);
            }
        },
        // 设置 currentTab 数据，仅在路由更新后处理
        [SET_CURRENT_TAB](state: TabsState, payload?: RouteTab): void {
            state.currentTab = payload;
        },
    },
    actions: {
        // 新增一个Tab页
        [ADD_ROUTE_TAB]({ commit, state }: ActionContext<TabsState, RootState>, payload: RouteTab): void {
            // 新增入口统一处理 reloadKey
            payload.reloadKey = 1;
            commit(SET_ROUTE_TABS, [...state.routeTabs, payload]);
        },
        // 对现有Tab页做更新操作
        [UPDATE_ROUTE_TAB]({ commit, state }: ActionContext<TabsState, RootState>, payload: RouteTab): void {
            const { routeTabs } = state;
            const index = routeTabs.findIndex((item) => item.path === payload.path);
            routeTabs[index] = payload;
            commit(SET_ROUTE_TABS, routeTabs);
        },
        // 根据matchedPath移除所有匹配的Tab
        [REMOVE_ALL_ROUTE_TABS_BY_MATCH]({ commit, state }: ActionContext<TabsState, RootState>, payload: RouteTab): void {
            const { routeTabs } = state;
            const newRouteTabs = routeTabs.filter((item) => item.matchedPath !== payload.matchedPath);
            commit(SET_ROUTE_TABS, newRouteTabs);
        },
        // 根据matchedPath移除一个Tab页，autoNavigate控制是否要自动导航
        [REMOVE_ROUTE_TAB](
            { commit, state }: ActionContext<TabsState, RootState>,
            { matchedPath, autoNavigate = true }: RemoveRouteTabPayload
        ): void {
            const { routeTabs } = state;
            // 过滤出可见Tabs
            const visibleRouteTabs = routeTabs.filter((item) => !item.hiddenInTab);
            // removeIndex是所有Tabs中matchedPath匹配的Tab index
            const removeIndex = routeTabs.findIndex((item) => !item.hiddenInTab && item.matchedPath === matchedPath);
            // visibleIndex是可见Tabs中matchedPath匹配的Tab index
            const visibleIndex = visibleRouteTabs.findIndex((item) => item.matchedPath === matchedPath);
            if (removeIndex !== -1) {
                const toRemoveRouteTab = routeTabs[removeIndex];
                routeTabs.splice(removeIndex, 1);
                visibleRouteTabs.splice(visibleIndex, 1);
                // as断言不为空
                const { path: currentMatchedPath } = last(router.currentRoute.value.matched) as { path: string };
                // 如果关闭的是当前激活的Tab，则要进行下一步的路由选择
                if (currentMatchedPath === matchedPath && autoNavigate) {
                    let nextPath;
                    if (toRemoveRouteTab.parentRoutePath) {
                        const parentTabIndex = routeTabs.findIndex((item) => item.path === toRemoveRouteTab.parentRoutePath);
                        if (parentTabIndex !== -1) {
                            // 如果存在parentRoutePath
                            const isParentTabHidden = routeTabs[parentTabIndex].hiddenInTab;
                            if (isParentTabHidden) {
                                // 如果父Tab隐藏了，把父Tab显示出来
                                routeTabs[parentTabIndex] = {
                                    ...routeTabs[parentTabIndex],
                                    hiddenInTab: false,
                                };
                            }
                            // 导航到父Tab的路由
                            nextPath = toRemoveRouteTab.parentRoutePath;
                        } else {
                            nextPath = "/";
                        }
                    } else if (visibleRouteTabs.length > 0) {
                        // 返回可见范围内，顺序上的前一个页面
                        const nextActiveTabIndex = Math.max(visibleIndex - 1, 0);
                        nextPath = visibleRouteTabs[nextActiveTabIndex].fullPath;
                    } else {
                        nextPath = "/";
                    }
                    router.push(nextPath);
                }
                commit(SET_ROUTE_TABS, routeTabs);
            }
        },
        // 对当前Tab页做刷新操作
        [RELOAD_CURRENT_ROUTE_TAB]({ commit, dispatch, state }: ActionContext<TabsState, RootState>): void {
            if (!state.currentTab) {
                return;
            }
            const newKey = state.currentTab.reloadKey + 1;
            const newTab = {
                ...state.currentTab,
                reloadKey: newKey,
            };
            // 刷新 tab 页，本质上是刷新 reloadKey
            commit(SET_CURRENT_TAB, newTab);
            // 同时更新 routeTabs 数据，保证 currentTab 和 routeTabs 数据一致性
            dispatch(UPDATE_ROUTE_TAB, newTab);
        },
        // 关闭当前Tab页
        [CLOSE_CURRENT_TAB](
            { state, dispatch }: ActionContext<TabsState, RootState>,
            { autoNavigate = true, reloadOpener = true } = {}
        ): void {
            const { path: currentMatchedPath } = last(router.currentRoute.value.matched) as { path: string };
            dispatch(REMOVE_ROUTE_TAB, {
                matchedPath: currentMatchedPath,
                autoNavigate,
            });
            if (reloadOpener && state.currentTab?.parentRoutePath) {
                // 刷新父级 tab
                const parentTab = state.routeTabs.find((item) => item.path === state.currentTab?.parentRoutePath);
                if (parentTab) {
                    dispatch(UPDATE_ROUTE_TAB, {
                        ...parentTab,
                        reloadKey: parentTab.reloadKey + 1,
                    });
                }
            }
        },
        // 适用于点击新增按钮需要重新打开页面的场景，代替直接使用 router.push
        [REOPEN_ROUTE_TAB]({ dispatch, state }: ActionContext<TabsState, RootState>, fullPath: string): void {
            const removeIndex = state.routeTabs.findIndex((item) => !item.hiddenInTab && item.fullPath === fullPath);
            if (removeIndex !== -1) {
                const { matchedPath } = state.routeTabs[removeIndex];
                dispatch(REMOVE_ROUTE_TAB, {
                    matchedPath,
                });
                // mock nextTick
                setTimeout(() => {
                    router.push(fullPath);
                }, 0);
            } else {
                router.push(fullPath);
            }
        },
        [EXECUTE_TAB_SHORTCUT]({ commit, state }: ActionContext<TabsState, RootState>, { action, fullPath }: ShortcutPayload): void {
            let index: number;
            switch (action) {
                case "closeall":
                    commit(SET_ROUTE_TABS, null);
                    router.push("/");
                    break;
                case "closeother":
                    const reservedTab = state.routeTabs.find((tab) => tab.fullPath === fullPath) as RouteTab;
                    const newTabs = [reservedTab];
                    commit(SET_ROUTE_TABS, newTabs);
                    if (reservedTab.fullPath !== router.currentRoute.value.fullPath) {
                        // 保留的Tab页不匹配当前路由，需要进行路由切换
                        router.push(reservedTab.fullPath);
                    }
                    break;
                case "closeleft":
                    index = state.routeTabs.findIndex((tab) => tab.fullPath === fullPath);
                    if (index !== -1) {
                        const newTabs = state.routeTabs.slice(index);
                        commit(SET_ROUTE_TABS, newTabs);
                        const currentRouteIndexInNewTab = newTabs.findIndex((tab) => tab.fullPath === router.currentRoute.value.fullPath);
                        if (currentRouteIndexInNewTab === -1) {
                            // 删掉的部分包含了当前路由，此时需要重新切换路由
                            router.push(newTabs[0].fullPath);
                        }
                    }
                    break;
                case "closeright":
                    index = state.routeTabs.findIndex((tab) => tab.fullPath === fullPath);
                    if (index !== -1) {
                        const newTabs = state.routeTabs.slice(0, index + 1);
                        commit(SET_ROUTE_TABS, newTabs);
                        const currentRouteIndexInNewTab = newTabs.findIndex((tab) => tab.fullPath === router.currentRoute.value.fullPath);
                        if (currentRouteIndexInNewTab === -1) {
                            // 删掉的部分包含了当前路由，此时需要重新切换路由
                            router.push(newTabs[index].fullPath);
                        }
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
