/*
 * @Author: 蒋文斌
 * @Date: 2021-01-09 14:14:26
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-18 16:34:30
 * @Description: vuex常量定义
 */

// Modules
export const AUTH_MODULE = "auth";

export const TABS_MODULE = "tabs";

export const CUSTOMER_MODULE = "customer";

export const PREFER_MODULE = "prefer";

// AUTH_MODULE Mutations
export const SET_VERIFY_KEY = "setVerifyKey";

export const SET_ACCESS_INFO = "setAccessInfo";

export const SET_USER_INFO = "setUserInfo";

export const SET_USER_MENUS = "setUserMenus";

export const SET_BADGE_INFO = "setBadgeInfo";

export const SET_BTN_AUTH_PATHS = "setBtnAuthPaths";

// AUTH_MODULE ACTIONS
export const FETCH_VERIFY_CODE = "fetchVerifyCode";

export const DISPATCH_LOGIN = "dispatchLogin";

export const DISPATCH_LOGOUT = "dispatchLogout";

export const GET_CURRENT_USER = "getCurrentUser";

export const GET_USER_MENUS = "getUserMenus";

export const CHANGE_COM = "changeCom";

// TABS_MODULE Mutations
export const SET_ROUTE_TABS = "setRouteTabs";

export const SET_CURRENT_TAB = "setCurrentTab";

// PREFER_MODULE Mutations
export const SET_PREFERENCE = "setPreference";

// TABS_MODULE ACTIONS
export const CLOSE_CURRENT_TAB = "closeCurrentTab";

export const REOPEN_ROUTE_TAB = "reopenRouteTab";

export const EXECUTE_TAB_SHORTCUT = "executeTabShortcut";

export const RELOAD_CURRENT_ROUTE_TAB = "reloadCurrentRouteTab";

export const ADD_ROUTE_TAB = "addRouteTab";

export const UPDATE_ROUTE_TAB = "updateRouteTab";

export const REMOVE_ROUTE_TAB = "removeRouteTab";

export const REMOVE_ALL_ROUTE_TABS_BY_MATCH = "removeAllRouteTabsByMath";

// CUSTOMER_MODULE Mutations
export const SET_CUSTOMIZED_INFO = "setCustomizedInfo";

// CUSTOMER_MODULE ACTIONS
export const FETCH_CUSTOMIZED_INFO = "fetchCustomizedInfo";

export const FETCH_AUTHORIZED_CUSTOMIZED_INFO = "fetchAuthorizedCustomizedInfo";

export const FETCH_UNAUTHORIZED_CUSTOMIZED_INFO = "fetchUnauthorizedCustomizedInfo";
