
import { computed, defineComponent, PropType, ref } from "vue";
import { useAsyncLoading } from "@/hooks/async";
import { DefineComponentOptions, GeneralFunction, PlainObject } from "@/bean/base";

export interface StepItem {
    title: string;
    comp: DefineComponentOptions;
    props?: PlainObject;
    beforePrev?: GeneralFunction<Promise<unknown>>;
    beforeNext?: GeneralFunction<Promise<unknown>>;
}

export interface HideControlFn {
    (current: number, total: number): boolean;
}

const props = {
    prevTitle: {
        type: String,
        default: "上一步",
    },
    nextTitle: {
        type: String,
        default: "下一步",
    },
    commonProps: {
        type: Object as PropType<PlainObject>,
        default() {
            return {};
        },
    },
    steps: {
        type: Array as PropType<StepItem[]>,
        default() {
            return [];
        },
    },
    controlHidePrev: {
        type: Function as PropType<HideControlFn>,
    },
    controlHideNext: {
        type: Function as PropType<HideControlFn>,
    },
};

export default defineComponent({
    name: "ClSteps",
    props,
    setup(props) {
        // 步骤信息
        const compRef = ref();
        const currentStep = ref(0);
        const startStep = ref(0);
        const finishStep = computed(() => props.steps.length - 1);
        const handlePrev = () => {
            if (currentStep.value > 0) {
                currentStep.value--;
            }
        };
        const handlePrevStep = async () => {
            const { beforePrev } = props.steps[currentStep.value];
            if (beforePrev && typeof beforePrev === "function") {
                await beforePrev();
                handlePrev();
            } else {
                handlePrev();
            }
        };
        const handleNext = () => {
            if (currentStep.value < finishStep.value) {
                currentStep.value++;
            }
        };
        const handleNextStep = async () => {
            const { beforeNext } = props.steps[currentStep.value];
            if (beforeNext && typeof beforeNext === "function") {
                await beforeNext();
                handleNext();
            } else {
                handleNext();
            }
        };
        const { trigger: onClickPrevStep, loading: isPrevLoading } = useAsyncLoading(handlePrevStep);
        const { trigger: onClickNextStep, loading: isNextLoading } = useAsyncLoading(handleNextStep);

        const isPrevVisible = computed(() => {
            if (props.controlHidePrev) {
                return !props.controlHidePrev(currentStep.value, props.steps.length);
            } else {
                return true;
            }
        });

        const isNextVisible = computed(() => {
            if (props.controlHideNext) {
                return !props.controlHideNext(currentStep.value, props.steps.length);
            } else {
                return true;
            }
        });

        return {
            currentStep,
            startStep,
            finishStep,
            onClickPrevStep,
            onClickNextStep,
            isPrevLoading,
            isNextLoading,
            compRef,
            isPrevVisible,
            isNextVisible,
        };
    },
});
