import { merge } from "lodash-es";
import { DirectiveBinding } from "@vue/runtime-core";
import { getOffset } from "@/utils/dom";
import { PlainObject } from "@/bean/base";

interface AutoScrollOption {
    triggerDistance: number;
    delay: number;
    step: number;
}

interface AutoScrollElement extends HTMLElement {
    autoScrollHelper: AutoScrollHelper;
}

class AutoScrollHelper {
    private options: AutoScrollOption;

    private el: HTMLElement;

    private isOverflow: boolean;

    private isTranslating: boolean;

    private isWaiting: boolean;

    private translateDirection: "left" | "right";

    private translateMin: number;

    private currentTranslateValue: number;

    private rafId: number | null;

    private firstChild: HTMLElement | null;

    private delayTimer: number | null;

    private containerWidth = 0;

    private scrollListWidth = 0;

    constructor(el: HTMLElement, options: PlainObject) {
        const defaultOptions = {
            triggerDistance: 150,
            step: 6,
            delay: 500,
        };
        this.options = merge(defaultOptions, options);
        this.el = el;
        this.isOverflow = false; // 是否溢出
        this.isTranslating = false; // 是否正在移动
        this.isWaiting = false; // 是否延迟等待中
        this.translateDirection = "left"; // left代表向左滑动
        this.translateMin = 0;
        this.currentTranslateValue = 0;
        this.rafId = null;
        this.firstChild = null;
        this.delayTimer = null; // 延迟等待定时器
    }

    handleInserted() {
        this.el.addEventListener("mouseenter", () => {
            this.checkNodes();
        });
        this.el.addEventListener("mouseleave", () => {
            this.isWaiting = false;
            this.stopTranslate();
            this.removeDelayTimer();
        });
        this.el.addEventListener("mousemove", (e) => {
            if (this.isOverflow) {
                const { offsetLeft } = getOffset(this.el);
                if (e.clientX - offsetLeft < this.options.triggerDistance) {
                    // 到达左侧临界值
                    this.translateDirection = "right";
                    if (!this.isTranslating && !this.isWaiting) {
                        // 未发生移动并且不在等待期，才可以执行startTranslate方法
                        this.startTranslate();
                    }
                } else if (this.containerWidth + offsetLeft - e.clientX < this.options.triggerDistance) {
                    // 到达右侧临界值
                    this.translateDirection = "left";
                    if (!this.isTranslating && !this.isWaiting) {
                        this.startTranslate();
                    }
                } else {
                    this.isWaiting = false;
                }
            }
        });
    }

    handleUnbind() {
        this.stopTranslate();
        this.removeDelayTimer();
    }

    checkNodes() {
        const firstChild = this.el.firstChild;
        if (firstChild) {
            this.firstChild = firstChild as HTMLElement;
            this.containerWidth = this.el.clientWidth;
            this.scrollListWidth = this.firstChild.clientWidth;
            this.translateMin = this.containerWidth - this.scrollListWidth;
            if (this.scrollListWidth > this.containerWidth) {
                // 发生溢出
                this.isOverflow = true;
                // console.log('检测到溢出', this.containerWidth, this.scrollListWidth);
            } else {
                // 没有溢出的情况
                this.isOverflow = false;
                // console.log('未检测到溢出');
            }
        } else {
            // console.log('子节点还没初始化');
        }
    }

    stopTranslate() {
        window.cancelAnimationFrame(this.rafId as number);
        this.isTranslating = false;
    }

    removeDelayTimer() {
        if (this.delayTimer) {
            clearTimeout(this.delayTimer);
        }
    }

    startTranslate() {
        this.isWaiting = true;
        this.removeDelayTimer();
        this.delayTimer = window.setTimeout(() => {
            this.isWaiting = false;
            const transformValue = (this.firstChild as HTMLElement).style.transform;
            this.currentTranslateValue =
                !transformValue || transformValue === "none" ? 0 : Number(transformValue.replace(/[a-zA-z]+\((-?\d+)px\)/, "$1"));
            this.isTranslating = true;
            if (this.translateDirection === "left") {
                this.doTranslateLeft();
            } else {
                this.doTranslateRight();
            }
        }, this.options.delay);
    }

    doTranslateLeft() {
        if (!this.isTranslating) {
            return;
        }
        let nextValue = this.currentTranslateValue - this.options.step;
        if (nextValue > this.translateMin) {
            this.rafId = window.requestAnimationFrame(this.doTranslateLeft.bind(this));
        } else {
            nextValue = this.translateMin;
            this.isTranslating = false;
        }
        // 更新行为
        (this.firstChild as HTMLElement).style.transform = `translateX(${nextValue}px)`;
        this.currentTranslateValue = nextValue;
    }

    doTranslateRight() {
        if (!this.isTranslating) {
            return;
        }
        let nextValue = this.currentTranslateValue + this.options.step;
        if (nextValue < 0) {
            this.rafId = window.requestAnimationFrame(this.doTranslateRight.bind(this));
        } else {
            nextValue = 0;
            this.isTranslating = false;
        }
        // 更新行为
        (this.firstChild as HTMLElement).style.transform = `translateX(${nextValue}px)`;
        this.currentTranslateValue = nextValue;
    }
}

export default {
    mounted(el: AutoScrollElement, binding: DirectiveBinding): void {
        const { value: options } = binding;
        el.autoScrollHelper = new AutoScrollHelper(el, options);
        el.autoScrollHelper.handleInserted();
    },
    beforeUnmount(el: AutoScrollElement): void {
        el.autoScrollHelper.handleUnbind();
    },
};
