/*
 * @Author: 蒋文斌
 * @Date: 2021-05-31 15:13:20
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-31 15:14:55
 * @Description: dom utils
 */

interface OffsetResponse {
    offsetLeft: number;
    offsetTop: number;
}

export function getOffset(el: HTMLElement, relativeNode = document.body): OffsetResponse {
    let offsetLeft = 0;
    let offsetTop = 0;
    let parent: HTMLElement | null = el;
    while (parent !== null && parent !== relativeNode) {
        offsetLeft += parent.offsetLeft;
        offsetTop += parent.offsetTop;
        parent = parent.offsetParent as HTMLElement | null;
    }
    return {
        offsetLeft,
        offsetTop,
    };
}
