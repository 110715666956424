
import { useRoute } from "vue-router";
import { defineComponent, computed, h, VNode, resolveComponent, ComponentPublicInstance } from "vue";
import { ConfigProvider } from "ant-design-vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { getFullscreenElement } from "@/utils/fullscreen";
import { isDefined, isObject, isString } from "@/utils/type";
import { EnhancedSlots, PlainSlots, RenderOptions } from "@/bean/base";
import { useFullscreen } from "@/hooks/fullscreen";
import DOMPurify from "dompurify";
import { DEFAULT_CUSTOMIZED_INFO } from "./utils/const";

const proxySlots = (slots: EnhancedSlots) => {
    const slotKeys = Object.keys(slots);
    const proxiedSlots: PlainSlots = {};
    slotKeys.forEach((slotKey) => {
        proxiedSlots[slotKey] = () => {
            const slotSafeContent = DOMPurify.sanitize(slots[slotKey] as string);
            // eslint-disable-next-line no-eval
            return eval(`(${slotSafeContent})`).call(null, h, resolveComponent);
        };
    });
    return proxiedSlots;
};

function recurseCreateElementOptions(option: RenderOptions): VNode {
    const layoutComp = resolveComponent(option.tag);
    if (isString(layoutComp)) {
        return h(resolveComponent(DEFAULT_CUSTOMIZED_INFO.config.defaultLayout as string));
    }
    return h(resolveComponent(option.tag) as ComponentPublicInstance, option.props, isObject(option.slots) ? proxySlots(option.slots) : {});
}

function renderLayout(value: string) {
    const layoutComp = resolveComponent(value);
    if (isString(layoutComp)) {
        return h(resolveComponent(DEFAULT_CUSTOMIZED_INFO.config.defaultLayout as string));
    } else {
        return h(layoutComp);
    }
}

export default defineComponent({
    name: "App",
    setup() {
        const route = useRoute();
        const layout = computed(() => route.meta.layout);

        const { isFullscreenFlag } = useFullscreen();

        return () => (
            <ConfigProvider
                locale={zhCN}
                getPopupContainer={() => (isFullscreenFlag.value ? (getFullscreenElement() as HTMLElement) : document.body)}
                v-slots={{
                    default: () => {
                        if (isObject<RenderOptions>(layout.value)) {
                            return recurseCreateElementOptions(layout.value);
                        } else if (isDefined(layout.value)) {
                            return renderLayout(layout.value as string);
                        } else {
                            return null;
                        }
                    },
                }}
            ></ConfigProvider>
        );
    },
});
