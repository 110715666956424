
// import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, onMounted, PropType, ExtractPropTypes } from "vue";
import {
    EnhancedHTMLElement,
    enterFullscreen,
    exitFullscreen,
    getFullscreenElement,
    isFullscreen,
    listenFullscreen,
} from "@/utils/fullscreen";
import ClIconSVG from "@/components/icon/icon-svg/index.vue";

const props = {
    useText: {
        type: Boolean,
        default: true,
    },
    iconSize: {
        type: Number,
        default: 24,
    },
    getElement: {
        type: Function as PropType<() => EnhancedHTMLElement>,
        default() {
            return document.body;
        },
    },
    onFschange: {
        type: Function as PropType<(fullscreen: boolean) => void>,
    },
};

export type FullscreenProps = Partial<ExtractPropTypes<typeof props>>;

export default defineComponent({
    name: "ClFullscreen",
    props,
    emits: ["fschange"],
    setup(props, { emit }) {
        const isTargetFullscreen = ref(false);
        const checkFullscreenStatus = () => {
            const isFullscreenFlag = isFullscreen();
            isTargetFullscreen.value = isFullscreenFlag ? (getFullscreenElement() || document.body) === props.getElement() : false;
        };
        const onRequestFullscreen = () => {
            checkFullscreenStatus();
            if (isTargetFullscreen.value === true) {
                exitFullscreen();
            } else {
                enterFullscreen(props.getElement());
            }
        };
        onMounted(() => {
            checkFullscreenStatus();
            listenFullscreen(() => {
                checkFullscreenStatus();
                emit("fschange", isTargetFullscreen.value);
            });
        });

        return () => {
            const iconStyle = {
                fontSize: `${props.iconSize}px`,
            };
            return (
                <div class="fullscreen__wrapper" onClick={onRequestFullscreen}>
                    {isTargetFullscreen.value ? (
                        <ClIconSVG icon="tuichuquanping" style={iconStyle} />
                    ) : (
                        <ClIconSVG icon="quanping" style={iconStyle} />
                    )}
                    {props.useText ? <span class="fullscreen__text v-a-m">{isTargetFullscreen.value ? "退出全屏" : "全屏"}</span> : null}
                </div>
            );
        };
    },
});
